.searchVisor {
  position: sticky;
  top: 145px;
  z-index: 4;
  margin-bottom: 10px;
  box-shadow: 1px 0 1px 22px var(--gray-10);
  background: var(--gray-10);
}

.searchContainer {
  flex-grow: 1;
  margin: 12px 0 12px 0;
  border: 1px solid var(--gray-60);
  border-radius: 4px;
}

.search {
  position: relative;
}

.searchOpen {
  max-width: 540px;
}

.searchOpenFullWidth {
  max-width: 100%;
}

.pagination {
  margin-left: 13px;
}

.sortBy {
  margin: 0 16px 0 12px;
}

.selectAllSelection {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 7px;
  color: var(--gray-80);
}

.selectAllSelection:hover {
  color: var(--gray-90);
}

.selectAllSelection > label {
  color: inherit !important;
}

.selectedAllChoices {
  display: flex;
}

.selectAllSelectionChoices {
  margin-left: 5px;
}

.selectAllCheckBox {
  color: var(--gray-80);
}
