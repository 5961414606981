.badge {
  display: flex;
  margin-left: 5px;
  background-color: #387ab7;
  padding: 1.5px 10px;
  width: 97%;
  color: #ffffff;
}

.BadgeIcon {
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.BadgeMessage {
  margin-left: 2px;
  font-weight: bold;
}
