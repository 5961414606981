@import '../../../../styles/colors.css';
@import '../../../../styles/typography.css';
@import '../../../../styles/sizes.css';

.title {
  margin-bottom: 15px;
  height: 24px;
  line-height: 24px;
  color: var(--white);
  font-size: 14px;
  font-weight: var(--bold);
}

.subTitle {
  margin-top: 6px;
  margin-bottom: 6px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: var(--gray-20);
  font-weight: 400;
}

.pillContainer {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 240px;
  max-width: 800px;
}

.pill {
  margin-right: 8px;
  margin-bottom: 3px;
}

.containerSearch {
  max-width: 800px;
  max-height: 50vh;
  overflow: auto;
}

.editorWrapper {
  min-width: 260px;
  max-height: 500px;
}

.advancedTextEditor {
  background-color: #34404b;
  color: #fff !important;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
    monospace;
}

.containerTooLongText {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}

.textTooLongText {
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 4px 0px;
  background: #677078;
  padding: 0 4px 0 4px;
  line-height: 24px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}
