.disclaimerContent {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 5px;
  padding-top: 20px;
  max-width: 100%;
}

.disclaimerIcon {
  justify-self: center;
  padding-top: 3px;
}

.subtitle {
  margin-bottom: 10px;
}

.itemCount {
  padding-right: 10px;
}

.warningOption {
  display: flex;
  margin-top: 8px;
}

.warningOptionLabel {
  margin-left: 8px;
}
