.SectionSummary {
  box-sizing: border-box;
}

/* Add parent class for specificity, since this style is applied to an imported component. */
.SectionSummary .paper {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  padding: 0;
}

.fill {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

@-moz-document url-prefix() {
  .SectionSummary .paper.paper.paper {
    display: flex;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .fill.fill {
    display: flex;
    width: 100%;
    max-width: 100%;
  }

  .flexWrapper {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}

.flexWrapper {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.header {
  flex: 0 0 auto;
  margin: 16px;
}

.body {
  box-sizing: border-box;
  flex: 1 1 auto;
  margin-right: 0;
  margin-left: 0;
  padding: 0 16px;
  min-height: 0;
  overflow: hidden;
}

.body:last-of-type {
  margin-bottom: 16px;
}

.scrollable .body {
  overflow-y: auto;
}

.collapser {
  display: none;
  flex: 0 0 auto;
  margin: 8px 16px 16px;
}

.collapsible .collapser {
  display: block;
}

.measuring .collapser {
  display: none;
}

.footer {
  flex: 0 0 auto;
  border-top: 1px solid var(--gray-20);
  padding: 8px 16px;
  width: auto;
}