@import '../../../../styles/colors.css';
.content {
  border-bottom: 1px solid var(--gray-20);
  min-height: 56px;
}

.listVisor > div > div:first-child {
  box-shadow: none;
  padding: 8px 0px 8px 8px;
}

.filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  border-top: 1px solid var(--gray-20);
  box-shadow: none;
  background: var(--white);
  padding: 0px 8px 8px 8px;
  min-height: 64px;
}

.filters > div > div:first-child {
  display: none;
}

.filters > div > div:last-child {
  display: none;
}

.filterPillsWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
}

.filterPillsWrapper > * {
  margin-top: 8px;
}
