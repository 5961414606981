.InfluencerListActionBar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  background: var(--white);
  height: 60px;
}

.backArrowWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  width: 30px;
}

.backArrow.backArrow {
  border: none;
  padding: 0;
}

.backArrow.backArrow:hover {
  background: inherit;
}

.formWrapper {
  display: inline-block;
  margin-right: 20px;
  width: auto;
  height: 40px;
  line-height: 40px;
}

.actionWrapper {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 20px;
}
