.options {
  margin-top: 25px;
}

.optionsHeader {
  margin-bottom: 10px;
}

.radioButton {
  margin-top: 3px;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.optionText {
  margin-left: 8px;
  color: var(--gray-90);
}

.optionNote {
  font-size: 12px;
}
