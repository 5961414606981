.trackersDropdownLabel {
  margin-top: 15px;
}

.trackersDropdown {
  margin-top: 10px;
}

.trackersDropdown > div > div {
  height: 200px;
  overflow: auto;
}

.modalActions {
  display: flex;
  align-items: center;
  width: 100%;
}

.withoutRemoveTracker {
  justify-content: flex-end;
}

.divider {
  flex: 1;
}

.removeTrackerContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 20px;
}

.setUpATrackerButton {
  padding-left: 0px;
}

.emptyState {
  margin-top: 20px;
}
