.adminActionsSection {
  margin-top: 15px;
  overflow: hidden;
}
  
.adminTableSection {
  max-height: calc(100vh - 290px);
  overflow-y: auto;
}

.adminTableSearchBar {
  display: flex;
  float: right;
  justify-content: flex-end;
}
  