.container {
  display: flex;
  align-items: center;
}

.shareStatusLabel {
  margin-right: 8px;
}

.shareStatusIcon {
  margin-right: 5px;
}
