.topJournalistsChart .contentList {
  position: relative;
  margin-right: -16px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.topJournalistsChart:last-of-type {
  border-bottom: 0;
}

.topJournalistsChart .heading {
  display: flex;
  flex-direction: row;
  margin-right: 0px;
  border-bottom: 1px solid var(--gray-20);
  padding-top: 16px;
  padding-bottom: 16px;
  text-transform: uppercase;
  font-size: 16px;
}

.topJournalistsChart .heading div {
  flex: 1;
}

.topJournalistsChart .rowContent {
  display: flex;
  position: relative;
  left: 0;
  flex-direction: row;
  margin-top: 14px;
  margin-right: 14px;
  border-bottom: 1px solid var(--gray-20);
  padding-bottom: 10px;
}

.topJournalistsChart .rowContent:last-child {
  border-bottom: 0;
  padding-bottom: 40px;
}

.topJournalistsChart .rowStartContent {
  padding-top: 4px;
  padding-right: 10px;
  width: 24px;
  text-align: right;
  font-size: 20px;
  font-weight: 700;
}

.topJournalistsChart .rowEndContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.topJournalistsChart .rowEndContent > div {
  display: flex;
  flex-direction: row;
}

.topJournalistsChart .rowEndContent > div > div:first-child {
  flex: 1;
}
.topJournalistsChart .rowEndContent > div > div:nth-child(2) {
  text-align: right;
}

.topJournalistsChart .rowEndContent .main {
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
}
.topJournalistsChart a,
.topJournalistsChart a:hover {
  cursor: default;
  text-decoration: none;
  color: var(--brandColor);
}

.percentageChart {
  display: inline-block;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  background-color: var(--charting-green-base);
  width: auto;
  height: 6px;
}
.percentageChart[data-title]:hover::after {
  position: absolute;
  top: 20px;
  left: 10px;
  border-radius: 4px;
  background: var(--gray-90);
  padding: 5px 16px;
  width: 140px;
  line-height: 24px;
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  content: attr(data-title);
}

.outletIcon {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
}

.contactSeparator {
  display: inline-block;
  position: relative;
  top: -3px;
  margin-right: 6px;
  margin-left: 6px;
  border-radius: 50%;
  background-color: var(--gray-80);
  width: 3px;
  height: 3px;
}

.topJournalistsChart a:link,
.topJournalistsChartSmall a:link {
  text-decoration: none;
}

.noData {
  min-height: 300px;
}

.textRight {
  text-align: end;
}

.topJournalistsChart .rowEndContent .shareButton {
  visibility: hidden;
  color: var(--gray-40);
}

.addToList {
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
}
.addToList:hover {
  background: var(--gray-20);
}

.topJournalistsChart .clickableContact a:hover {
  cursor: pointer;
  width: auto;
}

.addToListDisabled {
  cursor: default;
  color: var(--gray-40);
}

.preview {
  cursor: pointer;
}

.disabled {
  cursor: default;
}
