.legends {
  width: var(--impact-attribution-legends-width);
  min-width: var(--impact-attribution-legends-width);
  overflow: auto;
}

.legendsHeader {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  align-items: baseline;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  width: 317px;
  height: 45px;
}

.legendsHeaderForWidget {
  box-sizing: border-box;
  display: flex;
  align-items: baseline;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  width: 317px;
  height: 45px;
}

.legendsList {
  display: block;
  margin: 45px 0 0;
  padding: 0px;
  list-style: none;
}

.legendsItem {
  border-top: 1px solid var(--gray-20);
}
.clickable:hover {
  background-color: var(--gray-10);
  cursor: pointer;
}
.active {
  box-shadow: inset 5px 0 0 0 var(--light-teal);
  background-color: var(--gray-10);
}
.infoTooltip {
  text-transform: none;
  font-size: 15px;
}
.attributionMetricTitle {
  display: flex;
  align-items: center;
}
.tooltipInfoIcon {
  width: 20px;
}
