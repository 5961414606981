:root {
  --impact-audience-radio-button-checked-color: var(--light-blue);
  --impact-audience-selection-step-space: 16px;
  --impact-audience-selection-step-indentation: calc(
    var(--impact-audience-selection-step-space) * 2
  );
  --impact-bars-height: calc(
    var(--header-bar-height) + var(--impact-view-title-bar-height)
  );
  --impact-audience-card-padding: 16px;
  --impact-attribution-legends-width: 317px;
  --impact-view-title-bar-height: 60px;
  --header-bar-height: 65px;
  --impact-pagination-controls-space: 16px;
  --impact-campaign-status-color-circle-size: 8px;
  --impact-audience-tooltip-background-color: var(--gray-90);
  --impact-audience-tooltip-color: var(--white);
  --impact-single-url-sidebar-width: 300px;
  --impact-report-sidebar-width: 400px;
  --impact-persona-builder-manage-filter-header-height: 60px;
  --impact-persona-builder-manage-filter-height: calc(
    100vh - var(--impact-bars-height) -
    var(--impact-persona-builder-manage-filter-header-height)
  );
  --divider-border: 1px solid var(--gray-20);

  --message-center-body-spacing: 20px;
  --highlighter-bg-color: var(--light-grey-blue);

  --tab-text-color: var(--gray-90);

  --view-title-bar-height: 60px;
  --header-bar-height: 65px;
  --view-report-status-switch-bar-height: 37px;

  --drilldown-mention-cell-padding: 50px;

  /* Transitions */
  --easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
