.modalContent {
  min-width: 462px;
  height: 179px;
}

.loadingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  width: 100%;
}

.loadingText {
  margin-bottom: 40px;
}

.contentClassName {
  display: flex;
  flex-direction: column;
}

.confirmText,
.cancelText {
  text-transform: capitalize;
}

.header :global(.tk-modal__title) {
  color: var(--gray-90);
}

.modalWrap .modal :global(.tk-modal__body) {
  padding: 16px 25px;
  color: black;
}

.contentClassName .headerDefault {
  background-color: var(--white);
}

.contentClassName .headerDanger {
  border-bottom: 1px solid var(--error);
}

.contentClassName .modalBody {
  padding: 16px 25px;
}
