.Tweet {
  padding: 16px 0;
  color: var(--black);
  font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
}

.Tweet:not(:nth-child(1)) {
  border-top: 1px solid var(--gray-20);
}

.nameContainer {
  display: flex;
  font-weight: bold;
}

.invisibleLink {
  text-decoration: none;
  color: inherit;
}

.retweetedIcon {
  margin-right: 8px;
}

.tweetedDate {
  color: black;
  font-weight: bold;
}

.tweetFooter {
  margin-top: 12px;
}

.tweetStats {
  display: flex;
}

.tweetStat {
  display: flex;
  margin-right: 24px;
}

.statNumber {
  margin-left: 8px;
}

.statSentiment {
  margin-left: 8px;
  text-transform: capitalize;
}

.twitterIcon {
  cursor: pointer;
}
