@import '../../../../styles/colors.css';

.blockListTableItem {
  vertical-align: middle;
}

.blockListTableItemButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--delete-button-color) !important; /* TODO: Convert the button to ROVER-UI button and remove the important */
}
