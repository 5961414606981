.InfluencerNoteFilterBar.InfluencerNoteFilterBar {
  margin-bottom: 10px;
}

.contentBar.contentBar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.barRight {
  display: flex;
}

.dropdownLabel {
  display: flex;
  margin-left: 16px;
  padding-right: 8px;
}

.dropdownDir {
  text-transform: lowercase;
}

.icon {
  padding-right: 8px;
}
