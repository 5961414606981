@import '../../../app/src/styles/colors.css';
@import '../../../app/src/styles/sizes.css';

.LabelPill {
  background: var(--gray-20);
  cursor: default;
}

.icons {
  color: var(--gray-80);
}

.smallIcon {
  width: var(--icon-size-small);
  height: var(--icon-size-small);
}

.largeIcon {
  width: var(--icon-size-large);
  height: var(--icon-size-large);
}

.labelIcon {
  margin-right: 4px;
}

.content {
  @apply t-truncate;
  margin-right: 4px;
  max-width: 175px;
  /* arbitrary reasonable cutoff width so we ellipse before we stretch out containers */
}

.clickable {
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: var(--color-link);
  font-weight: bold;
}
