.contentContainer {
  z-index: 9999999999999;
  width: 320px;
  max-height: 312px;
  overflow-x: hidden;
  overflow-y: auto;
}

.connectionGroup{
  display: flex;
  flex-direction: column;
  margin-top: -8px;
  margin-bottom: -8px;
}

.connectionGroupTitle {
  margin-left: 16px;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.07em;
  color: #34404B;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
}

.connectionItemContainer:hover {
  background-color: var(--gray-10);
}

.connectionTextTrunk{
  margin-left: 16px;
  width: 100%;
  max-width: 250px;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  line-height: 11px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.connectionItemButton {
  margin-right: 11px;
  margin-left: -15px;
  padding-top: 0;
  padding-bottom: 0;
}

.arrowDropDown {
  color: #677078;
}