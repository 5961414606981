@import '../../../../styles/sizes.css';

.accountInput {
  flex-basis: 200px;
  margin-right: 15px;
}

.accountJson {
  background-color: var(--gray-10);
  padding: 15px;
  max-height: 450px;
  overflow-y: auto;
}
