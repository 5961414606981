.centered {
  text-align: center;
}

.text {
  margin: 0 0 1.5em;
  color: var(--gray-90);
}

.checkboxWrapper {
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
}

.checkboxLabel {
  margin-left: 12px;
  cursor: pointer;
  user-select: none;
}

.summaryList {
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--gray-80);
}

.summaryList > * {
  margin-bottom: 8px;
  cursor: pointer;
}

.dataList {
  margin-bottom: 25px;
}

.formatSelection {
  margin: 8px 0;
  padding-left: 16px;
}

.formatListItem {
  margin: 16px 0;
}

.formatRadio {
  margin-right: 8px;
}

.attributes {
  margin-bottom: 10px;
}

.attribute {
  padding: 3px 5px;
}

.attribute__label {
  padding: 3px 5px;
  width: 40px;
  text-align: left;
  font-weight: bold;
}
