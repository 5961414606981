.InfluencerListTitleWrapper {
  padding-right: 32px;
  padding-left: 0px;
}

.InfluencerListTitleDisplay {
  margin-right: 10px;
  padding-right: 32px;
  padding-left: 0px;
  word-wrap: break-word;
}
