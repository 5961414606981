.sidebarWrapper {
  flex: 0 0 auto;
  border-right: 1px solid var(--gray-20);
  background-color: var(--white);
  width: 350px;
  height: calc(100vh - 177px);
  overflow-y: scroll;
}

.filterSidebar {
  flex-direction: column;
}

.hidden {
  display: none;
}
