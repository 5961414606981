.StoriesInfluencerSearchInput {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  visibility: visible;
  border: 1px solid var(--gray-40);
  border-radius: 5px;
  background-color: var(--white);
  height: 45px;
}

.focused {
  border: 1px solid var(--blue-lite-1);
  border-radius: 5px;
  box-shadow: 0 0 7px var(--blue-lite-1);
}

.container {
  border-bottom: 1px solid var(--gray-20);
  padding: 15px;
}

.inputArea {
  display: flex;
  flex-basis: 100%;
  height: 100%;
}

.textBox {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  padding: 10px 0;
  width: 100%;
}

.textBoxInput {
  padding: 8px;
}

.textBoxInput:focus {
  box-shadow: none;
}

.clearButtonText {
  box-sizing: border-box;
  border: none;
  background: none;
  padding: 4px 20px 0;
}
