.previewText {
  text-align: left;
}

.content {
  display: flex;
  margin-top: 20px;
}

.imageContainer,
.imageContainer > img {
  width: 96px;
  height: 80px;
}

.articleInfo {
  margin: 0 15px;
}

.articleUrl {
  display: flex;
  align-items: center;
  margin: 15px 0;
  text-decoration-line: underline;
}

.articleHeader {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.articleTitle {
  margin-bottom: 5px;
  line-height: 24px;
  font-size: 15px;
  font-weight: 900;
}

.articleAuthor {
  line-height: 24px;
  font-size: 15px;
}

.articleContent {
  text-align: left;
  line-height: 24px;
  font-size: 15px;
}

.publishDateContainer {
  display: flex;
  align-items: center;
  text-align: left;
}

.publishDateContainer > label {
  flex-grow: 1;
  font-weight: 900;
}

.publishDateContainer > div {
  flex-grow: 2;
}

.footer {
  margin-top: 16px;
  text-align: right;
}

.footer > button {
  margin-left: 10px;
}

.bulkAddContainer {
  box-sizing: border-box;
  margin-top: 13px;
  border: 1px solid var(--gray-10);
  border-radius: 4px;
  background-color: var(--gray-10);
  padding: 10px 15px;
  min-height: 250px;
}

.darkText {
  color: #3B4955;
}

.bulkAddTitle {
  margin: 0px;
}

.bulkAddAuthor {
  color: var(--gray-80);
}

.publishDateInput {
  border-radius: 4px;
}