.personaPreview {
  background-color: var(--white);
  padding: 15px;
  text-align: center;
}
.personaPreview > p {
  margin-top: 0;
}
.personaPreviewAvatar > svg {
  width: 48px;
  height: 48px;
}
.personaPreviewName {
  color: var(--gray-90);
  font-size: 16px;
}
.personaPreview > .personaPreviewEmpty {
  margin-top: 30px;
  padding: 0 30px;
  color: var(--gray-80);
  font-size: 14px;
}
.section {
  margin: 0 -15px 5px;
  border-bottom: 1px solid var(--gray-lite-4);
  padding: 0 15px 5px;
  text-align: left;
}
.section:last-child {
  border-bottom: none;
}
.section > .header {
  color: var(--gray-80);
  font-size: 12px;
}
.showButton {
  margin: 0 -15px 5px;
  border-top: 1px solid var(--gray-lite-4);
  cursor: pointer;
  padding: 15px 15px 0px;
  color: var(--light-blue);
  font-size: 14px;
  font-weight: 500;
}
.personaDataPreview {
  flex-grow: 1;
  margin: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  padding: 16px;
}
.personaDataPreviewTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.personaDataPreviewButtonIcon {
  margin-right: 10px;
}
.personaDataPreviewButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.personaDataPreviewFullLine {
  margin-right: -16px;
  margin-left: -16px;
  border-top: var(--gray-lite-4) 1px solid;
}
.personaDataPreviewSmallLine {
  margin-right: -8px;
  margin-left: -8px;
  border-top: var(--gray-lite-4) 1px solid;
}
.personaDataPreviewTitle {
  color: var(--gray-90);
  font-weight: bold;
}
.personaDataPreviewHeader {
  display: inline-block;
  text-transform: uppercase;
  color: rgba(103, 112, 120, 0.59);
  font-weight: bold;
}
.personaDataPreviewHeaderIcon {
  color: var(--medium-blue-hover);
}
.personaDataPreviewTooltip {
  display: inline-block;
  margin-left: 10px;
  vertical-align: sub;
}
.personaDataPreviewHeaderBis {
  color: var(--gray-80);
}
.personaDataPreviewEntity {
  letter-spacing: normal;
  color: var(--gray-90);
  font-weight: bold;
}
.personaDataPreviewValue {
  display: flex;
  align-items: center;
  margin-right: 5px;
  color: var(--gray-90);
  font-weight: bold;
}
.personaDataPreviewLoading {
  margin-right: 5px;
}
.personaDataPreviewBlock {
  display: flex;
  flex-direction: column;
}
.personaDataPreviewRow {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.personaDataPreviewContainer {
  display: flex;
  flex-grow: 1;
}
.personaDataPreviewLoadingContainer {
  margin-top: 50px;
  text-align: center;
  color: var(--gray-80);
}
.personaDataPreviewSearchName {
  color: var(--gray-80);
  font-size: 12px;
}
