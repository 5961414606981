.placeholder {
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 300px;
}

.wrapperSpacing {
  padding: 15px;
}
