.TwitterBio {
  padding: 10px 0;
  color: #34404b;
}

.top {
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
}
