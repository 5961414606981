.mainViewport,
.queryBuilder {
  display: flex;
  flex-direction: column;
}

.mainViewport {
  padding-top: 60px;
}

.queryBuilder {
  margin: 15px;
}

.buttonBox {
  padding: 0 !important;
}

.footer {
  display: flex;
  flex-direction: row;
  border-radius: 0 0 6px 6px;
  padding: 5px 15px !important;
}

.footer .content {
  display: flex;
  flex-grow: 1;
}

.footer .content * {
  margin-right: 5px;
}

.footer .controls {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: end;
}

.footer .controls button:first-child {
  margin-right: 5px;
}

.editorWrapper {
  border-radius: 4px 4px 0 0;
  background-color: #272822;
  padding: 15px;
  height: 230px;
}

.advancedTextEditor {
  flex-grow: 1;
  width: 100% !important;
  height: 100% !important;
  overflow-y: auto;
  resize: none;
  color: #fff !important;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
    monospace;
  font-size: 16px !important;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.emptyState .icon {
  width: 200px;
  height: 200px;
}

.contentDropdown {
  justify-content: center;
}

.replacement_marker {
  position: absolute;
  border-bottom: 2px solid var(--light-pink);
}