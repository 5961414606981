.reportPresentation {
  position: relative;
  background-color: var(--gray-10);
  padding-top: 0;
  height: 100vh;
}

.ReportContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.reportWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.reportSlide {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-right: 20px;
  width: 100%;
  height: 100%;
}

.reportSlideEdit {
  overflow: auto;
  overflow: overlay;
}

.reportSlidewrapper {
  width: 100%;
  height: 100%;
}

.reportSlideContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.reportSlideButtonWrapper {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.reportSlideButtonHidden {
  visibility: hidden;
}

.reportSlideButton {
  justify-content: center;
  margin: 0 40px;
  width: 100%;
}

.slideWidgetImageWrapper {
  outline: 1px solid rgba(0,0,0,.2);
  overflow: hidden;
}

.slideWidgetChartWrapper {
  z-index: 600;
  outline: 1px solid rgba(0,0,0,.2);
}

.slideWidgetTextWrapper {
  outline: 1px solid rgba(0,0,0,.2);
  overflow: hidden auto;
}

.reportsV3WaterMark {
  position: absolute;
  z-index: 1000;
  margin-left: 20px;
  color: var(--black);
  font-size: initial;
  font-weight: bold;
}
