.toggleItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
}

.actions {
  display: flex;
  flex-direction: row;
}

.description {
  margin-left: 10px;
}
