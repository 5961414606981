.stepBody {
  padding-top: 50px;
  padding-bottom: 50px;
}

.stepContent {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 320px;
}

.stepContent > div {
  flex-basis: 100%;
}

.almostThere {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1.71;
  letter-spacing: 1px;
  color: var(--light-blue);
  font-size: 14px;
  font-weight: bold;
}

.almostThereContainer p {
  text-align: center;
  line-height: 1.71;
  font-size: 14px;
}

.formGroup {
  box-sizing: border-box;
  margin-bottom: 0px;
  padding-bottom: 42px;
  height: 110px;
}

.formGroup:last-child {
  margin-bottom: 0;
}

.phoneNumberLabel {
  text-transform: capitalize;
}
.stepBody .almostThereContainer {
  margin-top: 24px;
  padding: 24px 20px 10px 20px;
}

@media only screen and (min-width: 1440px) {
  .stepContent > div {
    flex-basis: 50%;
  }

  .stepBody .stepContent {
    padding: 24px;
    height: 226px;
  }

  .stepBody .almostThereContainer {
    box-sizing: border-box;
    margin: auto;
    margin-top: 24px;
    padding: 24px 58px 24px 64px;
    width: 806px;
    height: 160px;
  }

  .formGroup .input {
    width: 456px;
  }

  .inputEmail,
  .labelEmail,
  .inputEmailError {
    margin-left: 12px;
  }
}

@media only screen and (min-width: 1920px) {
  .formGroup .input {
    width: calc(100% - 80px);
  }
}
