.sidePanelOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
}

.sidePanelOption:hover {
  cursor: pointer;
}

.sidePanelOptionSelected {
  border-left: #9ddbd7;
  box-shadow: inset 4px 0px 0px #43bfb7;
  background: #f7f7f7;
}

.sidePanelDivider {
  left: 16px;
  border: 0px;
  background: #c4c4c4;
  width: 90%;
  height: 1px;
}

.sidePanelAccordionHeader {
  position: relative;
  left: 16px;
  margin-bottom: 16px;
  width: 88%;
  line-height: 24px;
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.sidePanelAccordionResults {
  position: absolute;
  left: 16.5em;
}

.expansionPanel {
  padding-top: 16px;
}

.truncateText {
  max-width: 150px;
}
