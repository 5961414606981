.OutletOverview {
  padding: 0;
}

.OutletOverview .overview {
  padding: 0;
}

.OutletOverview .contentTabMenu {
  border-bottom: 1px solid var(--gray-20);
  padding-left: 16px;
}
