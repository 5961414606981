.badge {
  display: flex;
  margin-left: 5px;
  background-color: #c4514f;
  padding: 1.5px 10px;
  color: #ffffff;
}

.BadgeIcon {
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.3px;
}

.BadgeMessage {
  margin: .5px 0px -0.5px 2px;
  font-weight: bold;
}
