.searchNameBox {
  width: 400px;
}

.wrapper {
  display: flex;
}

.editUiContent {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
}
