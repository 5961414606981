@import '../../../../styles/variables.module.css';
@import '../../../../styles/sizes.css';

.radioButton {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid var(--gray-80);
  border-radius: 50%;
  width: 100%;
  min-width: var(--impact-audience-radio-button-size);
  height: 100%;
  min-height: var(--impact-audience-radio-button-size);
}

.checked {
  border-color: var(--impact-audience-radio-button-checked-color);
}

.innerCircle {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.5);
  border-width: calc(var(--impact-audience-radio-button-size) / 2);
  border-style: solid;
  border-radius: 50%;
  border-color: var(--impact-audience-radio-button-checked-color);
  background-color: var(--impact-audience-radio-button-checked-color);
  width: 100%;
  min-width: var(--impact-audience-radio-button-size);
  height: 100%;
  min-height: var(--impact-audience-radio-button-size);
}

.radioButtonContainer {
  position: relative;
  cursor: pointer;
  width: var(--impact-audience-radio-button-size);
  min-width: var(--impact-audience-radio-button-size);
  height: var(--impact-audience-radio-button-size);
  min-height: var(--impact-audience-radio-button-size);
}

.disabled {
  border-color: var(--gray-40);
  background-color: var(--white);
  cursor: not-allowed;
}
