@import '../../../../../styles/colors.css';

.ProfileModule {
  padding: 0;
}

.ProfileModule .paper {
  padding: 0;
}

.header {
  padding: 16px;
}

.title {
  margin: 0;
  line-height: calc(24 / 16);
  color: var(--gray-90);
  font-size: 16px;
}

.footer {
  box-sizing: border-box;
  border-top: 1px solid var(--gray-20);
  padding: 8px 16px;
}

.ProfileModule a {
  text-transform: none;
  text-decoration: none;
  line-height: calc(24 / 14);
  color: var(--color-link);
  font-size: 14px;
  font-weight: bold;
}
