.loaderWrapper {
  padding: 16px;
}

.loader {
  margin-bottom: 12px;
}

.loader:last-child {
  margin-bottom: 0;
}
