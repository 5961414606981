.sidebarContainer {
  background-color: #ffffff;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.sideBarOption {
  display: flex;
  flex-direction: row;
  border-radius: 0;
  width: 100%;
  height: 56px;
}

.sideBarContent {
  width: 100%;
}

.sideBarOption:hover {
  cursor: pointer;
}

.linkText {
  flex-basis: 75%;
  text-align: left;
}

.subOptionButton {
  width: 100%;
  height: 40px;
}

.listCount {
  padding-top: 2px;
  text-align: center;
  font-weight: 700;
}

.selectedListType,
.selectedListType:hover {
  box-shadow: inset 4px 0px 0px #43bfb7;
  background: #f7f7f7;
  font-weight: bold;
}

.selectedListType:focus {
  box-shadow: inset 4px 0px 0px #43bfb7;
}

.sideBarDivider {
  left: 16px;
  border: 0px;
  background: #c4c4c4;
  width: 90%;
  height: 1px;
}

.truncateText {
  max-width: 200px;
}

.item .tk-side-bar-collapse {
  font-weight: normal;
}

.emptyStateWrapper {
  display: flex;
  flex-direction: column;
  margin: 18px 16px 0 16px;
}

.centerText {
  text-align: center;
}

.loaderHeight {
  margin-top: 20px;
  height: 300px;
}
