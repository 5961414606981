
.sidebarContainer {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.sideBarOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
}

.sideBarContent {
  width: 100%;
}

.sideBarOption:hover {
  cursor: pointer;
}

.sideBarOptionSelected {
  border-left: var(--charting-teal-light);
  box-shadow: inset 4px 0px 0px var(--light-teal);
  background: var(--gray-10);
}

.optionSelected {
  font-weight: bold !important;
}

.insideLink {
  display: flex;
  width: 100%;
}

.iconStart {
  flex: 0 0 auto;
  padding: 2%; 
}

.linkText {
  flex: 1 1 auto;
  padding: 2%; 
  min-width: 0; 
}

.sideBarButton {
  border: 0;
  border-left: 5px solid transparent;
  background-color: white;
  cursor: pointer;
  padding: 20px 24px 20px 19px;
  width: 100%;
  text-align: left;
}

.selectedMenuOption,
.selectedMenuOption:hover {
  border-left: 5px solid var(--teal-lite-2);
  background-color: var(--gray-20);
}

.listCount {
  padding-top: 2px;
  text-align: center;
  font-weight: 700;
}

.sideBarDivider {
  left: 16px;
  border:0px;
  background: var(--gray-60);
  width: 90%;
  height: 1px;
}

.sideBarAccordionHeader {
  position: relative;
  left: 16px;
  margin-bottom: 16px;
  width: 88%;
  line-height: 24px;
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.truncateText {
  max-width: 200px;
}

.buttonText {
  padding-left: 0;
  color: var(--gray-90);
  font-weight: normal;
}

.item .tk-side-bar-collapse {
  font-weight: normal;
}

.emptyStateWrapper {
  display: flex;
  flex-direction: column;
  margin: 18px 16px 0 16px;
}

.centerText {
  text-align: center;
}

.loaderHeight  {
  height: 300px;
}
