.ThreadTitle {
  display: flex;
  flex-direction: row;
}

.ThreadTitleSubject {
  display:flex;
  flex-direction: column;
  flex-grow: 1;
}

.ThreadInfo {
  display: flex;
  flex-direction: column;
}

.ThreadInfo > span {
  font-size: 14px;
}

.backButton {
  margin-right: 18px;
}

.copyButton {
  width: 94px;
  height: min-content;
}

.tooltip_content{
  bottom: 26px;
}
