.exportModalBody {
  padding: 0 20px;
  height: 175px;
  font-size: 16px;
}

.exportProcessingContainer {
  display: grid;
  grid-template-rows: 157px;
  grid-template-columns: 50px auto;
  align-items: center;
  justify-content: center;
}
