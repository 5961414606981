.wrapper {
  align-self: center;
  margin: 32px 0;
}

.uploadButtonContainer {
  text-align: center;
}

.recommendedSizeContainer {
  padding-top: 16px;
}

.contentFullHeight {
  height: 100%;
}

.widgetCard > div {
  justify-content: center;
  padding: 0;
}

.imageContainer,
.imageContainerViewMode {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.imageContent {
  transition: opacity 0.3s linear;
  opacity: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imageButtonsContainer {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  justify-content: center;
  transform: translate(-50%, -50%);
  transition: visibility 0s, opacity 0.3s linear;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  text-align: center;
}

.imageContainer:hover .imageContent {
  opacity: 0.2;
}

.imageContainer:hover .imageButtonsContainer {
  visibility: visible;
  opacity: 1;
}

.imageButtonsContainer > * {
  margin-right: 5px;
}

.deleteButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin: 5px;
}

.deleteButtonContainerControl {
  padding: 0;
}
