.responsiveChartContainer {
  position: relative;
  transition: all 50ms ease-in;
}

.responsiveChartContainerExpanded {
  transition: all 300ms ease-out;
}

.centerLabelText,
.centerLabelTextVertical {
  position: absolute;
  top: 40%;
  left: 18%;
  z-index: 1;
  color: var(--gray-60);
}

/* Making the "No Data." label text centered responsive since Label component
* didn't work like in the DonutChart component
*/
.centerLabelTextVertical {
  top: 46%;
}

.centerLabelTextMedium {
  left: 20%;
}

.centerLabelTextLarge {
  left: 32%;
}

.centerLabelTextNoLegends {
  left: 45%;
}

.centerLabelTextVertical .centerLabelTextMedium {
  left: 25%;
}

.centerLabelTextVertical .centerLabelTextLarge {
  left: 34%;
}

.centerLabelTextLarge .centerLabelTextNoLegends {
  left: 48%;
}

.centerLabelText::after {
  content: '.';
}
