.container {
  box-sizing: border-box;
  padding: 8px 16px;
}

.container:hover {
  background-color: var(--gray-20);
  cursor: pointer;
}

.body {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.secondaryInfo {
  margin-left: 8px;
  color: var(--gray-80);
}

.keywordSearchTerm {
  margin-left: 8px;
}
