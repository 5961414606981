.wrapper {
  margin: 48px 0;
}

.textHeader {
  margin: 0;
  padding: 0 24px;
  line-height: 48px;
  color: var(--gray-90);
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
}

.contentFullHeight {
  height: 100%;
}

.textBody {
  margin-top: 16px;
  padding: 0 24px;
  line-height: 24px;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--bold);
}

.reportTextInput {
  margin: 0;
  border: none;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  resize: none;
  text-overflow: ellipsis;
  font-weight: var(--bold);
}

.reportTextInputViewMode {
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--bold);
}

.reportTextInput:hover {
  border: 2px solid #3398db;
  resize: both;
}

.reportTextInput:focus {
  border: 2px solid #3398db;
  resize: both;
}

.reportTextInput:disabled {
  background: var(--white);
  cursor: inherit;
}

.deleteButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin: 5px;
}

.deleteButtonContainerControl {
  padding: 0;
}

.wysiwygContainer {
  margin: 0;
  padding: 0 24px;
  color: var(--gray-90);
  font-size: 24px;
}

.wysiwygBody {
  margin-top: 16px;
  font-size: 16px;
}

.toolbar {
  position: absolute;
  top: -45px;
  z-index: 600;
  border: 1px solid var(--gray-90);
}

.hidden {
  display: none;
}

.editor {
  width: 100%;
}

.activeEditor {
  border: 2px solid var(--brandColor);
}
