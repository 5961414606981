.titleContainer {
  display: flex;
}
.titleContainer > div {
  margin-left: 15px;
}
.audienceActivationButton {
  margin-left: 15px;
}

.earnedMediaTitleContainer {
  display: flex;
  align-items: center;
}

.exportIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.exportIcon:hover {
  border: solid 1px var(--gray-60);
  background-color: var(--gray-10);
  color: var(--gray-90);
}

.currentImpactTitle {
  margin: 0 4px;
  font-weight: bold;
}

.modalContent {
  max-width: 600px;
}

.modalContentLoading {
  max-width: 462px;
  min-height: 180px;
}

.headerContent {
  line-height: 24px;
  color: var(--black);
  font-size: 16px;
}

.modalExportSubTitle {
  margin-bottom: 10px;
  line-height: 24px;
  color: var(--black);
  font-size: 14px;
}

.modalExportInfoText {
  margin-top: 20px;
  line-height: 24px;
  color: var(--gray-80);
  font-size: 14px;
}

.checkboxRow {
  margin-bottom: 16px;
  line-height: 24px;
  color: var(--gray-90);
  font-size: 14px;
}

.radioButtonContainer {
  display: flex;
  margin-top: 8px;
  margin-left: 30px;
}

.radioButtonLabel,
.checkBoxLabel {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: normal;
}

.downloadIcon {
  display: block;
  fill: var(--gray-80);
}

.iconAddon {
  display: flex;
  align-items: center;
  height: 16px;
}
