.dropdownFloatingContentContainer {
  min-width: 335px;
}

.sortingOptionsContainer {
  border-color: var(--gray-lite-4);
  max-height: 48px;
}

.dropDownItemcontainer:hover,
.sortingElement:hover {
  background-color: var(--gray-10);
}

.elementInnerMargin > * {
  margin-right: 10px;
}

.submitBlock {
  border-color: var(--gray-lite-4);
  height: 56px;
}
