.ReportSidebar {
  flex: 0 0 auto;
  flex-flow: row nowrap;
  align-items: stretch;
  transition: 200ms linear margin;
  z-index: 100;
  border-right: 1px solid var(--gray-20);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  width: auto;
  height: calc(
    100vh - var(--header-bar-height) - var(--view-title-bar-height) -
    var(--view-report-status-switch-bar-height)
  );
  overflow-y: auto;
}

.ReportViewSidebar {
  height: calc(100vh - var(--header-bar-height));
}

.ReportSidebarContent {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden auto;
}

.ReportSidebarItemContent {
  display: grid;
  grid-template-rows: auto 90px 63px;
  justify-content: center;
  margin: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--gray-10);
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 270px;
  min-height: 200px;
}

.ReportV3SidebarItemContent {
  display: grid;
  grid-template-rows: 135px 5px;
  justify-content: center;
  margin: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--gray-10);
  padding-top: 50px;
  padding-bottom: 2px;
  min-width: 270px;
  min-height: 230px;
}

.ReportSidebarItemContentOverDown {
  border-bottom: 3px dashed var(--gray-40) !important;
  padding-bottom: 0;
}

.ReportSidebarItemContentOverUp {
  border-top: 3px dashed var(--gray-40) !important;
  padding-top: 0;
}

.ReportSidebarItemToolbarIndex {
  align-self: flex-end;
  margin-bottom: 5px;
  text-align: left;
  font-size: 16px;
}

.ReportV3SidebarItemToolbarIndex {
  padding: 0 3px;
  color: white;
  font-size: small;
  font-weight: bold;
}
.ReportV3SidebarItemToolbarIndexWrapper {
  display: flex;
  align-items: center;
  background-color: var(--gray-lite-3);
}

.ReportSidebarItemToolbarActions {
  place-self: flex-end;
  display: flex;
  flex-direction: row;
  z-index: 2;
  margin-bottom: -3px;
  padding-top: 10px;
}

.gridRowEllipsis {
  visibility: hidden;
}

.ReportSidebarItemToolbarActionsButton {
  padding-right: 0;
}

.ReportSidebarItemThumb {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
}

.ReportV3SidebarItemThumbRemoveIcon {
  display: flex;
  position: absolute;
  top: 15px;
  right: 10px;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  background-color: var(--gray-lite-1);
  color: white;
}

.ReportSidebarItemTitleWrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 18px;
  text-align: center;
  font-size: 16px;
}

.ReportV3TitleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5px;
}

.ReportSidebarItemTitle {
  max-width: 200px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReportSidebarCollapsed {
  margin-left: -230px;
}

.ReportSidebarLoadingOverlay {
  z-index: 2;
  margin-top: 94px;
  width: 278px;
  height: calc(100% - 158px);
}

.ReportSidebarToggle {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  width: 20px;
}

.ReportSidebarToggleIcon {
  transform: rotateZ(-90deg);
  transform-origin: center;
}
