.MediaResearchButton {
  width: 150px;
  text-align: right;
}

.MediaResearchButton > button {
  margin: -4px 0 0 11px;
  padding: 8px 10px;
  width: 100%;
}

.modifiedTransitionTiming.modifiedTransitionTiming.modifiedTransitionTiming.modifiedTransitionTiming {
  transition: opacity 250ms cubic-bezier(0.645, 0.045, 0.355, 1), 
    transform 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.MediaResearchAndButtonWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.hideModal {
  visibility: hidden;
}

.gabDisclaimer {
  margin-top: 10px;
}

.buttonTextWrapper {
  display: flex;
  align-items: center;
}

.kiteError div,
.inputError,
.textAreaError {
  background-color: #feebea;
}

.kiteSuccess div {
  background-color: #e9fcf6;
}

.buttonIcon {
  height: 22px;
}

.buttonText {
  margin-left: 2px;
}

.formCompletionParagraph {
  margin-top: 0;
  color: var(--black);
  font-weight: bold;
}

.formGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.selectGroup {
  display: flex;
  flex-direction: column;
}

.selectGroup > div {
  display: flex;
}

.select {
  width: 100%;
}

.selectGroup svg {
  width: 16px;
  height: 16px;
}

.requestTypeTooltipIcon svg,
.regionTooltipIcon svg {
  width: 24px;
  height: 24px;
}

.select.select svg {
  display: block;
  width: 9px;
}

.option > div {
  height: 112px;
}

.option.option > div svg {
  display: none;
}

.formSection,
.selectGroup {
  width: 45%;
}

.formSection label,
.selectGroup label,
.message label {
  color: var(--black);
  font-weight: bold;
}

.selectGroup label {
  margin-right: 4px;
}

.selectGroup button {
  width: 100%;
}

.input {
  border-radius: 5px;
}

.callout {
  margin: 1em 0;
}

.invalidEmail,
.missingInput {
  color: var(--error);
}

.invalidEmail > div {
  align-items: center;
  padding: 15px 20px;
}

.invalidEmail button {
  top: 17px;
}

.infoIcon, .invalidEmailIcon {
  margin-right: 10px;
}

.invalidEmailIcon {
  height: 22px;
}

.requiredField {
  margin-top: 20px;
  color: var(--error);
}

.gabButton {
  cursor: pointer;
  color: var(--light-blue);
}

.submitButton {
  margin-left: 10px;
}

.loader > span {
  margin-left: 10px;
}

.requestTypeContent {
  display: flex;
  flex-direction: column;
}

.requestTypeContent h2 {
  font-size: 16px;
}

.textArea::placeholder {
  font-style: italic;
}

.requestTypeTooltip,
.loader {
  display: flex;
}

.requestTypeTooltip h2 {
  margin: 0 0 5px 0;
}

.requestTypeTooltipIcon {
  width: 130px;
}

.regionTooltipIcon {
  width: 240px;
}
