.dateRange {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-border);
  border-radius: 20px;
  width: auto;
  height: 40px;
}

.headerTitle {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncable {
  width: 100%;
}

.rightPane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.viwTitleBar {
  z-index: 802;
}

.sticky {
  position: fixed;
  top: 125px;
  right: 0;
  left: 0;
  z-index: 800;
}

.drilldownContainer {
  display: flex;
  position: absolute;
  background-color: var(--gray-10);
  padding-top: 0;
  width: 100%;
  height: 100%;
}
