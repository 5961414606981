.mainContainer, .mainTitle, .resusableMainTitle, .reusableMainContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reusableMainContainer {
  background-color: var(--bgColor-info);
}

.resusableMainTitle {
  justify-content: center; 
  padding: 16px;
  width: 100%;
}

.mainTitle {
  width: 100%;
}

.reusableTitle {
  margin-left: 10%;
}

.title {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.text {
  position: relative;
  padding: 0;
  text-align: center;
  color: var(--gray-90);
}

.text button {
  background-color: initial;
  height: 32px;
}

.closeButton {
  display: inline !important;
  float: right;
  width: auto !important;
  vertical-align: middle;
  line-height: 32px !important;
}

.loaderContainer {
  display: flex;
  flex-direction: row;
}

.loaderText {
  padding-left: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 200px;
}

.selectAllButton {
  margin-left: 5px;
}