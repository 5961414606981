.header {
  display: flex;
  flex-basis: 65px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: var(--gray-90);
}

.title {
  color: var(--gray-90);
  font-size: 18px;
  font-weight: var(--bold);
}

.closeButton {
  outline: none;
  border: none;
  background: transparent;
  color: var(--gray-80);
}

.closeButton:hover {
  color: var(--gray-90);
}
