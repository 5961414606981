.header {
  background-color: var(--white);
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body {
  width: 100%;
}

.formGroup {
  padding: 30px;
  padding-top: 0;
}

.bodyHeader {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  height: 50px;
  font-size: 18px;
  font-weight: 700;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.rowRole {
  justify-content: flex-start;
}

.input {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.button {
  padding: 0 10px;
}

.buttonWrapper {
  margin: 0 3px;
  text-align: right;
}
