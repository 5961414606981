.titleContent {
  display: flex;
  justify-content: flex-end;
}

.titleContent > * {
  margin-right: 8px;
}

.checkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 19px;
}

.checkContainer :first-child {
  margin-right: 5px;
}
