@import '../../styles/variables.module.css';

.body {
  padding: 0px 20px 20px 20px;
}

.head {
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: var(--divider-border);
}
