.OutletContributorsList {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
}

.placeholder {
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 300px;
}

.wrapperSpacing {
  padding: 15px;
}

.controls {
  flex: 0 0 auto;
}

.influencers {
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
}
