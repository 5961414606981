.devFeatureLocalOverrides {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 902; /* page-header (SCSS) has explicit 901 z-index on hover */
}

.toggle {
  border-radius: 2px;
  background: rgb(131, 58, 180);
  cursor: pointer;
  padding: 0px 7px;
  line-height: 18px;
  color: #fff;
  font-weight: bold;
  user-select: none; /* click without accidentally selecting button text */
}

.toggle:active {
  /* visually move on mousedown so we can tell when we're clicking this element */
  margin-top: 1px;
}

.list {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  background: #eee;
  padding: 5px 8px;
}

.item {
  margin-bottom: 5px;
  background: #fff;
  padding: 5px;
}

.name {
  margin-bottom: 10px;
  font-family: monospace;
}

.input {
  margin-right: 5px;
  padding: 5px;
  width: 200px;
}

.deinit {
  margin-top: 10px;
}
