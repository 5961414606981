@import '../../../../styles/colors.css';
@import '../../../../styles/typography.css';

.pill {
  display: inline-block;
  transition: background-color 0.2s ease, color 0.2s ease,
    border-color 0.2s ease;
  margin: 5px;
  outline: none;
  border: solid 1px transparent;
  border-radius: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  cursor: pointer;
  padding: 5px 15px;
  text-align: center;
  line-height: 20px;
  color: var(--light-blue);
  font-size: 14px;
  font-weight: normal;
}

.pill:hover {
  background-color: var(--light-blue-hover);
  color: var(--white);
}

.selected {
  border: solid 1px var(--light-teal);
  background-color: #ebfffe;
  color: var(--gray-80);
}

.selected:hover {
  border: solid 1px var(--light-teal-hover);
  background-color: #d5f5f3;
  color: var(--gray-90);
}

.clear {
  transition: color 0.2s ease;
  margin-right: -10px;
  margin-left: 8px;
  width: 20px;
  vertical-align: top;
  color: var(--light-teal);
}

.selected:hover .clear {
  color: var(--light-teal-hover);
}

.bold {
  font-weight: var(--bold);
}

.selectedPill {
  background-color: var(--light-blue-hover);
  color: var(--white);
}
