.managePersona {
  box-sizing: content-box;
  display: flex;
  height: 100%;
}
.managePersona :global(.title) {
  margin-top: 32px;
  margin-bottom: 16px;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: 1px;
  color: var(--gray-80);
  font-size: 12px;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
}
.managePersona :global(.view-title-bar-link) {
  text-transform: initial;
}
.manageContainer {
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  width: 100vw;
  min-width: 900px;
}
.manageFilter {
  width: 100%;
  min-width: 496px;
}
.manageFilterContainer {
  display: flex;
  justify-content: center;
  padding-right: 32px;
  padding-left: 32px;
  height: var(--impact-persona-builder-manage-filter-height);
  overflow-y: auto;
}
.manageFilterContainerContent {
  width: 100%;
}
.subTitle :global(.view-title-bar--fixed) {
  top: 125px;
}
.subTitle :global(.view-title-bar-links) {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 16px;
}
.subTitle :global(.view-title-bar-link) {
  margin: 0 8px;
  padding: 0;
}
.subTitle :global(.view-title-bar-right-content) {
  display: none;
}
.subTitle :global(.view-title-bar-content-container) {
  margin: auto;
  padding: 0 8px;
}
.titleLink :global(.tk-svg-icon) {
  margin-top: 4px;
}
.subTitle :global(.view-title-bar-link:first-child),
.subTitle :global(.view-title-bar-link:last-child) {
  margin: 0;
}
.personaPreview {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #dee5e7;
  background-color: var(--gray-10);
  width: 100%;
  min-width: calc(404px - 1px); /* 1px is the border pixel */
  max-width: calc(410px - 1px); /* 1px is the border pixel */
  height: calc(100vh - var(--impact-bars-height));
  overflow-y: auto;
}
.titleContainer {
  display: flex;
}
.titleContainer > div {
  margin-left: 15px;
}
.title {
  margin: 0px;
  line-height: 1;
  font-size: 18px;
}

/* Media query breakpoints */
/* This file has measurements starting from 900px to 3440px */
@media only screen and (min-width: 1024px) {
  .manageFilter {
    min-width: 614px;
  }
  .manageFilterContainer {
    padding-right: 32px;
    padding-left: 32px;
  }
  .personaPreview {
    min-width: calc(410px - 1px); /* 1px is the border pixel */
  }
  .subTitle :global(.view-title-bar-link) {
    margin: 0 24px;
  }
}
@media only screen and (min-width: 1440px) {
  .manageFilterContainer {
    padding-right: 60px;
    padding-left: 60px;
  }
}
@media only screen and (min-width: 1680px) {
  .personaPreview {
    min-width: calc(405px - 1px); /* 1px is the border pixel */
    max-width: calc(405px - 1px); /* 1px is the border pixel */
  }
  .subTitle :global(.view-title-bar-link) {
    margin: 0 32px;
  }
}
@media only screen and (min-width: 1920px) {
  .personaPreview {
    min-width: calc(410px - 1px); /* 1px is the border pixel */
    max-width: calc(410px - 1px); /* 1px is the border pixel */
  }
}
@media only screen and (min-width: 2560px) {
  .manageFilterContainer {
    padding-right: 325px;
    padding-left: 325px;
  }
}
@media only screen and (min-width: 3440px) {
  .manageFilterContainer {
    padding-right: 625px;
    padding-left: 625px;
  }
}
