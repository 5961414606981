.chartLegendSurround {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 8px 0 0;
  width: 240px;
  min-width: 240px;
  max-height: 824px;
  list-style: none;
}

.restItems {
  transition: opacity 50ms ease-in;
  opacity: 0;
  height: 0;
  max-height: 0;
  overflow: hidden;
}

.restItemsExpanded {
  transition: opacity 300ms ease-out;
  opacity: 1;
  height: auto;
  max-height: 270px;
  overflow: hidden;
}

.chartLegendSurround .chartLegendItem {
  min-height: calc(44px - 1px); /* 1px is border buttom pixel */
  max-height: calc(56px - 1px); /* 1px is border buttom pixel */
}

.chartLegendSurround .seeMoreButton {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  outline: none;
  border: none;
  background-color: transparent;
  min-height: 40px;
}

.chartLegendSurround .seeMoreButton .seeMoreButtonText {
  padding-right: 10px;
  color: var(--light-blue);
  font-weight: 400;
}

.legendTitle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid var(--gray-20);
  padding-right: 8px;
  height: 44px;
  text-transform: uppercase;
  color: var(--gray-80);
  font-size: 12px;
}

.legendAdvancedTitle {
  display: flex;
  flex-direction: column;
  margin-left: 9px;
  border-bottom: 1px solid var(--gray-20);
  padding-bottom: 15px;
}

.legendAdvancedTitle .itemTitle {
  color: var(--black);
  font-size: 24px;
  font-weight: var(--bold);
}

.legendAdvancedTitle .itemSubtitle {
  text-transform: uppercase;
}

.metricRowStyle {
  padding: 0;
}

.metricRowLabelLink:hover {
  cursor: pointer;
}

:global(.recharts-wrapper) :global(.recharts-legend-wrapper) {
  width: 240px;
  min-width: 240px;
}

@media only screen and (min-width: 1024px) {
  :global(.recharts-wrapper) :global(.recharts-legend-wrapper) {
    width: 328px;
    min-width: 328px;
  }

  .chartLegendSurround {
    width: 328px;
    min-width: 328px;
  }
}
