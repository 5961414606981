.blockListTableContainer {
  max-height: 600px;
  overflow-y: auto;
}

.blockListTable {
  padding-right: 18px;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.blockListTableHead {
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
}

.blockListTableHead tr {
  height: 40px;
  text-align: left;
}

.blockListTableHead tr th {
  text-transform: uppercase;
  font-weight: normal;
}

.errorMessage {
  margin-top: 5px;
  color: var(--color-success);
}

.addEmailInput {
  width: 100%;
}

.addEmailButton {
  justify-content: center;
  margin-left: 4px;
  width: 100px;
}

.addEmailInputContainer {
  margin-bottom: 20px;
}
