.MetricSnapshot.MetricSnapshot {
  padding: 0;
}

.MetricSnapshot,
.MetricSnapshot * {
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  padding: 15px;
}

.headerBody {
  display: flex;
  align-items: center;
}

.downloadCsvButton {
  border-radius: 50%;
  padding: 5px;
}

.downloadCsvButton:hover {
  background: var(--gray-20);
}

.headerText {
  flex-grow: 1;
  margin: 0;
  margin-left: 0.5em;
  color: var(--gray-90);
  font-size: 16px;
}

.metricBody {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.metrics {
  margin: 0 10px;
}

.media {
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-between;
}

.arrow {
  align-self: center;
  transform: rotate(-90deg);
  transition: transform 200ms;
  margin-left: 15px;
  cursor: pointer;
  will-change: transform;
}

.metricWrapper {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column nowrap;
}

.metricWrapper > * {
  width: 100%;
}

.metric {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top: 1px solid var(--gray-20);
  padding: 10px 20px;
  width: 100%;
}

.metricLabelContainer {
  display: flex;
  justify-content: flex-start;
  width: 150px;
}

.metricLabel {
  width: auto !important;
  min-height: 22px;
}

.metricValue {
  line-height: 1.7;
  color: var(--gray-90);
  font-size: 14px;
}

.metricValueContainer {
  display: flex;
  align-items: center;
}

/* SKELETON */
.loading {
  padding: 0;
}

.loading .metric {
  padding: 15px 20px;
}

.metricWrapper:first-of-type .metric {
  border-top-width: 0;
}

.skeletonBar {
  margin-bottom: 0;
  max-height: 16px;
}

/* SUBSTATS */
.substats {
  display: none;
  flex-direction: column;
}

.substat {
  display: flex;
  flex-flow: row nowrap;
}

/* OPEN */
.open {
  visibility: visible;
}

.open .substats {
  display: flex;
}

.open .arrow {
  transform: rotate(90deg);
}

/* MEDIUM SIZE */
.medium .metricBody {
  flex-flow: row wrap;
  padding: 15px;
}

.medium .metricWrapper {
  flex-basis: 50%;
  margin-right: auto;
  padding: 0 10px;
}

.medium .main {
  flex-flow: column-reverse nowrap;
  align-items: initial;
  border: 0;
  padding: 0;
  width: 100%;
}

.medium .main .metricDescription {
  display: block;
  margin-top: 8px;
  width: 100%;
  font-size: 12px;
  font-weight: 100;
}

.medium .main .metricLabel {
  display: block;
  width: 100%;
  text-transform: uppercase;
  line-height: 1.33;
  font-size: 12px;
  font-weight: 100;
}

.medium.open .main .metricLabel {
  margin-bottom: 16px;
}

.medium .main .metricValue {
  display: block;
  line-height: 1.5;
  font-size: 32px;
  font-weight: 800;
}

.medium .substat {
  padding-right: 0;
  padding-left: 0;
  color: var(--gray-80);
  font-weight: 700;
}

.medium .loading {
  margin-top: 10px;
}

.medium .skeletonBar {
  margin: 10px 0 10px 5px;
  max-height: initial;
}

.large .metricWrapper {
  flex-basis: auto;
}
