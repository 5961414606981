.container {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 16px;
}

.postsCount {
  overflow: hidden;
  color: var(--black);
  font-size: 40px;
}

.postsCountLabel {
  font-size: 14px;
}

.metricsDetail {
  height: 40px;
}

.metricsDetail:not(:last-child) {
  border-top: 1px solid var(--gray-20);
  border-bottom: 1px solid var(--gray-20);
}

.metricsDetail td:not(:first-child) {
  text-align: right;
  color: var(--black);
  font-weight: var(--bold);
}

.tooltipContent {
  color: var(--white);
  font-weight: bold;
}
