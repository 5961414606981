.header {
  background-color: var(--white);
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body {
  background-color: var(--white);
}

.button {
  padding: 0 10px;
}

.title {
  padding: 10px;
}

.buttonWrapper {
  margin: 0 3px;
  text-align: right;
}

.contentWrapper {
  padding: 20px;
}
