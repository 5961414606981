.active:hover {
  cursor: default;
}

.active.sidebarRowPill {
  background-color: var(--light-blue);
  color: var(--white);
  font-weight: 600;
}

.sidebarRowPillContainer {
  display: flex;
  margin-top: 8px;
}

.sidebarRowPill {
  display: flex;
  border-radius: 12px;
  background-color: var(--gray-20);
  padding: 4px 8px;
  text-align: center;
  line-height: 12px;
  color: var(--gray-90);
  font-size: 12px;
}
