.ToggleFilterGroup {
  margin: 15px 0;
  white-space: nowrap;
}

.expandIconSection {
  display: flex;
  align-items: center;
}

.groupedFilterItem {
  padding: 7.5px 15px 7.5px 30px;
}

.disabled {
  opacity: 0.29;
}
