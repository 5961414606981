.metaAddArticle {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.metaAddArticleLabel {
  flex-grow: 1;
  margin-right: 5px;
  text-align: right;
}

.metaAddArticleContainer {
  display: flex;
  flex-grow: 5;
  justify-content: space-between;
}

.addArticleFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
}

.metaAddArticleInput,
.metaAddArticleButton {
  margin: 0 5px;
}

.panelContainer {
  position: absolute;
  right: 4px;
  z-index: 900;
  margin-top: 4px;
  margin-left: 5px;
  width: 400px;
}

.loaderContainer {
  position: relative;
  margin: 10px 0;
  height: 50px;
}

.loadingText {
  margin-top: 20px;
  font-size: 14px;
}

@media only screen and (max-width: 1000px) {
  :global(.earnedSearchesDrilldownWrapper) .panelContainer {
    right: 0px;
    left: 0px;
    width: 350px;
  }
}
