.responsiveChartContainer {
  transition: all 50ms ease-in;
}

.pieSlice {
  cursor: pointer;
}

.responsiveChartContainerExpanded {
  transition: all 300ms ease-out;
}
.donutChartLegendSurround {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 8px 0 0;
  max-height: 824px;
  list-style: none;
}

.restItems {
  transition: opacity 50ms ease-in;
  opacity: 0;
  height: 0;
  max-height: 0;
  overflow: hidden;
}

.restItemsExpanded {
  transition: opacity 300ms ease-out;
  opacity: 1;
  height: auto;
  max-height: 270px;
  overflow: hidden;
}

.donutChartLegendSurround .chartLegendItem {
  min-height: calc(44px - 1px); /* 1px is border buttom pixel */
  max-height: calc(56px - 1px); /* 1px is border buttom pixel */
}

.donutChartLegendSurround .seeMoreButton {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  outline: none;
  border: none;
  background-color: transparent;
  min-height: 40px;
}

.donutChartLegendSurround .seeMoreButton .seeMoreButtonText {
  padding-right: 10px;
  color: var(--light-blue);
  font-weight: 400;
}

.legendTitle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid var(--gray-20);
  padding-right: 8px;
  height: 44px;
  text-transform: uppercase;
  color: var(--gray-80);
  font-size: 12px;
}

.legendAdvancedTitle {
  display: flex;
  flex-direction: column;
  margin-left: 9px;
}

.legendAdvancedTitle .itemTitle {
  color: var(--black);
  font-size: 24px;
  font-weight: var(--bold);
}

.legendAdvancedTitle .itemSubtitle {
  text-transform: uppercase;
}

.chartLegendDot {
  border: 3px solid transparent;
  border-radius: 50%;
  width: 3px;
  height: 3px;
}

.sentimentTooltipBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--white);
}

.defaultTooltipContent {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
}

.defaultTooltipLabel {
  display: block;
  font-weight: bold;
}

.defaultTooltipValue {
  display: block;
}

.metricRowStyle {
  padding: 0;
}

.labelNoData {
  fill: var(--gray-60);
}
