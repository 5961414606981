.checkboxContainer {
  padding-top: var(--impact-audience-selection-step-space);
  padding-left: var(--impact-audience-selection-step-space);
}

.checkboxRow {
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: var(--impact-audience-selection-step-space);
}

.checkboxRow:last-child {
  padding-bottom: 0px;
}

.subCheckboxContainer {
  /* the 7px come from .tk-checkbox__children */
  padding-left: calc(var(--impact-audience-selection-step-indentation) + 7px);
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.subCheckbox {
  padding-top: 24px;
  width: 270px;
}

.noConversions {
  padding-top: var(--impact-audience-selection-step-space);
  line-height: 24px;
  color: var(--gray-80);
  font-size: 14px;
}

.tooltipIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.cardRow {
  margin-top: 17px;
}

.toggleConversionCheckbox {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--light-blue);
  font-size: 14px;
}

.conversionText {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.radioButtonInfo {
  display: flex;
  margin-bottom: 16px;
}

.checkboxSeeAll {
  /* Padding button is 7px, plus 3px is the design spect separation for "See all" and icon */
  margin-left: 3px;
  line-height: 24px;
}

.radioButtonBody {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 20px;
}

.audienceRadioButton {
  position: absolute;
  top: 0;
}

.entireSearchAudienceTooltipWidth {
  width: 263px;
}

.audienceWhoPerformedActionTooltipWidth {
  width: 370px;
}

@media only screen and (min-width: 1024px) {
  .radioButtonInfo {
    margin-bottom: 0;
  }
}
