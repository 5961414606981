.InfluencerDesks {
  border-top: 1px solid var(--gray-20);
}

.InfluencerDesks .link {
  line-height: inherit;
  line-height: unset;
}

.wrapper {
  box-sizing: border-box;
  line-height: 30px;
  font-size: 12px;
}

.wrapperSpacing {
  padding: 15px;
}

.placeholder {
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 300px;
}
