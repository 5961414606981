.ToggleFilterItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -15px;
  padding: 7.5px 15px;
  white-space: nowrap;
}

.ToggleFilterItem:focus,
.ToggleFilterItem:focus-within {
  background-color: var(--gray-10);
}

.disabled {
  opacity: 0.29;
  cursor: default;
}
