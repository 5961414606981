@import '../../styles/colors.css';

.mailTo {
  text-decoration: none;
  color: var(--color-link);
}

.mailTo:hover,
.mailTo:focus {
  color: var(--blue-lite-1);
}

.mailTo:active {
  color: var(--medium-blue-hover);
}
