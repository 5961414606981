.page {
  display: flex;
  flex-grow: 1;
}

.earnedSearchesSidePanelContainer {
  display: flex;
  flex: 0 0 20em;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  border-right: 1px solid var(--gray-20);
  background-color: #ffffff;
}

.rightContent {
  margin-top: 60px;
  width: 100%;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 120px 0 280px;
}

.emptyState .icon {
  margin-bottom: 10px;
  width: 200px;
  height: 200px;
}

.emptyState .textContent {
  display: flex;
  flex-direction: column;
}

.emptyState .controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -5px;
}

.emptyState .controls button {
  margin: -15px 15px;
}

.emptyState .caption {
  margin-bottom: 15px;
}

.dropdownMenu {
  z-index: 20;
}

.wontBreakSpaces {
  white-space: nowrap;
}
