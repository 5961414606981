@import '../../../../../styles/colors.css';

.RecentArticles {
  padding: 0;
}

.RecentArticles .articles {
  padding: 0 16px;
}

.RecentArticles .allArticles {
  box-sizing: border-box;
  border-top: 1px solid var(--gray-20);
  padding: 8px 16px;
}

.RecentArticles .link {
  text-transform: none;
  text-decoration: none;
  line-height: calc(24 / 14);
  color: var(--color-link);
  font-size: 14px;
  font-weight: bold;
}
