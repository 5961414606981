.description {
  line-height: 1.71;
  font-size: 14px;
}

.launchDateContainer {
  width: 100%;
}

.calendarIcon {
  fill: var(--gray-80);
}

@media only screen and (min-width: 1440px) {
  .launchDateContainer {
    max-width: 440px;
  }
}
