.pageContainer {
  box-sizing: border-box;
  margin: 0 16px;
  border-radius: 4px;
  min-width: 1048px;
  max-width: 100%;
  /* 60px and 65px are the page header and subheader respectively*/
  height: calc(100vh - 60px - 65px);
  min-height: 600px;
  max-height: 100%;
  overflow: auto;
}

.topOutletDrilldownChartContainer {
  border-bottom: 1px solid var(--gray-20);
  width: 100%;
  height: 335px;
  min-height: 335px;
}
.chart {
  padding-top: 15px;
  width: 100%;
  min-width: 400px;
}
.topOutletDrilldownContent {
  width: 100%;
  overflow: hidden;
}
.legends {
  width: 360px;
}
.legendLoaderContainer {
  width: 360px;
}
.legendsList {
  display: block;
  margin: 0px;
  margin-top: 14px;
  padding: 0px;
  list-style: none;
}
.legendsItem {
  border-top: 1px solid var(--gray-20);
}
.tableContainer {
  /* 335px is the chart container height*/
  height: calc(100% - 335px);
}
.secondaryNavContent {
  display: flex;
}
.chartHintText {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 15px;
}

.tooltip {
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background: var(--gray-90);
  padding: 10px;
  color: var(--white);
  font-size: 14px;
  font-weight: bold;
}

.tooltipLeftArrow:after {
  position: absolute;
  top: 50%;
  right: 99%;
  margin-top: -10px;
  border: solid transparent;
  border-width: 10px;
  border-color: rgba(51, 65, 75, 0);
  border-right-color: var(--gray-90);
  width: 0;
  height: 0;
  content: '';
  pointer-events: none;
}

.tooltipRightArrow:after {
  position: absolute;
  top: 50%;
  left: 99%;
  margin-top: -10px;
  border: solid transparent;
  border-width: 10px;
  border-color: rgba(51, 65, 75, 0);
  border-left-color: var(--gray-90);
  width: 0;
  height: 0;
  content: '';
  pointer-events: none;
}
.downloadIcon {
  margin-right: 5px;
  fill: var(--gray-80);
}
.exportIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
}
.modalContentLoading {
  max-width: 462px;
  min-height: 180px;
}
