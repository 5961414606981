.listContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.listContainer:not(:last-child) {
  margin-bottom: 16px;
}

.itemContent {
  display: flex;
  flex-direction: column;
}

.title {
  height: 24px;
  line-height: 24px;
  white-space: nowrap;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: bold;
}

.subTitle {
  height: 24px;
  line-height: 24px;
  color: var(--gray-90);
  font-weight: normal;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
}

.buttonList {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
}

.emptyStateText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.container {
  height: min-content;
}

.containerWithScrolling {
  margin: -16px -16px 0 -16px;
  padding: 16px;
  height: 350px;
  overflow: auto;
}
