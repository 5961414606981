.ContentWrapper {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
}

.tabMenuWrapper {
  flex: 0 0 auto;
  margin-bottom: 1px;
  box-shadow: 0 1px 0 0 #dee5e7;
  background-color: var(--white);
  padding: 0 16px;
  overflow-x: auto;
  white-space: nowrap;
}

.tabContentWrapper {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  background-color: var(--rvr-white);
  min-width: 0;
  min-height: 0;
  overflow-y: auto;
}

.tabsMenu {
  display: flex;
  flex-direction: row;
}
