.container {
  display: flex;
}

.pill {
  box-sizing: border-box;
  display: flex;
  border-radius: 12px;
  background-color: var(--gray-20);
  padding: 4px 8px;
  height: 24px;
  text-align: center;
  line-height: 16px;
  color: var(--gray-90);
  font-size: 12px;
}

.active.pill {
  background-color: var(--light-blue);
  color: var(--white);
  font-weight: 600;
}
