.likesMetric {
  display: flex;
  align-items: center;
  margin: 16px 0 2px 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--gray-20);
  color: var(--black-10);
}

.likesMetric__ranking {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #677078;
  padding: 8px 15px;
  color: var(--white);
  font-size: 16px;
  font-weight: var(--bold);
}

.likesMetric__body {
  flex-grow: 1;
  padding: 8px;
}

.likesMetric__likesCount {
  font-weight: var(--bold);
}
