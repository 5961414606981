.SocialStreams {
  flex: 1 1 auto;
}

.wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 60px;
}

.SocialStreams .contentWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  grid-gap: 25px;
  margin: 25px;
  width: 100%;
  height: fit-content;
}

.SocialStreams .contentWrapperEmpty {
  display: grid;
  grid-template-columns: repeat(1, auto);
  margin: 25px;
}

.SocialStreams .contentWrapperEmpty [class*='heading'] {
  color: var(--black-10);
  font-size: 1.7rem;
}

.SocialStreams [class*='title-bar-link']:hover {
  cursor: pointer;
  user-select: none;
}

.SocialStreams [class*='view-title-bar-content-container'] {
  border-right: 1px solid var(--gray-20);
  padding-right: 0;
  max-width: 330px;
}

.rightTitlebarActions {
  display: flex;
}

.SocialStreams .createButtonText {
  margin-right: 9px;
}

.SocialStreams .emptyState {
  margin-top: 120px;
}

.SocialStreams .emptyState [class*='text'] {
  width: 540px;
}

.fullWidth {
  width: 100%;
  height: 50vh;
}
.leftTitleBarContent {
  display: flex;
}

.shareButton {
  margin: 0 8px 0px 0px;
}
