.DropdownItem {
  justify-content: space-between;
  cursor: pointer;
  padding: 15px 20px;
}

.clearBtn > svg {
  transform: rotate(45deg);
  transition: fill .2s ease-in-out;
  visibility: hidden;
} 

.clearBtn > svg:hover {
  fill: var(--delete-button-color);
}

.DropdownItem:hover .clearBtn > svg {
  visibility: visible;
}

.searchWrapperIcon {
  margin-right: 15px;
}

.searchedTerm {
  margin-top: -5px;
}

.searchWrapper {
  width: 100%;
}

.DropdownItem, .searchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
