.centered {
  text-align: center;
}

.text {
  margin: 0 0 1.5em;
  color: var(--gray-90);
}

.checkboxWrapper {
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
}

.checkboxLabel {
  margin-left: 12px;
  cursor: pointer;
  user-select: none;
}

.summaryList {
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--gray-80);
}

.summaryList > * {
  margin-bottom: 8px;
}

.dataList {
  margin-bottom: 25px;
}

.formatSelection {
  margin: 8px 0;
  padding-left: 16px;
}

.formatListItem {
  margin: 16px 0;
}

.formatRadio {
  margin-right: 8px;
}
