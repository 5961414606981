.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.actions {
  display: flex;
  flex-direction: row;
}

.toggleItem:hover .only {
  display: inherit;
}
