@import '../../styles/colors.css';

.featureMenu {
  display: flex;
  position: fixed;
  top: 0;
  justify-content: center;
  z-index: 1000;
  width: 100%;
}

.featureMenu .menuContainer {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: var(--gray-10);
  padding: 10px;
  width: 400px;
}

.menuCaption {
  border-radius: 0 0 10px 10px;
  background-color: var(--gray-10);
  padding: 5px;
}

.actionButton {
  align-self: flex-end;
  margin-top: 5px;
}
