@import '../../../../styles/variables.module.css';
@import '../../../../styles/sizes.css';

.impactCheckbox {
  box-sizing: border-box;
  display: inline-block;
  transition: all 150ms;
  border: var(--impact-check-box-shape-size) solid var(--gray-80);
  cursor: pointer;
  width: var(--impact-checkbox-size);
  height: var(--impact-checkbox-size);
}

.impactCheckboxSelected {
  border: var(--impact-check-box-shape-size) solid var(--light-blue);
  background: var(--light-blue);
}

.impactCheckboxDisabled,
.impactCheckboxDisabledAndChecked {
  border: var(--impact-check-box-shape-size) solid var(--gray-40);
}

.impactCheckboxDisabledAndChecked {
  background: var(--gray-40);
}

.checkboxIcon {
  width: var(--impact-checkbox-icon-size);
  height: var(--impact-checkbox-icon-size);
  fill: none;
  stroke: white;
  stroke-width: var(--impact-check-box-shape-size);
}

.checkboxIconChecked {
  fill: white;
}

.checkboxLabel {
  color: var(--gray-90);
}

.subLabel {
  padding-left: var(--impact-checkbox-size);
  color: var(--gray-80);
}
