.page {
  --spacing: 10px;
  --page-horizontal-padding: 20px;
  --header-padding: 140px;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-padding);
  height: calc(100vh - var(--header-padding));
}
.filters {
  display: grid;
  grid-template-areas:
    'search search search search search'
    'button loader untranslatedfilter untranslatedratio pagination';
  grid-template-columns: max-content 260px auto max-content max-content;
  row-gap: var(--spacing);
  column-gap: var(--spacing);
  padding: 0 var(--page-horizontal-padding) var(--page-horizontal-padding);
}
/** START HACK:
 * TK UI components still use BEM styling model, so we need to cheat to override styles.
 * We can't use nested classes to target the UI components BEM names since the CSS Module
 * loader will see them and replace them with random classes.
 * So instead, use attribute contains matching */
.filters [class~='tk-button--margin-left'] {
  grid-area: button;
  margin-left: 0;
}

.filters > [class~='tk-searchInput'] {
  grid-area: search;
}
/* END HACK */

.listPhrases {
  flex: 1;
  margin: 0;
  padding: 0 var(--page-horizontal-padding);
  overflow-x: hidden;
  overflow-y: auto;
}

.loader {
  grid-area: loader;
  align-self: center;
  justify-self: start;
  margin: 0;
}

.checkboxShowOnlyUntranslated {
  grid-area: untranslatedfilter;
  align-self: center;
}

.checkboxShowOnlyUntranslated label {
  align-items: unset;
  /** nitpick, the center alignment from checkbox looks awful if not overriden */
}

.untranslatedRatio {
  grid-area: untranslatedratio;
  align-self: center;
}

.pagination {
  grid-area: pagination;
  align-self: center;
}
.pagination ul {
  margin: 0;
}
