.pageContainer {
  display: grid;
  grid-template-rows: minmax(40px, auto) minmax(57px, auto) 1fr;
  grid-template-columns: 1fr;
}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
