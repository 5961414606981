.savedListTable {
  display: flex;
  flex-flow: column nowrap;
  color: var(--gray-90);
}

.truncable {
  width: 100%;
}

.info {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #f1f1f1;
  background-color: var(--white);
}
.tableWrapper {
  flex: 1 1 auto;
  width: 100%;
  min-height: 0;
  overflow-y: auto;
}

.tableWrapper :global(.tk-grid-table__row--header) {
  justify-content: space-between;
}

/* TODO: Remove this when the grid table is ported outside of tk-ui or moved to
  using grid instead of flex. This is a work around to acomodate for a white that
  should exist to the right of the header of the table, but wasn't of enough size */
.tableWrapper :global(.tk-grid-table__row--header #actions) {
  flex-basis: 9%;
  max-width: 9%;
}

.paginationBar {
  display: flex;
  justify-content: flex-end;
  padding: 4px 15px;
  height: 45px;
}

.emptyResults {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 280px;
}

.barRight {
  display: flex;
}

.sorting {
  margin-left: 20px;
}

.singleSelect {
  margin: 0 10px;
}

.filter {
  padding: 4px 15px;
}

.listSearchInput {
  position: relative;
}

.searchResultsTitle {
  padding: 20px 20px 0 20px;
  color: var(--gray-60);
}

.checkbox, .selectAllCheckBox {
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.selectAllCheckboxWrapper {
  padding: 0px 10px;
  line-height: 0;
}

.selectAllCheckboxWrapper > span {
  color: var(--gray-80);
}

.listName {
  display: flex;
  align-items: center;
  justify-content: center; 
}

.selectedItemsWrapper {
  display: flex;
  align-items: center;
}

.deleteButton {
  margin-left: 15px;
}

.iconDeleteContainer {
  display: flex;
  align-self: center;
}

.iconDelete {
  margin-right: 2px;
}
