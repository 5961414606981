.navigationButton {
  fill: var(--gray);
  display: flex;
  align-items: center;
}

.navigationButtonText {
  margin-left: 4px;
}

.navigationFilesSizeProgress {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
