.simpleTabMenu li:not(:first-child) {
  color: var(--gray-40);
  pointer-events: none;
}

.createNotesBody {
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.notesEditorIcon {
  fill: var(--gray-40);
}

.notesContainer {
  margin-top: 8px;
  margin-bottom: 10px;
  border: 1px solid var(--charting-teal-light);
  border-radius: 4px;
  min-height: 200px;
}

.profileTabContainer {
  margin-bottom: 10px;
  min-height: 308px;
}

.notesBody {
  margin-top: 24px;
}

.pinedNoteLabel {
  border-radius: 12px;
  background-color: var(--gray-20);
  padding: 4px 8px;
  text-transform: capitalize;
  line-height: 16px;
  color: var(--gray-90);
  font-size: 12px;
}

.lastModifiedrow {
  margin: 8px 0;
  line-height: 16px;
  font-size: 12px;
}

.lastModifiedDate,
.lastModifiedTime {
  margin: 0 4px;
}

.lastModifiedLightText {
  margin-left: 4px;
  color: var(--gray-40);
}

.youTextWord {
  margin: 0 4px;
  text-transform: capitalize;
}

.notesTextBody {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 16px;
}
