.topBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: solid 1px var(--gray-20);
  background: var(--white);
  height: 55px;
  color: var(--gray-80);
}

.leftContent {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.divider {
  flex: 1;
}

.rightContent {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}

.pagination {
  padding: 2px 16px;
  width: max-content;
}

.sorting {
  padding: 5px 16px;
}
