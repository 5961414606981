.container {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  margin: 0px;
  border: 0px;
  border-radius: 50%;
  background-color: transparent;
  padding: 8px;
  fill: var(--gray-80);
}

.button:first-of-type {
  margin-right: var(--impact-pagination-controls-space);
}

.button:last-of-type {
  margin-left: var(--impact-pagination-controls-space);
}

.button:hover {
  fill: var(--gray);
  background-color: var(--gray-20);
}

.disabled,
.disabled:hover {
  background-color: transparent;
  cursor: not-allowed;
  fill: var(--gray-40);
}

.paginationText {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 14px;
}
