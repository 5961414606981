.TwitterProfile {
  margin-bottom: 40px;
  color: var(--black-10);
}

.header__title {
  font-size: 16px;
  font-weight: var(--bold);
}

.stats__list {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-inline-start: 0;
}

.stats__list li {
  margin-right: 15px;
}

.stats__list li span {
  font-weight: var(--bold);
}

.skeletonWrapper {
  margin: 20px 0 20px 0;
}

.skeletonHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.skeletonHeaderAvatar {
  margin: 0 5px 0 0;
}

.skeletonBody {
  margin-bottom: 15px;
}

.skeletonFooter {
  display: flex;
}

.skeletonFooterItem {
  margin-right: 5px;
}

.skeletonBar {
  margin-bottom: 8px;
}
