@import '../../../../../styles/colors.css';

.StoryHubIndicators {
  padding: 8px 16px;
  background: var(--white);
  border-bottom: 1px solid #ebeced;
}

.statusBarWrapper {
  margin-bottom: 10px;
}

.toggleWrapper {
  margin-right: 8px;
}

.linkWrapper {
  margin-left: 16px;
}
