@keyframes fadein {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideup {
  from {
    transform: translateY(10px);
  }

  to {
    transform: translateY(0px);
  }
}

.managePersonaDataLayerAccordions {
  --duration: 300ms;
  animation: fadein var(--duration) ease-in, slideup var(--duration) ease-in;
}

.managePersonaDataLayerContainer {
  width: 100%;
}
