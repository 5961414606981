.sidebar {
  flex-grow: 0;
  border-right: 1px solid var(--gray-20);
  background-color: var(--white);
  min-width: 0;
  min-height: 100vh;
}

.customContent {
  padding: 16px;
}

.mediaResearchIcon {
  transition: all .2s ease-in-out;
  margin-right: 8px;
  cursor: pointer;
  color: var(--gray-80);
}

.mediaResearchIcon:hover {
  color: var(--light-blue-hover);
}