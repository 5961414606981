.container {
  display: grid;
  grid-template-columns: 150px auto max-content;
  column-gap: 8px;
  margin-bottom: 8px;
  min-height: 24px; /* Edit button is higher than loader, so it jumps around a bit if we don't do this */
}

.inputText {
  width: 100%;
}

.editActions > button {
  margin: 3px 3px 3px 0;
}

.labelDefault {
  opacity: 0.6;
}

.labelError {
  color: #ff4433;
}
