.radioButton {
  display: flex;
  align-self: center;
  margin-right: 5px;
}

.IsCompletedContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.NormalContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.error {
  border-color: var(--error);
  background-color: var(--error-light);
  color: var(--error);
}

.errorMessage {
  margin-top: 8px;
  line-height: 16px;
  color: var(--error);
  size: 12px;
}

.optionContainer {
  display: flex;
  flex-direction: row;
}
