.wrapper {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

.suggestedCards {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 15px;
  width: 98%;
}

.suggestedCards > div {
  width: 100%;
}

.loadingMedia.loadingMedia {
  flex-wrap: wrap;
}

.loadingMediaItem.loadingMediaItem {
  margin: 10px 0;
  min-width: 300px;
}

.title {
  margin-top: 10px;
  margin-bottom: 15px;
}

.avatarWrapper {
  margin-right: 10px;
}

.outletAvatarWrapper {
  border-radius: 100px;
  box-shadow: 0 0 0 2px #fff, 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
}

.outletAvatarWrapper img {
  width: 45px;
  height: 45px;
}

.mediaBody {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.influencerInfo {
  flex: 0 0 100%;
}

.seeMore {
  margin-top: 10px;
}

.goToResultsLink a {
  text-decoration: none;
  color: var(--color-link);
}

.clickableItem {
  cursor: pointer;
}

.badge {
  margin: -2px 0 0 -14px;
}
