.header {
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--gray-10);
  padding: 3px 15px;
  letter-spacing: 1px;
  white-space: nowrap;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
  width: 100%;
}

.clearButton.clearButton {
  margin-left: 10px;
  font-size: 12px;
}
