.sideTrayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sideTrayFooter {
  display: flex;
  justify-content: flex-end;
}

.pillEdit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 50%;
  background-color: var(--light-teal-hover);
  width: 25px;
  height: 25px;
  color: var(--white);
}

.primarySearch {
  margin: 10px 0 20px 0;
}
