.truncable {
  width: 100%;
}

.info {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #f1f1f1;
  background-color: white;
}

.paginationBar {
  display: flex;
  justify-content: flex-end;
  padding: 4px 15px;
  height: 45px;
}

.emptyResults {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 280px;
}
