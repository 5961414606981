.title {
  padding-bottom: 10px;
}

.input {
  flex-basis: 240px;
  margin-right: 15px;
}

.searchButton {
  /* padding, since any change to width means it
  aligns text to the left instead for some reason */
  padding-right: 40px;
  padding-left: 40px;
}
