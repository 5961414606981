.amountContainer {
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.accountsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
}
