.darkGrayBar {
  border-radius: 50px;
  background-color: var(--gray-40);
  width: 230px;
  height: 16px;
}

.grayButton {
  margin-right: 16px;
  border-radius: 50px;
  background-color: var(--gray-20);
  width: 120px;
  height: 40px;
}

.blueButton {
  border-radius: 50px;
  background-color: var(--light-blue);
  width: 120px;
  height: 40px;
}
