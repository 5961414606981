@import '../../../styles/colors.css';

.wrapper {
  margin-top: 125px;
  min-height: calc(100% - 125px);
}

.cardsContainer {
  display: grid;
  grid-template-rows: minmax(581px, auto);
  grid-template-columns: repeat(2, 577px);
  justify-content: center;
  column-gap: 100px;
  padding-bottom: 16px;
}

.importEarnedMedia {
  color: var(--light-blue);
}

.importEarnedMedia:hover {
  color: var(--light-blue);
}

.builderOptions {
  margin-top: 24px;
}

.builderOptions ul {
  margin-left: 0px;
}

.builderOptions ul li:first-child a {
  padding-left: 0px;
}

.previewCard {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.previewCard::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.keywordSocialStreamTitle {
  width: 74px;
  height: 24px;
  line-height: 24px;
  color: #677078;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.keywordSocialStreamInputBox {
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.keywordSocialStreamInputBoxError {
  box-sizing: border-box;
  border: 1px solid #de4543;
  border-radius: 4px;
  background: #feebea;
}

.rightActions {
  display: flex;
  align-items: center;
}

.rightActions > *:not(:last-child) {
  margin-right: 8px;
}
