.row {
  clear: both;
  margin: 0;
}

.modalLoader {
  position: absolute;
  top: 41%;
  left: 56%;
}

.screenFileUpload {
  margin: 28px;
}

.modalBtn {
  margin-left: 81%;
}

.leftPane {
  padding: 25px 0;
}

.dummyheight {
  border-left: solid 1px #ebeced;
  width: 100%;
  min-height: 494px;
}

.verticalTab {
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: #f6f8f8;
  height: 40px;
}

.browseFromComputerHeaderText,
.browseFromUrlHeaderText {
  margin-top: 21px;
  margin-left: 20px;
  width: 100%;
  color: #34404b;
}

.uploadFromComputerBrowseButton {
  position: absolute;
  right: 5%;
  bottom: 16%;
  opacity: 0;
  z-index: 999;
  padding: 3px;
  width: 87%;
}

.getImageInputButton {
  position: absolute;
  top: 108px;
  right: 59px;
  width: 116px;
}

.browseFromComputerSupportedText {
  position: absolute;
  top: 7%;
  margin-left: 2.5%;
  width: 100%;
  font-size: 12px;
}

.uploadFromComputerBrowseFilesButton {
  position: absolute;
  top: 19px;
  right: 17px;
}

.verticalTabSelected {
  box-shadow: inset 5px 0 0 0 #4ecdcf;
}

.browseFromComputerHeader {
  border-bottom: 1px solid #ebeced;
  width: 700px;
  min-height: 60px;
}

.browseFromUrlHeader {
  border-bottom: 1px solid #ebeced;
  width: 656px;
  min-height: 42px;
}

.enterUrlHeading {
  margin-left: 20px;
  font-size: 14px;
}

.uploadFromUrlDeleteIcon {
  position: absolute;
  top: -2px;
  right: 22px;
}

.uploadFromComputerBrowseButtonRight {
  position: absolute;
  right: 44px;
  width: 128px;
}

.getImageInputtext {
  width: 70%;
}

.imageUrlSection {
  margin-left: 20px;
}

.uploadFromComputerMediaStudioSection {
  position: absolute;
  top: 413px;
  left: 175px;
  border-top: 1px solid #ebeced;
  width: 700px;
  height: 88px;
}

.hoverMenuForUploadFromComputer {
  position: absolute;
  top: 4px;
  right: 9px;
  width: 100%;
  height: 100%;
  text-align: right;
}

.UploadFromComputerCheckboxSection {
  margin-left: 32px;
}

.uploadFromComputerAgrementText {
  margin-left: 53px;
  font-size: 12px;
}

.UploadFromComputerCheckboxText {
  margin-left: 7px;
}

.uploadFromComputerCheckboxTextLearnMore {
  position: absolute;
  top: 30px;
  right: 5%;
  cursor: pointer;
  color: #3497db;
}

.tabText {
  cursor: pointer;
  padding: 8px;
}

.mediaIconFileUpload {
  position: absolute;
  top: 34px;
  left: 16px;
  object-fit: contain;
}

.mediaLabel {
  position: absolute;
  left: 5% !important;
  width: 138px;
  height: 24px;
  line-height: 1.71;
  letter-spacing: normal;
  color: #34404b;
  font-size: 14px;
  font-stretch: normal;
  font-weight: bold;
  font-style: normal;
}

.bottomInfoSectionWrapper {
  position: absolute;
  right: 38.2%;
  bottom: 25px;
  width: 100%;
  text-align: center;
}

.uploadFileInfo {
  margin: 0 auto;
  width: 130px;
  text-align: left;
  color: #8a99a2;
  font-size: 12px;
}

.assetFileUploadWrapper {
  margin-bottom: 125px;
  text-align: center;
}

.uploadMediaButton {
  margin-top: 50px;
  cursor: pointer;
}

.lighthouseIcon {
  margin-top: 48px;
  height: 180px !important;
  object-fit: contain;
}

.dropzoneRightPanel {
  position: none;
  border: none;
}

.addFileText {
  margin: 0;
  padding-top: 24px;
  color: #34404b;
  font-size: 16px;
  font-weight: bold;
}

.addTextSection {
  margin-top: 24px;
}

.dragDropText {
  margin: 0;
  padding: 12px 0;
  color: #677078;
  font-size: 14px;
}

.rightPane {
  border-left: solid 1px #ebeced;
  background-color: rgba(250, 254, 255, 0.5);
  width: 601px;
  height: 490px;
}

.leftPanelUploadBtn {
  position: absolute;
  right: 33%;
  bottom: 27%;
  opacity: 0;
  z-index: 999;
  padding: 3px;
  width: 14%;
}

.disclaimerText {
  display: flex;
  position: absolute;
  top: 78.8%;
  left: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 0 0 #f8ca00;
  box-shadow: 0 1px 0 0 #f8ca00;
  background-color: #fef8d3;
  padding: 18px;
  width: 838px;
  text-align: justify;
}

.disclaimerWarningIcon {
  margin-top: 3px;
  margin-right: 18px;
}

.warningIcon {
  width: 24px;
  height: 24px;
}

.disclaimerTextMessage {
  width: 800px;
  line-height: 1.71;
  letter-spacing: normal;
  color: #34404b;
  font-size: 14px;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
}

.disclaimerCloseIcon {
  margin-top: 6%;
  margin-left: 30px;
  cursor: pointer;
  width: 15%;
}

.leftPanelUploadBtnInsideLibrary {
  position: absolute;
  right: 42%;
  bottom: 7%;
  opacity: 0;
  z-index: 999;
  padding: 3px;
  width: 15%;
}

.closeBtn {
  cursor: pointer;
}

.mediaPopupUpload {
  width: 23px;
  height: 16px;
}

.imgHolder {
  margin-top: 2px;
  border-radius: 5px;
  width: auto;
  max-width: 187px;
  max-height: 126px;
  object-fit: contain;
}

.imgHolderForUploadFromUrl {
  margin-top: 5px;
  border-radius: 5px;
  width: auto;
  max-width: 187px;
  max-height: 186px;
  object-fit: contain;
}

.checkmark {
  position: absolute;
  top: 0px;
  right: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  width: 20px;
  height: 20px;
}

.checkmarkCheckbox {
  position: absolute;
  top: 2px;
  right: 8px;
  border: 2px solid #fff;
  background-color: transparent;
  width: 14px;
  height: 14px;
}
.sortingContent {
  display: flex;
  position: absolute;
  top: 7%;
  right: 20px;
  font-weight: 700;
}

.liStyle {
  display: inline-block;
  margin-left: 25px;
  background: none;
  cursor: pointer;
  height: 28px;
  color: #677078;
  font-size: 16px;
}

.sortSection {
  display: flex;
  flex-wrap: nowrap;
}

.imageContainer {
  text-align: center;
  line-height: 128px;
}

.imageContainerImage {
  max-width: 171px;
}

.progressBarContainer {
  padding-top: 60px;
}

.current {
  box-shadow: inset 0 -2px 0 0 #0092c2;
  color: #34404b;
  font-weight: bold;
}

.progress {
  margin: auto;
  border-radius: 5px;
  background-color: #ebeced;
  width: 100px;
  height: 4px;
}

.progressBar {
  background-color: #0092c2;
  width: 80%;
  height: 100%;
}

.progressState {
  margin: auto;
  padding-top: 5px;
  width: 100px;
}

.thumbnailInfo {
  margin-left: 10%;
}

.imgName {
  margin-bottom: 0;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #34404b;
  font-size: 14px;
}

.imgSize {
  margin-bottom: 0;
  color: #8a99a2;
  font-size: 12px;
}

.uploadIconForRightPane {
  position: absolute;
  margin-top: 11px;
}

.uploadIconForLeftPane {
  position: absolute;
  left: 44%;
  margin-top: 11px;
}

.browseLibraryText {
  margin-left: 25px;
  cursor: pointer;
  color: #49aacd;
  font-weight: 600;
}

.browseLinkDiv {
  position: relative;
}

.browseIcon {
  position: absolute;
  bottom: 3%;
  color: #677077;
}

.imgSizeDetails {
  padding-right: 10px;
}
.selectedAsset {
  position: absolute;
  top: 4px;
  right: 9px;
  z-index: 999;
}

.deleteAsset {
  margin-top: 4px;
}

.checkmarkCheckboxForDownloadable {
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 999;
  border: 2px solid #fff;
  background-color: transparent;
  width: 16px;
  height: 16px;
}

.dot {
  display: inline-block;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #fff;
  width: 5px;
  height: 5px;
}

.selectedContainer {
  position: absolute;
  top: 9px;
  left: 189px;
}

.selectedContainerForDownloadable {
  position: absolute;
  top: 13px;
  left: 163px;
}

.currentSelected {
  border: 1px solid #3398db !important;
}

.hoverMenu {
  display: none;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: right;
}

.menuDots {
  display: inline-block;
  position: relative;
  top: 10px;
  right: 35px;
}

.downloadAsset {
  margin-top: 4px;
}

.downloadAsset a {
  text-decoration: none;
  color: inherit;
}
.assetNoAction {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.noTextLabel {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.editMenu {
  position: absolute;
  top: 10px;
  left: 35px;
  opacity: 1;
  z-index: 999;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding: 20px;
  width: 64px;
  height: 56px;
  text-align: left;
}

.dotsSection {
  position: absolute;
  float: right;
  z-index: 999999;
  margin-top: 6%;
  margin-left: 64%;
  border-radius: 4px;
  padding: 1px;
  width: 17%;
}

.thumbnailContainer {
  position: relative;
  float: left;
  margin: 25px 25px 0 0;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 0;
  width: 188px !important;
  height: 195px;
  max-height: 270px;
}
.thumbnailContainerForUploadFromComputer {
  position: relative;
  float: left;
  margin: 25px 25px 0 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 0;
  width: 188px !important;
  height: 195px;
  max-height: 270px;
}

.assetsSelected {
  border: 1px solid #0092c2;
}

.thumbnailContainer:hover .hoverMenu {
  display: block;
}

.thumbnailContainer:hover .imgHolder {
  opacity: 0.2;
}
.thumbnailContainer:hover .thumbnailImage {
  background-color: #97d3eb;
}

.dragOver {
  border: 1px solid #0092c2;
}

.dropFilesSection {
  position: absolute;
  top: 40%;
  left: 25%;
  border-radius: 5px;
  box-shadow: inset 0 -1px 0 0 #0092c2;
  background-color: #e3f6fc;
  width: 332px;
  height: 56px;
}

.dropFilesText {
  position: absolute;
  margin-top: 20px;
  margin-left: 10px;
  width: 300px;
  height: 24px;
  text-align: center;
  line-height: 1.71;
  letter-spacing: normal;
  color: #34404b;
  font-size: 14px;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
}
.tabContent {
  padding: 0;
  height: 321px;
  overflow-y: scroll;
}

.tabs {
  margin: 0px;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px;
  padding-right: 30%;
  width: 63%;
  list-style: none;
}

.sortDropdown {
  -webkit-appearance: none;
  display: inline-block;
  border: none;
  box-shadow: none;
  padding: 0 10px 0 5px;
}

.assetSorting {
  padding: 0;
}

.buttonRow {
  clear: both;
  margin: 0;
  margin-bottom: 10px;
}

.rightPaneEdit {
  border-left: 1px solid #dee5e7;
  padding: 25px;
}

.deleteSaveContainer {
  position: absolute;
  top: 20px;
  right: 78px;
  padding: 0;
  text-align: right;
}

.assetImageContainer {
  margin-bottom: 25px;
  width: 552px;
}

.assetIsArchived {
  float: right;
  color: #3398db;
}

.imageNameDetails {
  border: solid 1px #cccfd2;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 10px;
  width: 45%;
  color: #677078;
}

.imageSizeDetails {
  position: absolute;
  right: 71px;
  bottom: 169px;
  text-align: right;
  line-height: 40px;
  color: #8a99a2;
  font-size: 12px;
}

.sizeDetails {
  padding-right: 10px;
}

.editCaption {
  float: left;
  padding-top: 10px;
  color: #58666e;
  font-weight: bold;
}

.editCaptionTextArea {
  border: 1px solid #c3cbcf;
  border-radius: 2px;
  padding: 10px;
  width: 96%;
  height: 60px;
  resize: vertical;
  color: #8a99a2;
}

.imageEditContainer {
  width: auto;
  height: auto;
  max-height: 250px;
  object-fit: contain;
}
@media (min-width: 992px) {
  .col-md-3 {
    width: 25%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
}
