.tagsTable {
  padding: 0;
  overflow-x: hidden;
}

.tagsTableEmptyMessage {
  display: flex;
  justify-content: center;
  padding-top: 120px;
}
