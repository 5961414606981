.wrapper {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  min-width: 0;
  white-space: nowrap;
}

.link {
  margin-right: 10px;
}

.backButton {
  margin-right: 10px;
  padding: 0;
  color: var(--gray-80);
}

.backButton:hover {
  color: var(--gray-80);
}

.breadCrumbs {
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
  line-height: normal;
}

.breadCrumb {
  display: inline-block;
  flex: 0 1 auto;
  min-width: 0;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
}

.breadCrumbLink {
  cursor: pointer;
}

.breadCrumbSeparator {
  display: inline-block;
  margin: 0 16px;
  vertical-align: middle;
}

.breadCrumbDropdown {
  display: inline-grid;
  flex-shrink: 0.1;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  min-width: 0;
}

.dropdownToggle {
  cursor: pointer;
  max-width: none;
}

.dropdownLabel {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownIcon {
  align-self: center;
  margin-top: -2px;
  margin-left: 10px;
}

.dropdownMenu {
  margin-left: 10px;
}

.hideBottomBorder{
  border-bottom: 0px;
}

.influencerListActionsHeadline {
  padding: 10px;
}

.influencerListActionSpacer {
  flex: 1 1;
}

.influencerListActionEmailList {
  margin-left: 10px;
}

.normalButton {
  align-self: auto;
  margin-left: 20px;
  min-width: 120px;
  height: 45px;
}

.searchBarWrapper {
  display: flex;
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .NavBar .kebabButton {
    display: none;
  }

  .normalButton {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .normalButton {
    display: none;
  }
}

@media (max-width: 425px) {
  .NavBarWithDropdown {
    flex-direction: column;
  }

  .rightContentClassModifier{
    margin: 0 auto;
    padding: 0;
    width: 99%;
  }
}

.bottomSpacing {
  padding: 1% 0% 3.5% 0%;
}

.searchResultsTitle {
  padding-top: 20%
}
