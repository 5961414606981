:root {
  /* Sizing */
  --analytics-list-sidebar: 270px;
  --widget-drilldown-sidebar: 370px;
  --drilldown-mention-cell-width: 654px;
  --drilldown-date-column-width: 101px;
  --drilldown-outlet-column-width: 65px;
  --drilldown-author-column-width: 400px;
  --drilldown-outlet-and-author-divider-column-width: 20px;

  /* Default height of the navbar */
  --nav-height: 65px;

  /* Impact sizing */
  --impact-checkbox-size: 18px;
  --impact-checkbox-icon-size: 14px;
  --impact-check-box-shape-size: 2px;
  --impact-audience-radio-button-size: 20px;

  --icon-size-small: 16px;
  --icon-size-large: 20px;

  /* For Earned Search, Tags, Widget, All mentions drilldown */
  --widget-drilldown-left-list-header-sticky: 66px;
  --widget-drilldown-left-list-header-sticky-sidebar-open: 296px;

  /*Admin pages forms*/
  --form-max-width: 585px;
}
