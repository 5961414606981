.container {
  margin-bottom: 16px;
  width: 500px;
}

.error {
  background-color: var(--salmon-lite-2);
  color: var(--error);
}
.warning {
  background-color: var(--warning-light);
  color: var(--gray-90);
}

.pagination {
  display: flex;
  justify-content: right;
}
