@import '../../../styles/colors.css';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  text-align: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
}

.centered {
  padding-top: 40px;
  text-align: center;
}

.pageTitle {
  margin-top: 15px;
  padding-bottom: 10px;
  color: #34404b;
  font-size: 1.7rem;
  font-weight: 700;
}

.paperContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.paperCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 10px;
  padding: 0 20px !important;
}

.paperCard .title {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}
.paperCard .description {
  flex: 1;
  margin-right: 19px;
  margin-left: 19px;
  line-height: 24px;
  font-size: 14px;
  overflow-wrap: break-word;
}

.paperCard .actionButton {
  margin: 24px 58px 24px 58px;
}

.existingSocialLink {
  margin-top: 32px;
}

.svgImage {
  margin-top: 24px;
}
