.mainContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.expansioPanelHeader {
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--gray-10);
  padding: 5px 15px;
  letter-spacing: 1px;
  white-space: nowrap;
}

.expansioPanelHeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.counter {
  margin-right: 15px;
}

.expansionPanelBody {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px;
}

.loadMoreButtonContainer {
  display: grid;
  grid-template-columns: minmax(150px, 200px);
  justify-content: center;
  margin: 10px 0;
  width: 100%;
}

.loadMoreButton {
  display: flex;
  justify-content: center;
}

.selectedDasboard,
.selectedDasboard:focus {
  border-left: #9ddbd7;
  box-shadow: inset 4px 0px 0px var(--light-teal);
  background: var(--gray-10);
}

.dashboardItem {
  border-radius: 0px;
  width: 100%;
}