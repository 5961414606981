.dateGrouping {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 10px;
}

.dropdown {
  border-radius: 10px;
  background-color: var(--teal-lite-2);
  padding: 2px 10px;
  color: var(--white);
}

.dropdown:hover {
  cursor: pointer;
}

.pill {
  display: flex;
  align-items: center;
  margin-right: 5px;
  border-radius: 15px;
  padding: 0px 15px;
  height: 28px;
}

.pill:hover {
  background-color: var(--gray-lite-5);
  cursor: pointer;
}

.dateGrouping .pill:disabled {
  background-color: var(--gray-lite-4);
}

.selected {
  background-color: var(--teal-lite-2);
  color: var(--white);
}
