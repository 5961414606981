.recentlyPublishedTabs {
  border-bottom: 1px solid var(--gray-20);
}

.header {
  background-color: var(--white);
}

.headerInner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.body {
  background-color: var(--gray-lite-6);
}

.topBlock {
  box-shadow: 0 1px 0 0 var(--gray-lite-4);
  background-color: white;
  text-align: center;
}

.button {
  padding: 0 10px;
}

.paddingModifier.paddingModifier {
  padding: 16px;
}

.mediaResearchButtonWrapper {
  margin-bottom: 20px;
}

.mediaResearchButton.mediaResearchButton {
  margin: 0;
  height: 32px;
} 

.profileButtons {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.buttonWrapper {
  margin: 0 3px;
}

.contentWrapper {
  padding: 20px;
}

.activateConnect {
  position: relative;
  border-bottom: 1px solid #007fa9;
  background-color: #c9e8f2;
  padding-top: 8px;
  padding-right: 28px;
  padding-bottom: 24px;
  padding-left: 48px;
  text-align: left;
}
.activateConnect .button {
  position: absolute;
  top: 16px;
  right: 12px;
}

.activateConnect .info {
  position: absolute;
  top: 25px;
  left: 16px;
  color: #007fa9;
}
