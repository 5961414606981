@import '../../../app/src/styles/sizes.css';

.BasePill {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
}

.large {
  padding: 8px;
  line-height: var(--icon-size-large);
  font-size: 14px;
}

.small {
  padding: 4px;
  line-height: var(--icon-size-small);
  font-size: 12px;
}
