.pagination {
  display: flex;
  align-items: center;
  margin: 0 6px;
}
.pagination li {
  display: inline-block;
  margin-left: 0;
  border: none;
  background-color: inherit;
  height: 19px;
  list-style: none;
  color: black;
}

.pagination li a {
  padding: 10px 5px;
}

.pagination li:nth-child(2) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 5px;
}

.paginationXs li a {
  padding: 10px 15px;
}

.nextPagecontrolDisabled li:last-child a {
  cursor: default;
  color: var(--gray-lite-3);
  pointer-events: none;
}

.pagination li:nth-child(2) a:hover {
  border-color: transparent;
  background-color: inherit;
  cursor: default;
}

.paginationXs li:nth-child(2) {
  display: none;
}

.previousLinkClassName:hover, .nextLinkClassName:hover {
  border: 1px solid transparent ! important;
  background-color: transparent ! important;
}

.previousLinkClassName svg, .nextLinkClassName svg {
  border-radius: 16px;
}

.previousLinkClassName svg {
  margin-right: -4px;
}

.nextLinkClassName svg {
  margin-left: -4px;
}

.previousLinkClassName svg:hover, .nextLinkClassName svg:hover {
  background-color: var(--gray-20);
}

.paginationLink {
  border: none;
  background-color: inherit;
  padding: 0;
  color: black;
}
