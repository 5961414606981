.pane {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: 0 16px 16px;
}

.aceEditor {
  flex-grow: 1;
  color: #fff !important; /* since aceEditor is so shittily written that it overrides its own themes with default styles */
}
