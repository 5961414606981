.sideBarWrapper {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--gray-20);
  background-color: var(--white);
}

.text {
  text-decoration: none;
}

.insideLink {
  display: flex;
  align-items: center;
}

.iconStart {
  flex-basis: 15%;
}

.iconEnd {
  flex-basis: 10%;
  text-align: center;
}

.linkText {
  flex-basis: 75%;
  text-align: left;
}

.sideBarLinkSeparated button {
  width: 100%;
}

.sideBarButton {
  display: block;
  border: 0;
  border-left: 5px solid transparent;
  background-color: white;
  cursor: pointer;
  padding: 20px 24px 20px 19px;
  width: 100%;
  text-align: left;
}

.listCount {
  padding-top: 2px;
  text-align: center;
}

.selectedListType,
.selectedListType:hover {
  border-left: 5px solid var(--teal-lite-2);
  background-color: var(--gray-20);
}

.selectedListType .listCount {
  font-weight: var(--bold);
}
