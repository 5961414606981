.confirmationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  text-align: center;
  color: #34404b;
}

.confirmationHeader {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.2;
  font-size: 25px;
}

.confirmationParagraph1 {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 27px;
}

.confirmationParagraph2 {
  line-height: 27px;
}

.gotoAudienceActivations {
  background-color: var(--light-blue);
}

.button {
  display: flex;
  align-items: center;
  fill: var(--gray);
}

.buttonText {
  margin-left: 4px;
  font-weight: normal;
}

.confimationContainerText {
  width: 490px;
}

@media only screen and (min-width: 1024px) {
  .confimationContainerText {
    width: 672px;
  }
}
