.Location {
  position: relative;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--gray-80);
}

.locationPin {
  position: relative;
  top: 5px;
  margin-right: 5px;
}
