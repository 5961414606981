.WidgetFooter {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #ebeced;
  padding: 5px 0px 5px 5px;
  height: 43px;
}

.totalFIlters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 45px;
  font-weight: bold;
}

.tooltip {
  display: flex;
}

.widgetV3FilterPanel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
}

.itemContainer {
  display: flex;
  align-items: center;
}

.dropdownWrapper {
  margin: 0px 0px;
}

.dateCompareWrapper {
  display: flex;
  justify-content: space-between;
}

.deleteDateRangeWrapper {
  justify-content: flex-end;
  margin-right: 5px;
}

.customCalendarPicker {
  position: absolute;
  top: 100%;
  z-index: 10;
}

.customCalendarPickerRight {
  right: 0;
}

.popperDropdown {
  /* It's needed to keep the date range container in front of the widget cards */
  z-index: 10;
}
