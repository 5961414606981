.bodyContainer {
  display: flex;
  flex-direction: row;
}

.rightContent {
  flex: 1;
}

.loaderHeight  {
  height: 300px;
}

.adminBox_tkLoader_position{
  position: absolute;
  top: 40%;
  left: 55%;
}