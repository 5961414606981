@import '../../../styles/colors.css';
.criteriaContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  min-height: 141px;
}

.criteriaContainer > div:last-child {
  border-right: 0;
}

.criteria {
  flex-grow: 1;
}

.specificationHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.specificationCore {
  border-radius: 4px 4px 0 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 0;
}

.pillboxContainer {
  display: flex;
  flex: 1;
  border-right: 1px solid var(--gray-20);
}

.criteriaLabel {
  padding: 11px 0px;
}
