.userForm {
  display: grid;
  grid-template-columns:
    minmax(125px, 1fr) minmax(200px, 3fr) minmax(150px, 2fr)
    minmax(150px, 2fr) minmax(150px, 1fr) minmax(100px, 1fr);
  margin-bottom: 30px;
  border-bottom: 2px solid var(--gray-20);
  padding-bottom: 30px;
}

.formLabel {
  grid-column: 1;
  color: var(--gray-90);
  font-weight: 700;
}

.emailInput {
  grid-column: 2;
}

.firstNameInput {
  grid-column: 3;
}

.lastNameInput {
  grid-column: 4;
}

.role {
  grid-column: 5;
}

.submitButton {
  grid-column: 6;
  padding-left: 20px;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
