.reportHeaderInputTitle {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: var(--bold);
}

.reportHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.reportHeaderDateRange {
  padding-right: 10px;
  font-size: 14px;
}

.reportsV3HeaderWrapper {
  z-index: 601;
  padding-top: 15px;
}

.reportsV3HeaderInputTitle {
  color: var(--gray);
}
