.bodyContainer {
  min-height: 80px;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rightSideTableHeader button,
.leftSideTableHeader button {
  margin-left: 10px;
}

.gcIdInput label,
.domainInput label,
.customDkimSelectorInput label {
  display: block;
}

.fullWidth {
  width: 100%;
}

.table,
.domainInput,
.customDkimSelectorInput {
  margin-top: 20px;
}

.hasRecords {
  margin-top: 40px;
}

.noRecords {
  margin: 70px 0 35px 0;
  text-align: center;
  font-size: 21px;
}

.domainRow {
  border-bottom: 2.5px solid #ebeced;
}

.valid {
  color: green;
}

.invalid {
  color: red;
}

.loader {
  margin-right: 10px;
}

.gridTable {
  border-top: 1px solid #ebeced;
  max-height: calc(100vh - 265px);
  overflow-x: scroll;
  overflow-y: auto;
}

.clickableIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
