.emailRow {
  width: auto;
}

.largeCopyButton.largeCopyButton {
  transition: background-color 0.2s ease-in-out;
  margin-top: -5px;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.largeCopyButton.largeCopyButton.largeCopyButton:hover {
  background-color: var(--gray-20);
}

.centerSmallBtnTooltip.centerSmallBtnTooltip {
  right: -12px;
}

.centerLargeBtnTooltip.centerLargeBtnTooltip {
  right: -4px;
  transform: translateY(-20px);
}

.smallCopyButton {
  visibility: hidden;
}

.emailRow:hover .smallCopyButton {
  visibility: visible;
}

.email {
  text-decoration: none;
  line-height: inherit;
  color: var(--color-link);
  font-size: inherit;
  font-weight: inherit;
}

.email.email {
  user-select: all;
}
.emailDisabled {
  display: inline-block;
  color: var(--gray-40);
  pointer-events: none;
}

.emailDisabled::first-letter {
  text-transform: uppercase;
}
