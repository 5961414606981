.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 50%;
  background-color: transparent;
  width: 40px;
  min-width: 40px;
  height: 40px;
  color: var(--gray);
}

.container:hover {
  background-color: var(--gray-20);
  cursor: pointer;
  color: var(--gray-90);
}

.icon {
  width: 24px;
  height: 24px;
}
