.OmniBar {
  visibility: visible;
}

.modalBody.modalBody {
  padding: 0;
}

.query {
  box-sizing: border-box;
  display: block;
  border: 0 transparent none;
  border-radius: 10px;
  padding: 12px;
  width: 100%;
}

.queryIcon.queryIcon {
  margin-right: 8px;
  padding: 12px;
}
