.attributionCarouselWidget {
  padding: 0;
}
.attributionCarousel {
  padding-left: 28px;
}
.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  opacity: 1;
  margin-right: 4px;
  padding-bottom: 10px;
  padding-left: 8px;
  width: 200px;
  height: 100%;
  min-height: 141px;

  @media only screen and (min-width: 1024px) {
    margin-right: 24px;
    padding-left: 16px;
    width: 236px;
  }
}

.hidden {
  opacity: 0;
}

.cardTitle {
  margin: 16px 0px 4px;
  text-align: left;
  color: var(--gray-90);
  font-size: 16px;
}

.conversionValueContainer {
  margin-top: 14px;
}

.cardNumberViews {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.5;
  color: var(--gray-90);
  font-size: 32px;
  font-weight: 700;
}
.cardSubtitle {
  margin: 0;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: 1px;
  color: var(--gray-80);
  font-size: 12px;
}
.sliderHeader {
  --border: solid 1px var(--gray-20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: var(--border);
  border-bottom: var(--border);
  padding: 0px 28px 0px 24px;
  height: 54px;
}
.controlsContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.controlsText {
  min-width: 60px;
  text-align: center;
}
.controlsArrow {
  display: flex;
  align-items: center;
  border: none;
  cursor: not-allowed;
  color: var(--gray-40);
  user-select: none;
}
.controlsArrow:first-of-type {
  padding-right: 12px;
}
.controlsArrow:last-of-type {
  padding-left: 12px;
}
.controlsArrow.enabledArrow {
  cursor: pointer;
  color: var(--gray-80);
}
