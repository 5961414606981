.loaderWrapper {
  display: flex;
  justify-content: center;
}

.textWrapper {
  margin-top: 25px;
  margin-left: 8px;
  line-height: 50px;
}

.chartsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 44px;
}

.vertBarChartWrapper {
  display: flex;
  position: relative;
  left: 100px;
  justify-content: center;
  z-index: 1;
  border-radius: 4px;
  background: linear-gradient(
    178.8deg,
    var(--white) 67.11%,
    rgba(255, 255, 255, 0) 98.97%
  );
  width: 256px;
  height: 233px;
}

.listChartWrapper {
  display: flex;
  position: relative;
  left: -100px;
  flex-direction: column;
  z-index: 1;
  border-radius: 4px;
  background: linear-gradient(
    178.8deg,
    var(--white) 67.11%,
    rgba(255, 255, 255, 0) 98.97%
  );
  width: 256px;
  height: 233px;
}

.pieChartWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  border-radius: 4px;
  background: linear-gradient(
    178.8deg,
    var(--white) 67.11%,
    rgba(255, 255, 255, 0) 98.97%
  );
  width: 352px;
  height: 400px;
}

.listItemWrapper {
  position: relative;
  left: -50px;
  height: 50px;
}
