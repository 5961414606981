.addToListButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 5px;
}

.addToListButton:hover {
  background-color: var(--gray-20);
}

.centerAlignTooltip.centerAlignTooltip {
  right: -3px;
}
