.feedActionsSection {
  margin-top: 15px;
  margin-bottom: 50px;
  overflow: hidden;
}

.newFeedButton {
  float: right;
  margin-right: 20px;
}

.feedsTableSearchBar {
  display: flex;
  float: right;
  justify-content: flex-end;
}
