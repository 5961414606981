.loadingContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.loadingRightIcon {
  margin: 0px;
}

.loadingArticleDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loadingAvatarAndName {
  width: 144px;
}

.loadingArticleDetailsColumn {
  display: flex;
  align-items: center;
}

.loadingArticleDetailsText {
  margin: 0px 12px;
  width: 97px;
  height: 15px;
}

.loadingArticleDetailsRightIcons > * {
  margin-left: 8px;
}

.loadingHeader {
  margin: 28px 0px;
}

.loadingTextImage {
  display: flex;
  justify-content: space-between;
}

.loadingText {
  width: 330px;
}

@media screen and (min-width: 1440px) {
  .loadingText {
    width: 453px;
  }
}

.loadingText > * {
  margin-bottom: 16px;
}
