.topOutletRow {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  height: auto;
  min-height: 92px;
  max-height: 92px;
}

.topOutletRow:last-child {
  border-bottom: unset;
}

.topOutletInfo {
  display: flex;
  flex: 0.6;
}

.topOutletIconContainer {
  box-sizing: border-box;
  margin-right: 8px;
  padding-top: 12px;
  width: 96px;
  min-width: 96px;
  height: 80px;
}

.topOutletInfoBody {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  padding-top: 12px;
}

.topOutletInfoDomain {
  margin-bottom: 4px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
}

.topOutletNumber {
  min-width: 54px;
  line-height: 24px;
  color: var(--gray-90);
  font-size: 16px;
}

.topOutletNumber::after {
  content: '.';
}

.outletName {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  white-space: nowrap;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: 700;
}

.numberOfArticlesContainer {
  flex: 0.2;
}

.avatar {
  margin-right: 8px;
}

.topOutletsViewMetricContainer {
  flex: 0.2;
}

.numberOfArticles,
.topOutletsViewMetricNumber {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}
.numberOfArticlesText,
.topOutletsViewMetricText {
  line-height: 16px;
  color: var(--gray-90);
  font-size: 12px;
}

@media only screen and (min-width: 1024px) {
  .outletName {
    max-width: 400px;
  }
}

@media only screen and (min-width: 1440px) {
  .outletName {
    max-width: 100%;
  }
}
