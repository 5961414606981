.chartContainer {
  display: flex;
  padding-bottom: 16px;
}

.chart {
  padding-top: 16px;
  width: calc(100% - var(--impact-attribution-legends-width));
}

.legends {
  width: var(--impact-attribution-legends-width);
}

.legendsList {
  display: block;
  margin: 0px;
  margin-top: 14px;
  padding: 0px;
  list-style: none;
}

.legendsItem {
  border-top: 1px solid var(--gray-20);
}

.legendsHeader {
  display: flex;
  justify-content: space-between;
  /* 10px padding comes from tk-ui/src/chart-legend/chart-legend-item.scss */
  padding: 0px 10px;
  text-transform: uppercase;
  font-size: 12px;
}
.legendsHeaderClassName {
  position: relative;
  padding-left: 16px;
}

.iconAddon {
  display: flex;
  align-items: center;
  height: 16px;
}
