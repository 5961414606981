.listContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px solid var(--gray-20);
  overflow-y: auto;
}

.loadMoreContainer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--gray-20);
  min-height: 40px;
}

.loadMore.loadMore {
  display: flex;
  justify-content: center;
  width: 100%;
}
