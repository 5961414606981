.wrapper {
  position: fixed;
  right: 0;
  z-index: 900;
  border: 1px solid var(--gray-lite-5);
  background-color: var(--white);
  width: 370px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.headerWrapper {
  flex: 0 0 auto;
  border-bottom: 1px solid var(--gray-lite-5);
  padding: 12px;
}

.content {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.footerWrapper {
  flex: 0 0 auto;
  border-top: 1px solid var(--gray-lite-5);
  padding: 12px;
}

.sideTrayDescription {
  cursor: pointer;
  padding: 16px 15px;
  text-transform: uppercase;
}

.sideTrayTypeSearches {
  padding: 10px 0px;
}

.sideTrayTitleSearches {
  display: flex;
  width: 100px;
  color: var(--light-blue);
}

.header {
  display: flex;
  flex-basis: 56px;
  justify-content: space-between;
  color: #34404b;
}

.header svg:hover {
  cursor: pointer;
}

.headerLeft {
  display: flex;
  margin-left: 5px;
}

.headerRight {
  margin-right: 10px;
}

.footer {
  flex-shrink: 0;
  justify-self: flex-end;
  margin-top: auto;
  -ms-flex-negative: 0;
}

.footerButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.truncable {
  display: flex;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.titleInput {
  margin: 0;
  border: none;
  cursor: pointer;
  width: 280px;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.titleInputFocusWidth {
  width: 200px;
}

.titleInput:hover {
  border: 2px solid #3398db;
}

.titleInput:focus {
  border: 2px solid #3398db;
}

.buttonsContainer {
  display: flex;
}

.iconButton {
  margin-left: 10px;
}
