.modalWrap .modal :global(.tk-modal__dialog) {
  width: 504px;
}

.modalWrap .modal :global(.tk-modal__header) {
  border-bottom: unset;
}

.modalWrap .modal :global(.tk-modal__content) {
  border: unset;
}

.header {
  border-radius: 5px;
  box-shadow: inset 0 -1px 0 0 var(--error);
  background-color: var(--error-light);
}

.deletePersonaTitle {
  font-size: 16px;
}

div.error {
  margin-bottom: 15px;
}

.errorMessage {
  display: block;
  margin-top: 4px;
  line-height: 24px;
}

.loading {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-height: 48px;
}

.deleteText {
  line-height: 24px;
  word-wrap: break-word;
  color: var(--gray-90);
  font-size: 14px;
}
