.reportHeaderTitle {
  padding-left: 10px;
  color: #34404b;
  font-size: 16px;
  font-weight: bold;
}

.reportHeaderDateRange {
  padding-right: 10px;
  font-size: 14px;
}
