.avatarListContainer {
  --extra-margin: 18px;
  position: relative;
  transform: translateZ(0);
  margin-right: calc(-1 * var(--extra-margin));
  margin-left: calc(-1 * var(--extra-margin));
  padding: 10px 0;
  width: 466px; /* dynamic width will break the carousel */
  overflow: hidden;
}
.slider {
  --animation-duration: 250ms;
  transition: transform ease var(--animation-duration) 0s;
  margin-left: var(--extra-margin);
}
.avatarList {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  list-style: none;
}
.avatarList li {
  display: inline-block;
  transition: transform 0.2s;
  opacity: 1;
  margin: 0 13.75px;
  cursor: pointer;
  width: 64px;
  height: 64px;
}
.avatarList li.notVisibleAvatar {
  transition: opacity 0.2s ease-out var(--animation-duration);
  opacity: 0;
}
.avatarList li:first-of-type {
  margin-left: 0;
}
.avatarList li:last-of-type {
  margin-right: 0;
}
.avatarList li:hover,
.avatarList li.selectedAvatar {
  transform: scale(1.25);
}
.avatarList li:hover > div,
.avatarList li.selectedAvatar > div {
  box-shadow: 1px 2px 4px var(--gray-40);
}
.avatar {
  width: 64px;
  height: 64px;
}
.avatarList li div[role='button'] {
  position: relative;
  transition: box-shadow ease-out 0.2s;
  outline: none;
  border-radius: 64px;
  width: 64px;
  height: 64px;
}
.activeIcon {
  display: flex;
  position: absolute;
  top: 42px;
  right: 0;
  align-items: center;
  justify-content: center;
  transform: scale(0.2);
  visibility: hidden;
  border-radius: 25px;
  background-color: var(--light-blue);
  width: 25px;
  height: 25px;
  animation: pop-out 0.2s;
  color: var(--white);
}
.activeIconActive {
  transform: scale(1);
  visibility: visible;
  animation: pop-in 0.2s;
}
.avatarList li.selectedAvatar div[role='button'] {
  box-shadow: 1px 2px 4px var(--gray-40);
}

@keyframes pop-in {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes pop-out {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
  }
}
