.emptyStateText {
  color: var(--gray-90);
}

.noSearchesError {
  width: 396px;
}

.setupIncompleteMessage {
  max-width: 322px;
  line-height: 24px;
}

.contactButton {
  margin-top: 32px;
  margin-bottom: 10px;
}

.learnAboutConversionsButton {
  margin-top: 8px;
}

.searchToRemove {
  color: black;
  font-weight: bold;
}

.modalContent {
  max-width: 580px;
  min-height: 230px;
}

:global(.earned-impact-page)
:global(.earned-impact-page__content)
.earnedImpactListView {
  margin-top: var(--view-title-bar-height);
}
