.noAnalayticsCreatedContainer {
  display: flex;
  align-items: start;
  justify-content: center;
  margin-top: 100px;
}

.noAnalayticsCreatedContent {
  display: flex;
  align-items: center;
}

.noAnalayticsCreatedIcon {
  margin-right: 25px;
}

.noAnalayticsCreatedTitle {
  margin-top: 0;
  margin-right: 32px;
  max-width: 320px;
  font-size: 24px;
}

.noAnalayticsCreatedText {
  max-width: 580px;
  font-size: 16px;
}

.noAnalytics {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  width: 100%;
}

.noFilteredAnalytics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  justify-items: center;
  font-size: 16px;
  font-weight: 700;
}

.noFilteredAnalyticsSearchImage {
  margin-top: 64px;
  margin-bottom: 25px;
  width: 150px;
  height: 150px;
}

.analyticsListContainer {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  min-height: 0;
}

.analyticsListContent {
  display: grid;
  grid-template-rows: 360px 41px auto;
  width: calc(100vw - var(--analytics-list-sidebar));
  height: 86vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebarHidden {
  width: 100%;
}

.analyticsListTopContainer {
  padding-top: 65px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.analyticsListContent::-webkit-scrollbar {
  display: none;
}
