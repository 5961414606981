.leftSideTableHeader {
  display: flex;
  margin-left: 10px;
  width: 30%;
}

.leftSideTabSpan {
  margin-left: 10px;
}  

.createButtonWidth {
  margin-left: 15px;
  width: 75px;
}

.textDisplay {
  display: block;
}

.createdMargin {
  margin-top: 20px;
}