.sectionContainer {
  padding: 8px 0px;
}

.sectionTitle {
  padding: 16px 16px 8px;
  text-transform: uppercase;
  color: var(--gray-90);
}

.divisor {
  background-color: var(--gray-20);
  width: 100%;
  height: 1px;
}
