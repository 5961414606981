/* Tooltip */
.tooltip {
  background-color: var(--gray-90);
  padding: 10px;
}

.error {
  color: var(--white);
  font-weight: bold;
}

.tooltipArrow {
  --tooltipBackground: var(--gray-90);
  --tooltipBorder: var(--gray-90);
  display: block;
}
