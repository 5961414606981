@import '../../../../styles/sizes.css';

.buttonMargin {
  margin-right: 10px;
}

.adminCheckbox {
  padding-bottom: 10px;
}

.assetMigrationSpacing {
  margin-top: 30px;
  margin-bottom: 10px;
}

.usernameInput {
  width: 410px;
}

.checkBoxDivStyle {
  margin-top: 10px;
}