@import '../../styles/colors.css';

.ArrowPagination {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: center;
}

.arrow {
  --webkit-appearance: none;
  flex: 0 0 auto;
  border: 0 none transparent;
  border-radius: 4px;
  background: transparent none;
  cursor: pointer;
  padding: 10px;
  color: var(--gray-80);
}

.arrow:focus {
  background-color: var(--gray-10);
}

.arrowDisabled {
  cursor: default;
  color: var(--gray-40);
}
