.drilldownChartContainer {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

/* for safari compatibility */
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .drilldownChartContainer {
      height: 300px;
    }
  }
}

.chartContent {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  padding: 0 16px 0;
  height: 100%;
}

.emptyState {
  padding-bottom: 30px;
}
