.InfluencerNoteActions {
  position: absolute;
  top: 5px;
  right: 5px;
  visibility: visible;
}

.icon {
  border: none;
}

.dropdown {
  position: relative;
  top: 2px;
  right: 10px;
}

.modalButton {
  width: 100%;
  text-align: center;
}
