.keyMessages {
  padding: 10px;
}

.pillHolder {
  position: relative;
  border: 1px solid #cccfd2;
  border-radius: 2px;
  padding: 16px 8px;
  min-height: 212px;
}

.pill {
  margin: 0 8px 8px 0;
}

.pillIcon {
  vertical-align: -1px;
}

.limitReachedMessage {
  display: inline-block;
  padding-left: 18px;
  width: 200px;
  line-height: 1.8;
}

.infoIcon {
  vertical-align: 17px;
}

.pillSelector {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  z-index: 2;
}

.pillSelector input {
  display: inline-block;
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  min-width: 100%;
}

.placeholder {
  position: absolute;
  top: 23px;
  left: 17px;
  z-index: 1;
  width: 100%;
  line-height: 24px;
  color: #34404b;
  font-size: 14px;
}

.pillSelector input:focus,
.pillSelector input:active,
.pillSelector input:hover {
  outline: none;
  border: none;
  box-shadow: none;
}

.footerButton {
  transition: color 0s;
  margin-top: 16px;
}

.buttonDisable:disabled {
  transition: color 0s;
  margin-top: 5px;
  color: var(--gray-lite-4);
}

.footerIcon {
  vertical-align: -2px;
}

.keyMessagesListError {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
}
