.InfluencerSearchBar {
  display: flex;
  border-radius: 5px;
  box-shadow: 0 0 7px 3px rgb(0 0 0 / 12%);
  background-color: var(--white); 
  width: 100%;
  height: 45px;
}

.focus {
  box-shadow: 0 0 6px 1px var(--light-blue);
}

.inputArea  {
  flex: 1;
}

.textBox {
  display: flex;
  align-items: center;
  padding: 3px 0;
}

.searchIcon {
  margin-left: 16px;
}

.input {
  border: none;
  font-size: 16px;
}

.input:focus {
  border: none;
  box-shadow: none;
}

.searchArea {
  padding: 3px 16px;
  text-align: right;
}

.searchButton {
  height: 38px;
}

.ContextMenu {
  border-radius: 5px;
  box-shadow: -6px -1px 24px -6px rgba(0,0,0,0.38);
  background-color: white;
}

.menuItemLink {
  padding: 6px 15px;
}
