.bulkActionBarWrapper,
.filtersButtonWrapper,
.selectAllItemsWrapper {
  display: flex;
  align-items: center;
}

.filtersButtonWrapper {
  padding: 0 20px;
}

.addToListWrapper,
.sendEmailWrapper {
  padding: 0 10px;
}

.actionIcon {
  margin-right: 6px;
}

.boldText button {
  font-weight: var(--bold);
}

.checkbox {
  height: 16px;
}

.svgClass {
  display: inline-flex;
}
