.RecentlyViewedDropdown {
  margin-top: 5px;
  width: 85.5%;
}

.dropdown {
  background: var(--white);
  padding: 0;
  width: 100px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.closeDropdown {
  margin-right: 10px;
}

.recentlyViewedItem {
  padding: 0;
}

.recentlyViewedItem h3 {
  margin: 0;
}

.clearMenu {
  padding-bottom: 18px;
}

.recentlyViewedText, .noRecentSearches {
  color: var(--gray-90);
  font-size: 15px;
  font-weight: 700;
}

.recentlyViewedText {
  transition: all .2s ease-in-out;
  border-bottom: 1px solid var(--gray-90);
}

.recentlyViewedText:hover {
  border-bottom: 1px solid var(--delete-button-color);
  color: var(--delete-button-color);
}

.recentlyViewedItem h3, .clearMenu {
  padding: 20px 20px;
}

.activeItem {
  background-color: var(--gray-20);
}

.searchTermsContainer {
  max-height: 180px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.backgroundHighlight:hover {
  background-color: var(--gray-20);
}
