.Unsubscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  word-wrap: break-word;
}

.title {
  color: var(--black);
  font-size: 42px;
  font-weight: 700;
}

.button {
  width: 107px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 503px;
}

.divider {
  margin: 24px 0 24px 0;
  border-top: 1px solid #cccfd2;
  width: 100%;
}

.warningMessageContainer {
  display: flex;
  flex-direction: row;
  margin: 24px 0 32px 0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  padding: 17px;
  word-break:break-all;
}

.warningIcon {
  margin-right: 17px;
  color: var(--error);
}
