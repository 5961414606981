.mainContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  background-color: var(--rvr-white);
  padding: 0;
  width: 340px;
  color: var(--rvr-color-font);
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0 8px 8px;
  width: 213px;
}

.dividerRight {
  margin: 0;
  border: 0px;
  background: var(--gray-20);
  padding: 0;
  width: 1px;
  height: 100%;
}

.iconFirstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.iconLastContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 20px 0;
}

.title {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: 400;
}

.subTitle {
  line-height: 16px;
  color: var(--gray-80);
  font-size: 12px;
  font-weight: 400;
}

.errorColor {
  color: var(--error);
}

.detailsContainer {
  width: 430px;
}
