/* stylelint-disable indentation */
.listHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: 24px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.pagination {
  margin-top: 11px;
}

.lists {
  transition: all 150ms;
  border: solid 1px #cccfd2;
  min-height: 5rem;
  max-height: 10rem;
  overflow-y: auto;
}

.noList {
  margin-top: 10px;
  text-align: center;
}

.truncateListName {
  display: inline-flex;
  min-width: auto;
  max-width: calc(42%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}