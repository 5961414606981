.Outlets {
  position: relative;
}

.chosen {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}

.searchIcon {
  position: relative;
  top: 30px;
  margin-left: 20px;
  width: 20px;
  height: 20px;
}

.searchInput {
  display: flex;
  text-indent: 30px;
}

.clearInputCrossIcon {
  position: absolute;
  right: 2px;
  margin-top: 3px;
}
