.content {
  background: white;
  padding: 10px;
}

.tabGroupWrapper {
  padding: 16px;
}

.CreateNote.CreateNote {
  margin-bottom: 10px;
}

.createNoteAction {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.createNoteHeader {
  margin: 0 0 0 5px;
  padding: 4px 0 0;
  color: var(--gray-40);
  font-size: 18px;
}
