:root {
  --twitterBlue: #1da1f2;
  --neutralColorBase: #34404b;
}

.TwitterWidget {
  display: flex;
  align-items: center;
}

.reducedTwitterProfile {
  display: flex;
  align-items: center;
}

.head {
  margin-right: 8px;
}

.body {
  display: flex;
  flex-direction: column;
  color: var(--neutralColorBase);
}

.twitterIcon {
  margin-right: 5px;
}

.name {
  display: flex;
  align-items: center;
  font-weight: var(--bold);
}

.profileHandle {
  border: 0px;
  padding: 0px;
  color: var(--twitterBlue);
}

.profileHandle:hover {
  color: var(--twitterBlue);
}

.profileHandle:visited {
  color: var(--twitterBlue);
}

.verifiedIcon {
  margin-left: 5px;
}

.bigFont {
  margin-left: 5px;
  font-size: 16px;
  font-weight: var(--bold);
}
