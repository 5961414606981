.titleWrapper {
  display: flex;
  display: -ms-grid;
  position: relative;
}

.icon {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 18px;
}

.svgIcon {
  position: relative;
  top: -4px;
  left: -2px;
}

.row {
  justify-content: space-between;
  height: 100%;
}

.rowOwnerWrapper div:first-of-type {
  max-width: -webkit-fill-available;
}

.rowOwnerWrapper {
  display: flex;
  align-items: center;
  min-width: 0;
  white-space: nowrap;
} 

.rowOwnerWrapper.rowOwnerWrapper p, 
.ownerWithTooltip.ownerWithTooltip p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rowOwnerWrapper svg {
  position: static;
  flex-shrink: 0;
  margin-right: 8px;
}

.ownerWithTooltip {
  display: flex;
  align-items: center;
}

.rowTitleText {
  margin-left: 30px;
  word-break: break-word;
  color: var(--gray-90);
  font-weight: var(--bold);
}

.mobileInfo {
  padding-left: 30px;
}

.contextMenu {
  z-index: 800;
}

.influencersCount {
  padding: 6px 20px 0 0;
  width: 30px;
  word-break: normal;
}

.avatarWrapper {
  display: inline-block;
  margin-left: -8px;
}

.avatarBorder {
  border: solid white 2px;
}

.influencers {
  display: flex;
  align-items: center;
}

.smallInfluencersCount {
  padding-bottom: 12px;
}

.listName {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.activeSavedListRow {
  box-shadow: inset 0 0 0 1px var(--brandColor);
  background-color: var(--base-selected);
}

.activeSavedListRow:hover {
  background-color: var(--base-selected);
}

.checkbox {
  margin: -2px 10px 0 0;
  width: 14px;
  min-width: 14px;
  height: 14px;
}