.adminActionsSection {
  margin-top: 15px;
  overflow: hidden;
}

.adminTableSection {
  max-height: calc(100vh - 290px);
  overflow-y: auto;
}

.adminTableSearchBar {
  display: flex;
  float: right;
  justify-content: flex-end;
}

.newButton {
  float: right;
  margin-right: 20px;
}

.loaderPosition {
  position: absolute;
  top: 20%;
  left: 50%;
}

.paginationControls {
  margin-right: 16px;
}

.listControlsItem {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.listControlsBody,
.listControlsItem {
  display: grid;
  grid-template-columns: 100%;
}

.listControlsBody {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
}

.paginationControlsButtons:first-of-type,
.paginationControlsButtons:last-of-type {
  margin: 0px;
}
