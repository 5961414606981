.InfluencerList {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-flow: row nowrap;
  flex-wrap: nowrap;
  min-height: 0;
}

.tiny .wrapper,
.small .wrapper {
  display: block;
}

.grid {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

.contentWrapper {
  flex: 1 1 auto;
  min-width: 0;
}
