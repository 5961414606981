.listContainer {
  height: auto;
}

.list {
  background-color: transparent;
}

.searchByNameList {
  overflow-y: auto;
}

.entry.entry {
  background-color: transparent;
}

.loadMoreContainer {
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: center;
  z-index: 999;
  border-top: 1px solid var(--gray-20);
  min-height: 40px;
}

.loadMore.loadMore {
  display: flex;
  justify-content: center;
  width: 100%;
}
