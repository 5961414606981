.iframe {
  position: relative;
  background-color: transparent !important;
  width: 100% !important;
  height: 100% !important;
}

.centered {
  display: flex;
  position: absolute;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.behindIframe {
  z-index: 0;
}
