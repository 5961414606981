.EntitiesList {
  visibility: visible;
}

.separator {
  margin: 0;
  border: 0 solid var(--gray-lite-4);
  border-width: 1px 0 0;
}

.listLoading {
  margin: 8px;
}

.list {
  margin: 0;
  padding: 12px 0;
  list-style-type: none;
}

.listHead {
  display: block;
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
}

.itemButton.itemButton {
  display: block;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
}
