.container {
  transform: translate(-400px, 0);
  background-color: var(--white);
  width: 400px;
}

.containerExtended {
  transform: translate(0px, 0);
  transition: transform 0.3s ease-in;
}

.header {
  border-bottom: 1px solid var(--gray-20);
  color: var(--gray-90);
}

.closeIcon {
  line-height: 8px;
  color: var(--gray-80);
}

.closeIcon:hover {
  color: var(--gray-90);
}

.content {
  border-right: 1px solid var(--gray-20);
}

.searchesTitle {
  letter-spacing: 1px;
  color: var(--gray-90);
}

.searches {
  list-style-type: none;
}

.search {
  color: var(--gray-90);
}

.search:hover {
  background-color: var(--gray-10);
}

.selectedSearch {
  box-shadow: inset 5px 0 0 0 var(--light-teal);
  background-color: var(--gray-10);
  font-weight: bold;
}

.searchName {
  margin-left: 12px;
}

.barIcon {
  width: 24px;
  height: 24px;
  color: var(--gray-80);
}

.loading {
  margin-top: 148px;
}

.enter {
  transform: translateX(calc(var(--impact-report-sidebar-width) * -1));
}
.enterActive {
  transform: translateX(0);
  transition: transform 300ms ease-in;
}
.exit {
  transform: translateX(0);
}
.exitActive {
  transform: translateX(calc(var(--impact-report-sidebar-width) * -1));
  transition: transform 300ms ease-out;
}
