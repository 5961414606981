.mainContainerWidget {
  padding: 0px;
}

.topUrlButton {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.sectionTitleContainer {
  display: flex;
  flex-direction: column;
}
