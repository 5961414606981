@import '../../../styles/variables.module.css';

.actionIcon {
  margin-right: 5px;
}

.calloutInfoIcon {
  color: var(--color-warning);
}

.nav {
  margin-bottom: 20px;
}

.subjectHeader {
  display: flex;
  position: sticky;
  top: 64px;
  align-items: center;
  z-index: 2;
  /*
    cancelling out MessageCenter padding since it's a
    good default for most children and not worth making
    all children define their own padding to avoid this
  */
  margin: calc(0px - var(--message-center-body-spacing));
  margin-bottom: var(--message-center-body-spacing);
}

.threadBody {
  padding-top: 13px;
}

.pitchClass {
  padding: 20px !important;
}

.emailTabs {
  margin-left: 40px;
}

.emptyState {
  padding: 0 0 40px;
}

.stats {
  margin-bottom: var(--message-center-body-spacing);
}

.message {
  border-bottom: var(--divider-border);
}

.lastMessage {
  border-bottom-width: 0;
}

.loader {
  position: relative;
  z-index: 0;
  margin: 16px;
}

.tab {
  display: block; /* TabMenu uses these by default (soon) */
  padding-top: 12px; /* defining padding ourselves until RoverUI */
  text-decoration: none;
  color: var(--tab-text-color);
}

.itemPadding {
  display: block;
  padding: 16px;
}

.filtersContainer {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -10px; /* This will override the margin of the items at the first line but not those that are wrapped */
}

.ThreadBanner{
  margin-top: 4%;
  background: white;
  padding: 15px;
  width: auto;
  text-align: center;
  color: #34404b;
  font-size: 20px;
  font-weight: 600;

}
