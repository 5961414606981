:root {
  /* Base Colors */
  --white: #fff;
  --black: #000;
  --brandColor: #3398db;
  --blue: var(--brandColor);
  --blue-contrast: #007fa9;
  --blue-lite-1: #50a7e0;
  --blue-lite-2: #e8f8fc;
  --green: #63bf52;
  --green-lite-1: #69cc58;
  --green-lite-2: #e9fcf6;
  --green-lite-3: #2392c3;
  --teal: #44c0c2;
  --teal-lite-1: #47cacc;
  --teal-lite-2: #42bfb7;
  --salmon: #f76764;
  --salmon-lite-2: #feebea;
  --hotPink: #e62e5f;
  --yellow: #f8ca00;
  --yellow-lite-2: #fef8d3;
  --gray: #414c52;
  --gray-lite-1: #58666e;
  --gray-lite-2: #8a99a2;
  --gray-lite-3: #c3cbcf;
  --gray-lite-4: #dee5e7;
  --gray-lite-5: #edf1f2;
  --gray-lite-6: #f6f8f8;
  --gold: #fdd500;
  --base-selected: #fafeff;
  --nice-blue: #1175a8;
  --nice-blue-1: rgba(17, 117, 168, 0.07);
  --black-20: rgba(0, 0, 0, 0.2);
  --gray-dark-1: #69727a;
  --light-pink: #FFB6C1;

  /* Link */
  --color-link: var(--light-blue);
  --color-link-active: var(--light-blue);

  /* Semantic */
  --color-border: var(--gray-20);
  --delete-button-color: var(--color-danger);

  /* Neutral */
  --color-neutral: var(--gray-lite-1);
  --bgColor-neutral: var(--gray-lite-6);

  /* Danger */
  --color-danger: var(--salmon);
  --bgColor-danger: var(--salmon-lite-2);

  /* Warning */
  --color-warning: var(--yellow);
  --bgColor-warning: var(--yellow-lite-2);

  /* Success */
  --color-success: var(--green);
  --bgColor-success: var(--green-lite-2);

  /* Info */
  --color-info: var(--blue);
  --bgColor-info: var(--blue-lite-2);

  /* Notify */
  --color-notify: var(--hotPink);

  /* colors from zeplin styleguide, created by Egle */
  --gray-10: #f7f7f7;
  --gray-20: #ebeced;
  --gray-40: #cccfd2;
  --gray-60: #9aa0a5;
  --gray-80: #677078;
  --gray-90: #34404b;
  --teal: #00837e;
  --light-blue: #007fa9;
  --light-teal: #43bfb7;
  --orange-hover: #fc5b08;
  --error: #de4543;
  --error-hover: #fa6866;
  --charting-blue-dark: #0177b2;
  --charting-teal-light: #89ebe8;
  --warning: #f8ca00;
  --charting-yellow-base: #fde568;
  --charting-red-light: #ffa2a7;
  --charting-blue-light: #65c1ea;
  --light-blue-hover: #0ba5d6;
  --charting-magenta-base: #ff64c6;
  --charting-green-base: #00c68d;
  --charting-teal-base: #39dbd8;
  --charting-yellow-dark: #fdd500;
  --charting-blue-base: #0195de;
  --charting-green-light: #a1f0d7;
  --charting-red-base: #ff626b;
  --light-teal-hover: #49d1c8;
  --charting-purple-base: #8575cd;
  --success-light: #e9fcf6;
  --aqua-marine: #44c0c2;
  --charting-red-dark: #cb4e55;
  --white: #fff;
  --white-transparent: rgba(255, 255, 255, 0);
  --orange: #e35207;
  --error-light: #feebea;
  --charting-teal-dark: #2dafac;
  --notification: #fc356a;
  --warning-light: #fef8d3;
  --medium-blue: #00607f;
  --charting-green-dark: #009e71;
  --success: #63bf52;
  --light-sky-blue: #d2eef7;
  --charting-magenta-dark: #cb509e;
  --charting-purple-dark: #6b5da3;
  --charting-yellow-light: #fdf5d0;
  --charting-magenta-light: #ffa4de;
  --teal-hover: #00a19b;
  --info-light: #e3f6fc;
  --charting-purple-light: #b5ade1;
  --medium-blue-hover: #007499;
  --aqua-marine-two: #47cacc;
  --button-border: #cbcfd2;
  --light-grey-blue: #c9e8f2;
  --light-grey-white: #EBECED;
}
