.searchInput {
  width: 100%;
}

.resultsPane {
  z-index: 1;
  border: 1px solid gray;
  background-color: white;
  padding-left: 5px;
  width: 97%;
  height: 300px;
  overflow-y: scroll;
}

.suggestionButton {
  width: 100%;
  text-align: left;
}

.openUp {
  position: absolute;
  top: -302px;
}

.typeAheadSurround {
  position: relative;
}
