.userGroupsListEntryBody {
  overflow-y: scroll;
}

.userGroupsListEntryColumns {
  display: grid;
  grid-template-columns: 350px 50px auto;
  gap: 1.5rem;
}

.userGroupsNewBadge {
  background-color: #6b5da3;
}
