.unsubscribedContact {
  display: flex;
}

.unsubscribedContact .icon > svg {
  margin-left: 5px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  color: var(--gray-80);
}

.tooltip {
  width: 250px;
}
