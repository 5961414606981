.WidgetChartMetricsDetails {
  flex-grow: 1;
}
.MetricsDetailsHeader {
  margin: 30px;
  text-align: center;
}
.WidgetSentimentMetricsDetail {
  display: flex;
  justify-content: space-around;
  padding-top: 25px;
}
