:root {
  --grid-columns: 40px 600px repeat(39, 190px);
  --grid-columns-dupe: 55px 585px repeat(39, 190px);
  --grid-columns-headers: 4px 50px 600px;
  --new-grid-columns-headers: var(--drilldown-mention-cell-padding)
    var(--drilldown-date-column-width) var(--drilldown-outlet-column-width)
    var(--drilldown-outlet-and-author-divider-column-width)
    var(--drilldown-author-column-width);
  --grid-columns--dupe-headers: 4px 75px 575px;
}

.newMentionCellOutletPadding {
  padding-left: 85px;
}

.newMentionCellDivider {
  padding-top: 10px;
  padding-left: 10px;
  color: var(--gray-80);
}

.highlightColumnHeader {
  line-height: 16px;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
}

.newMentionCellImageDefault {
  padding-top: 11px;
  width: 96px;
}

.newMentionCell {
  width: 630px;
}

.articleListContainer {
  position: relative;
  z-index: 1;
}

.articleListTable {
  display: flex;
  position: relative;
  padding: 0;
}

.articleListTableEmptyState {
  justify-content: center;
}

.headerColumns {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 799;
  background-color: var(--white);
}

.metaDescription {
  flex-grow: 5;
  margin-left: 21px;
}

.listHeader {
  display: grid;
  grid-template-columns: var(--grid-columns);
  border-bottom: 1px solid var(--gray-20);
  height: 40px;
  text-transform: uppercase;
}

.listHeader.sticky {
  position: fixed;
  top: 250px;
  right: 0;
  left: 0;
  z-index: 798;
  background-color: var(--white);
}

.headerColumnListHeader.sticky {
  width: 654px;
}
.sidebarOpen .listHeader.sticky,
.sideBarOpen .headerColumnListHeader.sticky {
  left: var(--widget-drilldown-sidebar);
}

:global(.allMentionsDrilldownWrapper) .listHeader.sticky {
  left: var(--widget-drilldown-left-list-header-sticky);
}
:global(.allMentionsDrilldownWrapper) .sidebarOpen .listHeader.sticky {
  left: var(--widget-drilldown-left-list-header-sticky-sidebar-open);
}

.headerColumnListHeader.newMentionHeader.sticky {
  width: var(--drilldown-mention-cell-width) !important;
}

.headerColumns .listHeader {
  grid-template-columns: var(--grid-columns-headers);
}

.headerColumns .listHeader.newMentionHeader {
  grid-template-columns: var(--new-grid-columns-headers) !important;
}

.tableHolder {
  width: 100vw;
  overflow: auto;
  clip-path: inset(0);
}

:global(.searchBuilderModal) .tableHolder {
  width: 100%;
}

.sidebarOpen .tableHolder {
  width: calc(100vw - 20px - var(--widget-drilldown-sidebar));
}

:global(.allMentionsDrilldownWrapper) .sidebarOpen .tableHolder {
  width: 100vw;
}

:global(.searchBuilderModal) .sidebarOpen .tableHolder {
  width: calc(100% - 20px - var(--widget-drilldown-sidebar));
}

.hiddenColumn {
  visibility: hidden;
}

.listRow {
  display: grid;
  grid-template-columns: var(--grid-columns);
  border-bottom: 0.5px solid transparent;
}

.listRowDupe {
  grid-template-columns: var(--grid-columns-dupe);
}

.cell {
  display: flex;
  position: relative;
  border-bottom: 0.5px solid var(--gray-20);
  padding-left: 10px;
}

.hiddenCell {
  display: flex;
  position: relative;
  margin-left: -30px;
  border-bottom: 0.5px solid var(--gray-20);
}

.listRow:hover,
.listRow:hover .cell,
.listRowDupe:hover .cell {
  background: #f7f7f7;
}

.listRowHeader {
  display: grid;
  grid-template-columns: var(--grid-columns-headers);
  border-bottom: 0.5px solid transparent;
}

.cellContent {
  display: flex;
  align-items: center;
  margin-left: 3px;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  word-break: break-all;
}

.cellLongContent {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.gridColumnOne {
  grid-column: 1;
}

.gridColumnTwo {
  grid-column: 2;
}

.gridColumnThree {
  grid-column: 3;
}

.gridColumnFour {
  grid-column: 4;
}

.gridColumnFive {
  grid-column: 5;
}

.gridColumnSix {
  grid-column: 6;
  text-transform: capitalize;
}

.mentionWrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 584px;
}

.mentionWrapper.dupeMentionCell {
  width: 635px;
}

.mention {
  display: grid;
  grid-template-columns: 20px 100px 1fr;
  grid-column-gap: 15px;
  word-break: break-word;
}

.dupeMentionCell .mention {
  grid-template-columns: 20px 100px 1fr;
  margin-left: 85px;
}

.dupeMentionCell .newdrilldownmention {
  margin-left: 125px;
}

.mentionCheckbox {
  padding-left: 50px !important;
}

.dupeMentionCell {
  justify-content: flex-end;
}

.mentionOpenDupesButton {
  display: flex;
  grid-column-start: 1;
  align-items: center;
  width: 14px;
  height: auto;
}

.dupesIndicator {
  position: absolute;
  z-index: 3;
  margin-top: -10px;
  margin-left: -10px;
  background-color: gray;
  width: 6px;
  height: 100%;
}

.dupesButton {
  margin-top: 14px;
  color: var(--color-link);
}

.mentionImage {
  display: flex;
  grid-column-start: 2;
  align-items: center;
  width: 100px;
  height: auto;
}

.mentionImage img {
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
}

.mentionText {
  display: flex;
  flex-direction: column;
  grid-column-start: 3;
}

.title {
  margin-bottom: 10px;
  font-weight: var(--bold);
}

.checkbox {
  display: flex;
  margin-right: 20px;
  padding-left: 10px;
}

.dupeCheckbox {
  position: absolute;
  top: 45%;
  margin-left: 50px;
}

.newdrilldowndupeCheckbox {
  margin-left: 90px;
}

.tagsContainer {
  display: flex;
  align-items: center;
  overflow: visible;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
  text-align: left;
  row-gap: 10px;
}

.articleTitle {
  text-transform: uppercase;
  white-space: nowrap;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-style: solid;
  border-radius: 10px;
  background-color: var(--gray-lite-4);
  padding: 0 10px;
  height: 20px;
  line-height: 16px;
}

.addTag {
  cursor: pointer;
  font-weight: bold;
}

.addTag:hover {
  color: var(--light-blue-hover);
  font-weight: 900;
}

.campaignTag {
  display: flex;
  align-items: end;
  margin-right: 10px;
}

.newMentionsAddTag {
  border: none;
  background-color: var(--gray-20);
}

.campaignEarnedMediaButton {
  @apply t-truncate;
  margin-right: 10px;
  padding: 8px;
  font-weight: bold;
}

.campaignIcon {
  margin-top: 4px;
  width: var(--icon-size-large);
  height: var(--icon-size-large);
  color: var(--gray-80);
}

.campaignEarnedMediaButton:hover .campaignIcon {
  color: var(--white);
}

.actionsMenu {
  display: none;
  position: absolute;
  grid-row-start: 0;
  grid-row-end: 1;
  z-index: 1;
  border-bottom: none !important;
  pointer-events: none;
}

.actionsMenu > * {
  pointer-events: initial;
}

.listRow:hover .actionsMenu {
  display: inline-flex;
  right: 50px;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(90deg, #f7f7f700 5%, var(--gray-10) 10%);
  padding-left: 20px;
  width: 250px;
}

.listRow:hover .newActionsMenu {
  right: 0;
  align-items: flex-start;
  justify-content: flex-end;
  background: transparent;
}

.kebabActionButton {
  display: inline-flex;
  padding: 0 20px;
}

.newTabActionButton {
  display: inline-flex;
}

.deleteActionButton {
  display: inline-flex;
}

.tagActionButton {
  display: inline-flex;
}

.campaignTagActionButton {
  display: inline-flex;
}

.removeTagCrossCircle {
  position: initial;
  bottom: 10px;
  border-radius: 50%;
  background-color: var(--gray-lite-1);
  width: 14px;
  height: 14px;
  color: white;
}

.tagIcon {
  position: relative;
  transform: rotate(280deg);
}

.campaignTagIcon {
  position: relative;
  bottom: 9px;
}

.fixedColumn {
  position: absolute;
  z-index: 2;
  background-color: var(--white);
}

.rowDupeOpen .fixedColumn {
  background-color: var(--gray-10);
}

.listRow:hover .fixedColumn {
  background-color: var(--gray-10);
}

.rowDupeOpen {
  background-color: var(--gray-10);
}

.tagText {
  position: relative;
  margin: 0px 10px;
  white-space: nowrap;
  font-size: 12px;
}

.tagCrossIcon {
  position: relative;
}

.sentimentActionButton {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.mozIcon {
  color: #23abe1;
}

.titleIcon {
  display: grid;
  justify-content: center;
}

.cellWithImage {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.buttonTitle {
  padding: 0 10px 0 10px;
}

.buttonTitleNoSortable {
  cursor: default;
  padding: 0 10px;
}

.stickyPadding {
  width: 100%;
  height: 0;
}

.stickyPadding.sticky {
  height: 145px;
}

.articleTitleIcon {
  display: flex;
  height: inherit;
}

.socialAmp {
  display: grid;
  position: absolute;
  top: 25px;
  right: -73px;
  justify-content: center;
  z-index: 1;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: var(--white);
  width: 240px;
}

.itemSocialAmp {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #edf1f2;
  padding: 12px;
}

.socialAmpArrowTop::before {
  display: block;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px));
  border: 10px solid transparent;
  border-top: 0;
  border-bottom: 10px solid var(--white);
  width: 0px;
  content: '';
}

.listSocialAmp {
  width: 220px;
}

.textSocialHeader {
  font-size: 16px;
}

.textSocialFooter {
  padding: 12px;
  font-size: small;
}

.textSocial {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.authorClickable {
  cursor: pointer;
  color: var(--light-blue);
}

.syndicate {
  cursor: pointer;
  color: var(--light-blue);
  font-weight: bold;
}

.loaderWrapper {
  margin: 10px;
}

.discoveryMetaBar {
  height: 60px !important;
}

.mediaItemFilterBy {
  margin-right: 15px;
}

.mediaItemRightSection {
  margin-right: 15px;
}

.viewFilters {
  width: 300px;
}

.analyticsIntegration {
  width: 250px;
}

.metaBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}

.metaBar .leftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px 0 5px;
}

.metaBar.sticky {
  position: fixed;
  top: 188px;
  right: 0;
  left: 0;
  z-index: 800;
  background-color: var(--gray-10);
}

:global(.allMentionsDrilldownWrapper) .metaBar.sticky {
  left: var(--widget-drilldown-left-list-header-sticky) !important;
}

/* stylelint-disable */
:global(.allMentionsDrilldownWrapper)
.discoveryMetaBar.metaBar.sticky.sidebarOpen {
  left: var(--widget-drilldown-left-list-header-sticky-sidebar-open) !important;
}
/* stylelint-enable */

.metaBar.sticky.earnedSearches {
  top: 172px;
}

.metaBar.sticky.discoveryMetaBarForSocialMonitoring {
  top: 130px;
}

.metaBar.sticky.metaBarTags {
  top: 124px;
}

.sidebarOpen .metaBar.sticky {
  left: var(--widget-drilldown-sidebar);
}

.headerColumnListHeader.sticky.earnedSearches,
.listHeader.sticky.earnedSearches {
  top: 212px;
}

.headerColumnListHeader.sticky.earnedSearchesWithOutAllMentionsFF,
.listHeader.sticky.earnedSearchesWithOutAllMentionsFF {
  top: 168px;
}

.headerColumnListHeader.sticky.discoveryMetaBarForSocialMonitoring,
.listHeader.sticky.discoveryMetaBarForSocialMonitoring {
  top: 190px;
}

.headerColumnListHeader.sticky.listHeaderTags,
.listHeader.sticky.listHeaderTags {
  top: 164px;
}

.headerColumnListHeader.sticky.listHeaderWidgetDrilldown,
.listHeader.sticky.listHeaderWidgetDrilldown {
  top: 228px;
}

.truncateMetaBarLabel {
  margin-right: 20px;
  max-width: 1050px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (min-width: 1000px) {
  .metaBar.sticky.earnedSearches {
    top: 125px;
  }

  /* stylelint-disable */
  :global(.newDrilldownAbstraction)
  .headerColumnListHeader.sticky.earnedSearches,
  :global(.newDrilldownAbstraction) .listHeader.sticky.earnedSearches {
    top: 165px;
  }
}
/* stylelint-enable */

@media only screen and (max-width: 1000px) {
  .metaBar.sticky.earnedSearches {
    top: 125px;
  }

  .metaBar.earnedSearches,
  .metaBar.discoveryMetaBar,
  .metaBar.widgetDrilldown {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 5px;
    padding-left: 15px;
    height: auto;
  }

  .metaBar.sticky.earnedSearches,
  .metaBar.sticky.discoveryMetaBar,
  .metaBar.sticky.widgetDrilldown {
    height: 85px;
  }

  .metaDescription.earnedSearches,
  .metaDescription.discoveryMetaBar,
  .metaDescription.widgetDrilldown {
    flex-grow: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .headerColumnListHeader.sticky.listHeaderWidgetDrilldown,
  .listHeader.sticky.listHeaderWidgetDrilldown {
    top: 275px;
  }

  .headerColumnListHeader.sticky.earnedSearchesWithOutAllMentionsFF,
  .listHeader.sticky.earnedSearchesWithOutAllMentionsFF {
    top: 212px;
  }

  .truncateMetaBarLabel {
    margin-right: 0;
    margin-left: 5px;
    max-width: 700px;
  }
}

.secondaryScroller {
  display: none;
  position: absolute;
  z-index: 699;
  width: 100vw;
  overflow: scroll;
}

.secondaryScroller .rowWrapper {
  visibility: hidden;
  height: 0;
}

.contextMenu{
  z-index: 9999999999999;
  margin-left: 45px;
}

