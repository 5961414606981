.backdrop {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 899;
  border: none;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: auto;
  user-select: none;
}

.backdropEnter {
  opacity: 0;
}

.leftContentClassModifier {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backdropEnterActive {
  transition: opacity 150ms;
  opacity: 1;
}

.backdropEnterDone {
  opacity: 1;
}

.backdropExit {
  opacity: 1;
}

.backdropExitActive {
  transition: opacity 150ms;
  opacity: 0;
}

.backdropExitDone {
  opacity: 0;
}

.dashboardTitleInput {
  margin: 0;
  border: none;
  cursor: pointer;
  width: 760px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.dashboardTitleInput:hover {
  border: 2px solid #3398db;
}

.dashboardTitleInput:focus {
  border: 2px solid #3398db;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.truncable {
  padding-top: 3px;
  width: 100%;
}

.shareButton,
.actionsMenuButton,
.generateReportButton,
.expandableDropdownContainer {
  margin-right: 10px;
  width: auto;
}

.actionsMenuButton button {
  padding: 0 7px;
}
