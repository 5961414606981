.ListOfListsWrapper {
  display: flex;
  flex-direction: column;
  height: 89%;
}

.innerHeader {
  position: sticky;
  top: 76px;
  z-index: 999;
  background-color: #FFFFFF;
}

.innerBody {
  flex-grow: 1;
  overflow-y: auto;
}

.searchInput {
  border-bottom: 1px var(--gray-20) solid;
  padding: 16px;
}

.loadMore.loadMore {
  display: block;
  width: 100%;
}
