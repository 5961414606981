.SocialSearchSidebar {
  flex: 0 0 auto;
  border-right: 1px solid #ebeced;
  background-color: #ffffff;
  width: 350px;
  height: 86vh;
  overflow-y: auto;
}

.SocialSearchSidebarIsLoading {
  display: flex;
  justify-content: center;
}

.listItemRightContent {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.listItemRightContent svg {
  transform: none;
  margin-right: 17px;
  width: 16px;
  min-width: inherit;
  height: 16px;
  color: var(--gray-80);
}

.tooltipClassName {
  width: 100%;
}

.hidden {
  display: none;
}

.searchInput {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 1vh;
  padding-left: 2vh;
}

.circleButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchInputWrapper {
  margin-bottom: 1vh;
  border-bottom: 1px solid #ebeced;
}
