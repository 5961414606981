.noAccessErrorText {
  max-width: 396px;
  text-align: center;
  line-height: 1.71;
  color: var(--gray-80);
  font-size: 14px;
}

.noAccessContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
