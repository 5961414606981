.gridSpacingMedium {
  grid-template-columns: 32px minmax(60%, 1fr) 40px;
}

.gridSpacingLarge {
  grid-template-columns: 32px minmax(60%, 1fr) 40px;
}

.errorClass{
  border: 2px solid #DE4543;
  filter: drop-shadow(0px 0px 8px #DE4543);
}