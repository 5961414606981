.formContent {
  padding-right: 16px;
  padding-left: 16px;
}
.control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 25px;
  border-top: 1px solid var(--gray-lite-3);
  padding: 16px;
}
.control > button {
  margin-right: 8px;
}
.radioButton {
  display: inline;
}
.required:after {
  color: red;
  content: '*';
}
.form__email {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.form__email button {
  margin-bottom: 12px;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
}
.inputMsg {
  margin-left: 5px;
}
.addNewRecipient {
  padding: 0;
}
.statusMessage {
  font-weight: 100;
}

.bold {
  font-weight: bold;
}
