.EmailCampaign {
  height: 100%;
}

.savedIndicator {
  margin-left: 8px;
  vertical-align: middle;
  font-weight: 400;
}

.fileName {
  font-weight: 700;
}
