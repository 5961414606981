.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 66px);
  overflow: hidden;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: row;
}

.titleText {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.sideBarDivider {
  left: 16px;
  border: 0px;
  background: #c4c4c4;
  width: 90%;
  height: 1px;
}
