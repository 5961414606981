.WidgetChart {
  position: relative;
  flex-grow: 1;
  padding: 0;
  width: inherit !important;
  min-width: 310px;
}

.WidgetMapDrilldownChart {
  flex-grow: 3;
  min-width: 700px;
  max-width: 1100px;
}

.overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.widgetUpdateWrapper {
  display: flex;
  margin: 0 10px;
  height: 44px;
}

.analyticDropdownWrapper {
  display: flex;
  flex: 0 1 auto;
  max-width: 300px;
}

.granularityTypeWrapper {
  display: flex;
  flex: 0 0 auto;
  align-self: flex-end;
  justify-content: center;
  height: 100%;
}

.tooltipContent {
  color: var(--white);
  font-weight: bold;
}
