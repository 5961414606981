.personaCardTraits {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  transition: max-height 0.1s ease-out;
  border-top: 1px solid var(--gray-20);
  padding: 35px 0px 0 24px;
  height: auto;
  min-height: 100px;
  max-height: 216px;
  overflow: hidden;
}
.personaCardTraitsOpen {
  transition: max-height 300ms ease-in;
  max-height: 1200px;
}
.traitContainer {
  flex: 0 0 50%;
  min-height: 100px;
}
.traitCategoriesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 185px;
  max-width: 185px;
}
.traitGroupName {
  line-height: 16px;
  color: var(--gray-80);
  font-size: 12px;
}
.seeMoreButton {
  position: absolute;
  right: 30px;
  bottom: 0;
  cursor: pointer;
  min-width: 75px;
  max-width: 100%;
  height: 35px;
  text-align: end;
  color: var(--light-blue);
}

@media only screen and (min-width: 768px) {
  .traitContainer {
    flex: 0 0 30%;
  }
  .personaCardTraits {
    padding: 35px 25px 0 120px;
    max-height: 235px;
  }
  .personaCardTraitsOpen {
    transition: max-height 300ms ease-in;
    max-height: 900px;
  }
}
@media only screen and (min-width: 1024px) {
  .traitContainer {
    flex: 0 0 25%;
  }
}
@media only screen and (min-width: 1920px) {
  .traitContainer {
    flex: 0 0 15%;
  }
}
