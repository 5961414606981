@import '../../../../styles/colors.css';

.metadataBarContainer {
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-20);
  padding: 8px 16px;
  min-height: 40px;
}

.metadataContent {
  align-items: center;
}

.separator {
  background-color: var(--gray-90);
  width: 1px;
  min-height: 12px;
}

.searchUsageTooltip {
  display: flex;
}

.searchUsageTooltipContent {
  align-items: flex-start;
  white-space: pre;
}

.linkTrackerButton {
  padding-right: 0px;
  padding-left: 0px;
}

.tooltip {
  display: flex;
}

.leftGroupMediaItem {
  margin-right: 8px;
}

.rightGroupMediaItem {
  margin-right: 16px;
}

.shareStatusIcon {
  margin-right: 5px;
}
