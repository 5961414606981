.InfluencerNoteEditor {
  visibility: visible;
}

.editor {
  padding: 15px;
}

.editorWrapper {
  margin-bottom: 10px;
  box-shadow: 0 0 0 2px var(--gray-80);
}

.editorWrapper.focused {
  box-shadow: 0 0 0 2px var(--brandColor);
}

.checkboxArea.checkboxArea {
  align-content: center;
  align-items: center;
}

.checkbox {
  margin-right: 10px;
}
