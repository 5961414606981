:global(.earned-impact-page) {
  min-width: 480px;
}
.personaContainer {
  margin: 0 8px auto;
}
.personaContainer :global(.tk-empty-state) {
  padding-top: 60px;
}
.personaContainer :global(.tk-empty-state__heading) {
  width: 382px;
  color: var(--gray-90);
  font-size: 16px;
}
.personaContainer :global(.tk-empty-state__heading) .details {
  margin-bottom: 0px;
  font-weight: normal;
}
.personaContainer :global(.tk-empty-state__svg) {
  width: 180px;
}
.titleContainer {
  display: none;
}
.sortByContainer {
  display: none;
  margin-right: 25px;
}
.sortByContainer,
.sortByContainerResponsive {
  color: var(--gray-80);
  font-size: 14px;
  font-weight: 400;
}
.sortByContainerResponsive {
  margin-left: 15px;
}
.title {
  margin: 0px;
  margin-top: 20px;
  line-height: 1;
  font-size: 16px;
}
.secondaryTitle {
  margin-top: 5px;
  margin-bottom: 20px;
  color: var(--gray-80);
  font-size: 12px;
  font-weight: 400;
}
.titleNavContainer {
  display: flex;
  flex-direction: column;
}

.sortByContainerResponsive > svg,
.sortByContainer > svg {
  margin-right: 8px;
  vertical-align: middle;
}
.cardsContainer {
  margin-top: 20px;
}

.personaContainer ul {
  padding-left: 0px;
}
.notificationContainer {
  padding: 0;
  height: 56px;
}
.notificationContent {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
}
.notificationText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  line-height: 1.71;
  color: var(--gray-90);
  font-size: 14px;
}
.notificationTextClickable {
  margin-left: 4px;
  padding: 0;
  height: inherit;
  line-height: 1.71;
  color: var(--light-blue);
  font-size: 14px;
}
.notificationClose {
  padding: 0;
  width: 24px;
  height: 24px;
}
.notificationContentLeft {
  display: flex;
  align-items: center;
}

.titleLinkcontainer {
  display: flex;
}

.titleDatepicker {
  margin-right: 20px;
}

@media only screen and (min-width: 768px) {
  .titleContainerResponsive {
    display: none;
  }
  .sortByContainerResponsive {
    display: none;
  }
  .titleContainer {
    display: unset;
  }
  .sortByContainer {
    display: unset;
  }
}
@media only screen and (min-width: 1440px) {
  .personaContainer {
    margin-right: 165px;
    margin-left: 165px;
  }
}
@media only screen and (min-width: 2260px) {
  .personaContainer {
    margin-right: 485px;
    margin-left: 485px;
  }
}
@media only screen and (min-width: 3440px) {
  .personaContainer {
    margin-right: 925px;
    margin-left: 925px;
  }
}
