.DiscoveryTopJournalists {
  /* Asymmetrical padding because of the wrapping container. */
  padding: 0 20px 0 10px;
}

.resultsSummary {
  margin: 20px;
}

.resultsSummaryBody.resultsSummaryBody {
  display: block;
}

.datePicker {
  display: inline-block;
  margin-left: 10px;
}

.influencersLoading {
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.influencersLoading > :first-child {
  border-radius: 2px 2px 0 0;
}

.influencersLoading > :last-child {
  border-radius: 0 0 2px 2px;
}

.influencers {
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.influencer {
  border-bottom: 1px solid var(--gray-20);
}

.influencer.first {
  border-radius: 2px 2px 0 0;
}

.influencer.last {
  border-bottom-width: 0;
  border-radius: 0 0 2px 2px;
}
