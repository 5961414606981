.container {
  font-size: 14px;
}

.label {
  color: #34404b;
  font-weight: bold;
}

.count {
  margin-left: 16px;
  color: #677078;
  font-weight: normal;
}
