.InfluencerOverview {
  padding: 15px;
}

.InfluencerOverview .activities {
  margin: 0 -16px;
  border-top: 1px solid var(--gray-20);
}

.OverviewWrapper > :global(.rvr-gridColumns__uYl)  > :global(.rvr-Entry__2Oe):first-child {
  flex-basis: 100% !important;
  max-width: 100% !important;
} 