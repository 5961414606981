.dashboardFilterBar {
  border-bottom: 1px solid var(--gray-20);
}

.filterPill {
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border: none;
  border-radius: 50px;
  background-color: var(--white);
  padding: 0 10px;
  height: 35px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}

.filterPill:hover {
  background-color: var(--gray-lite-5);
  cursor: pointer;
}

.filterPill span {
  margin-left: 5px;
  padding-top: 5px;
}

.sticky {
  position: fixed;
  top: 470px;
  right: 0;
  left: var(--analytics-list-sidebar);
  z-index: 800;
}
