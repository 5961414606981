@import '../../../../styles/colors.css';

.labelColor {
  color: var(--gray-90);
}

.formGroup {
  margin-bottom: 0px;
}

.modalBody {
  padding: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}

.inputInfluencer {
  width: 100%;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.marginIcon {
  margin-right: 5px;
}
