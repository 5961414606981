.nav {
  display: flex;
  justify-content: center;
}

.headings {
  display: flex;
  flex-direction: column;
  color: var(--gray-90);
  font-size: 16px;
}

.searchName {
  color: var(--gray-80);
  font-size: 12px;
  font-weight: 400;
}

.container {
  display: flex;
  height: calc(100vh - var(--impact-bars-height));
}

.sidebar {
  display: none;
  width: var(--impact-single-url-sidebar-width);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.content .performanceSnapshotBody {
  justify-content: center;
  height: auto;
  min-height: 121px;
}

.content .performanceSnapshotContainer {
  margin: 0 16px;
  height: auto;
}

.listViewIcon {
  display: block;
}

.enter {
  transform: translateX(calc(var(--impact-single-url-sidebar-width) * -1));
}

.enterActive {
  transform: translateX(0);
  transition: transform 300ms ease-in;
}

.exit {
  transform: translateX(0);
}

.exitActive {
  transform: translateX(calc(var(--impact-single-url-sidebar-width) * -1));
  transition: transform 100ms ease-out;
}

.modalContent {
  max-width: 462px;
  height: 179px;
}

@media screen and (min-width: 1024px) {
  .sidebar {
    display: flex;
    max-height: calc(100vh - var(--impact-bars-height));
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .listViewIcon {
    display: none;
  }
}
