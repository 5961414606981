.roleType{
  font-weight: bold;
}

.cellClick {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--gray-80);
}

.linkClick {
  padding: 0;
  text-decoration: none;
  color: var(--light-blue);
  font-weight: 600;
}

.linkClick:hover {
  color: var(--light-blue-hover);
}
