.mobileAddDashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-lite-3);
  color: var(--white);
}

.mobileAddDashboard:hover {
  cursor: pointer;
  color: var(--white);
}
