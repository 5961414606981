.notificationContainer {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  padding: 16px 21px 24px 18px;
  width: 540px;
  height: 248px;
}

.bannerNotificationContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bannerNotificationTitleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bannerNotificationTitleAndIcon {
  display: flex;
  align-items: center;
}

.notificationText {
  margin-left: 10px;
  line-height: 24px;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}

.notificationClose {
  padding: 0;
}

.bannerNotificationBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  padding-right: calc(48px - 41px); /* 41px is parent padding right */
  padding-left: calc(48px - 18px); /* 18px is parent padding left */
  height: 100%;
  color: var(--gray-90);
}

.bannerNotificationBodySubText {
  margin-top: 25px;
}
