.advicesContainer {
  display: grid;
  grid-template-rows: 24px;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 25px 0px 14px 0px;
}

.searchBuilder {
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  min-height: 392px;
}
