.sidebarContainer {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--gray-20);
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-y: auto;
}

.personaList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.loader {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
