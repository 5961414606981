.tab {
  display: flex;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
  height: 100%;
}

.tab:first-of-type {
  margin-left: 0;
}

.tabsHeader {
  display: flex;
  border-top: 1px solid #dee5e7;
  border-bottom: 1px solid #dee5e7;
  height: 48px;
}

.activeTab {
  box-sizing: border-box;
  border-bottom: 2px solid #0092c2;
}

.disabledTab {
  cursor: not-allowed;
}
