.dashboardSelect {
  flex-basis: 500px;
  margin-right: 15px;
}

.widgetSelect {
  flex-basis: 300px;
  margin-right: 15px;
}

.searchButton {
  padding-right: 40px;
  padding-left: 40px;
}

.tableContainer {
  height: 700px;
  overflow-y: scroll;
}

.widgetContainer {
  margin-top: 20px;
}

.row {
  cursor: pointer;
}

.accountInput {
  margin: 20px 20px 20px 0;
  width: 30%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.feedsTableCounts {
  margin-bottom: 20px;
}

.feedsTableNoResults {
  margin-top: 50px;
  text-align: center;
}
