.ReportOfflineContainer {
  padding-top: 10%;
  width: 100%;
  height: 100%;
}

.reportOffline {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
}

.reportText {
  display: grid;
  height: 70px;
}

.reportFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
}

.reportPoweredBy {
  padding-right: 15px;
}
