.adminFormPage {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: var(--form-max-width);
}

.removeMargin {
  margin: 0;
}

.loaderPosition {
  position: absolute;
  top: 20%;
  left: 50%;
}

.linkClick {
  padding: 0;
  text-decoration: none;
  color: var(--light-blue);
  font-weight: 600;
}

.linkClick:hover {
  color: var(--light-blue-hover);
}

.adminToggle {
  display: flex;
  margin-bottom: 25px;
  font-weight: bold;
}

.adminTooltip {
  text-align: justify;
  color: var(--warning);
  font-weight: bold;
}

.adminHeader {
  padding: 10px 0 25px;
  text-align: center;
}

.adminButtonHolder {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}

.buttonMargin {
  margin-right: 10px;
}

.adminWrapper {
  padding-top: 25px;
}

.adminCheckbox {
  padding-bottom: 10px;
}
