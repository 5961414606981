.earnedSearchesList {
  display: contents;
  height: 100%;
}

.earnedSearchesRowsListDeleteBannerTallest {
  margin-top: 9em;
}

.earnedSearchesRowsListTallest {
  margin-top: 5em;
}

.earnedSearchesRowsListDeleteBannerTall {
  margin-top: 7.5em;
}

.earnedSearchesRowsListTall {
  margin-top: 3.5em;
}

.earnedSearchesRowsListDeleteBanner {
  margin-top: 2em;
}

.earnedSearchesRowsList {
  margin-top: 2em;
}

.earnedSearchesList > div {
  width: 100%;
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 280px;
}
