.ListSummary {
  cursor: pointer;
  padding: 8px;
  padding-left: 16px;
}

.disabled {
  cursor: default;
  height: 46px;
  color: var(--gray-lite-3);
}

.prefix {
  align-self: flex-start;
}

.notSelectable {
  margin-left: -3px;
}

.notSelectableInfluencer {
  display: flex;
  margin-left: 20px;
  height: 46px;
}

.emptyListWarning {
  position: relative;
  top: -7px;
  color: var(--gray-60);
  font-size: 0.8rem;
  font-style: italic;
}

.body {
  align-self: center;
  margin: 0 8px;
}

.name {
  margin-bottom: 4px;
  color: var(--gray-90);
}

.disabled .name {
  color: var(--gray-lite-3);
}

.description {
  font-size: 12px;
}

.suffix {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.selectListCheckbox {
  position: relative;
  align-self: start;
  margin-top: 5px;
  margin-right: 3px;
}
