.personaCard {
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease;
  margin-bottom: 16px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  padding: 24px 0 0;
  color: var(--gray-90);
}
.personaCard:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.personaCardInfo {
  display: flex;
  padding: 0px 16px 40px 32px;
}
.personaAvatar {
  margin-right: 24px;
  width: 64px;
  height: 64px;
}
.personaName {
  flex-grow: 1;
}
.personaActions {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
  margin-top: -16px;
  width: 88px;
  color: var(--gray-80);
}
.buttonIcon {
  padding-right: 0;
  padding-left: 0;
}
.buttonIcon:hover {
  background-color: var(--gray-20);
  color: var(--gray-90);
}
.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: transparent;
  width: 40px;
  height: 40px;
}
