.body {
  line-height: 24px;
  color: var(--gray-90);
}

.radioButton {
  margin-top: 3px;
}

.optionText {
  margin-left: 8px;
  color: var(--gray-90);
}

.separator {
  margin-bottom: 16px;
  border-top: 1px solid var(--gray-20);
}
