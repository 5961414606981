.Note.Note {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
  padding: 20px;
}

.Note.pinned {
  border: 1px solid var(--light-teal-hover);
}

.Note > * {
  margin-bottom: 10px;
}

.Note:last-child {
  margin-bottom: 0;
}

.date {
  display: flex;
  align-items: center;
  color: var(--gray-80);
  font-size: 12px;
}

.dateElement {
  display: inline-block;
  margin-right: 10px;
}

.noteContent {
  margin: 0;
}
