.allMentionsDrilldownContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 10px;
  height: 100%;
}

.drilldownWrapper {
  position: relative;
}
