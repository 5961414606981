.tagsListContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #ffffff;
}

.tagsListContent {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.tagsListSideTray {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid var(--gray-20);
  min-width: 20em;
  margin-top: 60px;
}

.tagsListResults {
  width: 100%;
  margin-top: 60px;
}
