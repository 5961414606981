.searchNameBox {
  padding: 0 20px !important;
  width: 160px;
  height: 34px;
}

.wrapper {
  display: flex;
  max-width: 170px;
}

.editUiContent {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleBox {
  width: 170px;
  max-width: 170px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
