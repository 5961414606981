.formWrapper {
  display: flex;
  flex-direction: column;
}

.fullNameInput {
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 10px;
}

.firstName {
  padding-right: 10px;
}

.firstNameInput,
.lastNameInput {
  padding: 8px !important;
}

.prefixSuffixInput {
  display: flex;
  flex-basis: 10%;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 10px;
}

.flexArea {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flexInput {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.flexSplit50 {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  padding: 10px;
}

.twitter2Input {
  margin-top: 27px;
}

.information {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.formButtons {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.addButton {
  margin-left: 10px;
}

.required::after {
  display: inline;
}

.header {
  margin-bottom: 10px;
  background-color: var(--white);
}

.headerInner {
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.body {
  background-color: var(--white);
}

.button {
  padding: 0 10px;
}

.title {
  padding: 10px;
}

.buttonWrapper {
  margin: 0 3px;
  text-align: right;
}

.contentWrapper {
  padding: 20px;
}

.contactInfo {
  flex-direction: column;
}

.contactTopicsInputDiv {
  margin-top: -18px;
}

.callout.callout {
  margin-bottom: 10px;
  padding: 15px;
  font-weight: bold;
}

.callout:last-of-type {
  margin-bottom: 0;
}

.matches {
  position: relative;
  align-items: center;
  cursor: pointer;
}

.matchesPrompt {
  display: inline-block;
  max-width: 90%;
}

.matchesArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  padding: 3px 0 0;
  text-align: right;
}

.prefixSuffix {
  padding: 8px !important;
}

.nameSectionRequired {
  color: var(--error);
}
