@import '../../../../styles/colors.css';

.listHeader {
  display: flex;
  align-items: center;
}

.listHeaderText {
  flex-grow: 2;
}

.listContent {
  margin: 20px 0;
  border-top: 2px solid var(--color-border);
}

.addToEmailButton {
  margin-left: 10px;
  width: 150px;
}

.saveChangeButton {
  margin-left: 10px;
  width: 121px;
}
