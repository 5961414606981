.WidgetHeader {
  display: grid;
  grid-template-columns: 32px minmax(60%, 1fr) 40px;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  padding: 0 20px;
  height: 55px;
}

.WidgetHeaderWithRefreshButton {
  grid-template-columns: 32px minmax(60%, 1fr) 85px !important;
}

.widgetHeaderNewLabel,
.gridSpacingMedium .widgetHeaderNewLabel,
.gridSpacingLarge .widgetHeaderNewLabel {
  grid-template-columns: 32px minmax(60%, 1fr) 25px 10px;
}

.WidgetHeaderTitle {
  width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--gray-80);
  font-size: large;
  font-weight: bold;
}

.WidgetHeaderTitleText {
  width: 100%;
}

.WidgetHeaderSubTitleName {
  display: block;
  font-size: small;
  font-weight: 400;
}


.widgetActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.icon {
  flex-grow: 0;
  width: 20px;
  height: 20px;
}

.menu {
  flex-grow: 0;
}
.newWidgetLabel {
  display: inline;
  margin-left: 30px;
  border-radius: 4px;
  background-color: var(--charting-purple-dark);
  padding: 2px 5px 2px 5px;
  text-align: center;
  text-transform: uppercase;
  line-height: 16px;
  color: var(--white);
  font-size: 12px;
}
