.action {
  transition: background-color 0.2s ease-in-out;
  border-radius: 20px;
  padding: 5px;
  color: var(--gray-80);
}

.action:hover {
  background-color: var(--gray-20);
}
