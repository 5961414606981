.ListMemberBadge {
  display: flex;
  margin-left: 5px;
  background-color: #328653;
  padding: 1.5px 10px;
  width: 97%;
  color: #ffffff;
}

.TinyBadge {
  top: 50px;
  right: 20px;
}

.ListVerifiedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListMemberMessage {
  margin-left: 2px;
  font-weight: bold;
}
