@import '../../../../styles/colors.css';
@import '../../../../styles/typography.css';
@import '../../../../styles/sizes.css';

.AnalyticsListSidebar {
  position: relative;
  flex: 1 0 auto;
  border-right: 1px solid var(--gray-20);
  background-color: var(--white);
  width: var(--analytics-list-sidebar);
  min-width: 0;
  max-width: var(--analytics-list-sidebar);
  height: 86vh;
  overflow-y: auto;
}

.mobile {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.paper {
  width: 100%;
}

.media {
  align-items: center;
  padding: 5px 10px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.analyticsSidebarLink {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  text-decoration: none;
  color: var(--gray-80);
  font-weight: 500;
}

.activeSidebarLink {
  box-shadow: inset 4px 0 0 0 var(--light-teal);
  background-color: var(--gray-10);
  color: var(--gray-90);
  font-weight: var(--bold);
}
