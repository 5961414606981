.outreachCardHeader {
  display: flex;
  align-items: center;
}

.outreachCardName {
  flex-grow: 2;
}

.outreachCardList {
  margin: 20px 0;
  border-top: 2px solid #ebeced;
  padding-top: 20px;
}


.icon > svg {
  margin-top: 3px;
  margin-left:-19px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  color: var(--gray-80);
}