.headerColumns {
  display: grid;
  grid-template-columns:
    minmax(100px, 2fr) minmax(120px, 1fr) minmax(75px, 1fr) minmax(75px, 1fr)
    minmax(75px, 1fr);
  border-top: 1px solid var(--gray-20);
  border-bottom: 1px solid var(--gray-20);
  height: 40px;
}

.userRow {
  display: grid;
  position: relative;
  grid-template-columns:
    minmax(100px, 2fr) minmax(120px, 1fr) minmax(75px, 1fr) minmax(75px, 1fr)
    minmax(75px, 1fr);
  border-bottom: 1px solid var(--gray-20);
  height: 60px;
}

.inactiveUserRow > span {
  text-decoration: line-through;
}

.gridCell {
  display: flex;
  align-items: center;
}

.gridColumnOne {
  grid-column: 1;
}

.gridColumnTwo {
  grid-column: 2;
}

.gridColumnThree {
  grid-column: 3;
}

.gridColumnFour {
  grid-column: 4;
}

.gridColumnFive {
  grid-column: 5;
}

.actionsMenu {
  display: inline-flex;
  position: absolute;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  visibility: hidden;
  border-bottom: none !important;
  background-image: linear-gradient(90deg, #f7f7f700 0%, var(--gray-10) 10%);
  padding-left: 20px;
  width: 70px;
  height: 100%;
  pointer-events: none;
}

.actionButton {
  display: flex;
  padding: 0 10px;
}

.actionsMenu > * {
  pointer-events: initial;
}

.userRow:hover .actionsMenu {
  visibility: visible;
}

.userRow:hover {
  background-color: var(--gray-10);
}
