.chartWrapper {
  border-bottom: 1px solid var(--gray-20);
  cursor: pointer;
  padding: 10px 0;
  text-align: left;
}

.chartWrapper:hover {
  background: var(--gray-10);
}

.disabledChartWrapper {
  opacity: 0.3;
  border-bottom: 1px solid var(--gray-20);
  padding: 10px 0;
  text-align: left;
}

.chartIcon {
  display: inline-block;
  margin: 0 15px;
  vertical-align: top;
}

.chartText {
  display: inline-block;
  width: 245px;
}

.chartSelect {
  display: inline-block;
  margin: 15px 0 0 15px;
  vertical-align: top;
}

.footer {
  flex-shrink: 0;
  justify-self: flex-end;
  margin-top: auto;
  -ms-flex-negative: 0;
}

.footerButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.dataSectionTitleWrapper {
  padding: 10px;
}

.dataSectionRemovePill {
  border: none;
  border-radius: 10px;
  box-shadow: none;
  background-color: #9aa0a4;
  padding: 1px;
  vertical-align: bottom;
  color: #ffffff;
}

.sectionWrapper {
  margin-top: 20px;
  border-top: 1px solid var(--gray-20);
  padding: 10px;
}

.sectionTitles {
  font-size: 12px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
}

.sectionHeaderTitles {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.sectionHeaderIconButton {
  padding: 10px;
}

.itemContainer {
  display: flex;
}

.disabledItemContainer {
  display: flex;
  opacity: 0.3;
}

.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}

.widgetTitleInput {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.title {
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncable {
  width: 100%;
}

.dropdownWrapper {
  margin: 20px 0;
}

.dateCompareWrapper {
  display: flex;
  justify-content: space-between;
}

.deleteDateRangeWrapper {
  justify-content: flex-end;
}

.metricsContainer {
  margin: 10px 0;
}

.pillCross,
.pillCrossDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 50%;
  background-color: var(--light-teal-hover);
  width: 25px;
  height: 25px;
  color: var(--white);
}

.pillCrossDisabled {
  background-color: var(--gray-40);
}

.subtitleMetric {
  padding: 16px;
}

.chartSectionWizard {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 10px;
  padding-left: 10px;
}

.chartTypeList {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-content: center;
  align-items: baseline;
  padding: 5px;
}

.selectChartList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
}

.customCalendarPicker {
  position: absolute;
  right: 100%;
  bottom: 0;
  z-index: 10;
}

.tabContainer {
  background-color: var(--gray-10);
}

.settingsContainer div:first-child {
  margin-top: 0;
  border-top: 0;
}

.customTabButton {
  margin-right: 15px;
  margin-left: 15px;
  border: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.customTabButton:hover,
.customTabButton:focus {
  border: 0;
  background-color: transparent !important;
}

.tabActive {
  border-bottom: 2px solid #037fa9 !important;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
