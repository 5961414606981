@import '../../../../../styles/colors.css';

.highlight {
  appearance: none;
  display: inline;
  border: 0 none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: var(--color-link);

  &:hover,
  &:focus {
    color: var(--color-link-active);
  }
}

.link {
  text-decoration: none;
  color: var(--color-link);
}

.roleList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: inherit;
}

.showAllText {
  margin-left: 3%;
  font-size: 10px;
}
