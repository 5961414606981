.PitchingProfile {
  line-height: calc(24 / 14);
  color: var(--gray-90);
  font-family: var(--primary-font);
  font-size: 14px;
}

.paragraph {
  margin-top: 0;
}

.paragraph:last-child {
  margin-bottom: 0;
}

.loadingBar {
  margin-bottom: 8px;
}
