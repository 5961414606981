.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid var(--gray-20);
  border-bottom: 2px solid var(--gray-20);
  background-color: white;
  padding: 7px 0px;
  color: black;
}

.collapsed {
  align-items: flex-start;
  height: calc(100vh - 142px);
}

.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 1% 0 0 4%;
}

.title {
  margin-right: 10px;
  font-size: 17px;
  font-weight: bold;
}

.clearAll, .apply {
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
}

.apply {
  margin-right: 7px;
}

.collapseExpandIcon {
  cursor: pointer;
}

.expandCollapseTooltip {
  top: 3px;
}
