.actionBarContainer {
  display: flex;
  position: sticky;
  top: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  border-bottom: solid 1px var(--gray-20);
  background: var(--white);
  padding: 0 15px 0 15px;
  height: 55px;
  color: var(--gray-80);
}

.paginationHeader {
  padding: 16px;
}

.actionBarRightGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.sortingGroup {
  padding: 4px 16px;
}

.paginationGroup {
  padding: 4px 16px;
}

.searchGroup {
  display: flex;
  align-items: center;
  padding: 4px 16px;
}
