@import '../../../../styles/colors.css';

.block {
  display: block;
}

.title {
  margin: 0;
  color: var(--gray-90);
  font-weight: bold;
}

.content {
  margin-top: 8px;
  margin-bottom: 24px;
}

.content:last-of-type {
  margin-bottom: 8px;
}

.emptyState {
  margin-top: 8px;
  color: var(--gray-80);
  font-size: 14px;
  font-style: italic;
}
