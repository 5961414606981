.ownerColumn,
.centered {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ownerColumn .icon {
  margin-left: -23px;
}

.widgetsTooltip {
  white-space: pre;
}

.widgetsTooltipWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.widgetsTooltipWrapper .labelDecoy {
  visibility: hidden;
  width: 0;
}
