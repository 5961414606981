.PrivateContactsListSummary {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  cursor: pointer;
  padding: 8px 8px 8px 12px;
}

.prefix {
  align-self: flex-start;
}

.body {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 9px;
  padding-top: 5px;
  color: var(--gray-90);
  font-weight: 700;
}

.description {
  font-size: 12px;
}

.suffix {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.selectListCheckbox {
  position: relative;
  align-self: start;
  margin-top: 5px;
  margin-right: 3px;
  margin-left: 4px;
}
