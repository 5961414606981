@import '../../../../../styles/colors.css';

.InfluencerDesk.InfluencerDesk {
  box-sizing: border-box;
  flex-flow: row wrap;
  border-bottom: 1px solid var(--gray-20);
  padding: 10px 16px;
  font-size: 14px;
}

.InfluencerDesk.InfluencerDesk:last-child {
  border-bottom-width: 0;
}

.InfluencerDesk a {
  color: var(--color-link);
  font-weight: normal;
}

.InfluencerDesk .column {
  flex-basis: 100%;
  flex-grow: 1;
  color: var(--gray-90);
  font-family: var(--primary-font);
}

.InfluencerDesk .name {
  font-weight: var(--bold);
}

.InfluencerDesk.header {
  display: none;
}

.InfluencerDesk.header .column {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
}

.InfluencerDesk:not(.large):first-child {
  border-top: 1px solid var(--gray-20);
}

.InfluencerDesk.large .column {
  flex-basis: 20%;
  flex-grow: 0;
}

.InfluencerDesk.large .name {
  flex-grow: 1;
}

.InfluencerDesk.large:not(.header) {
  height: 60px;
  line-height: 45px;
}

.InfluencerDesk.large:not(.header) .name {
  font-weight: var(--bold);
}

.InfluencerDesk.large.header {
  display: flex;
  padding: 0 16px;
  height: 30px;
  line-height: 30px;
}
