.drilldownFilterBar {
  border-top: 1px solid var(--gray-20);
}

.sticky {
  position: fixed;
  top: 125px;
  right: 0;
  left: 0;
  z-index: 801;
}

.sticky.sidebarOpen {
  left: var(--widget-drilldown-sidebar);
}

:global(.allMentionsDrilldownWrapper) .sticky {
  left: var(--widget-drilldown-left-list-header-sticky);
}

:global(.allMentionsDrilldownWrapper) .sticky.sidebarOpen {
  left: var(--widget-drilldown-left-list-header-sticky-sidebar-open) !important;
}

.sticky.earnedSearches {
  top: 62px;
}

.sticky.discoveryMetaBar {
  top: 65px;
}

.filterIcon {
  color: var(--teal-lite-2);
}

.filter {
  font-weight: var(--bold);
}

.activeFilter {
  background-color: var(--gray);
  color: var(--white);
}

.activeFilter:hover {
  color: var(--white);
}

.searchWrapper {
  display: flex;
  z-index: 10;
  margin-right: 20px;
  min-width: 500px;
}

.searchInput {
  border-style: none;
  background-color: var(--gray-lite-4);
  padding: 8px 40px !important;
  height: 40px;
}

.searchInput:focus {
  box-shadow: none;
}

.searchIcon {
  position: relative;
  bottom: 38px;
  left: 120px;
  width: 12px;
  height: 12px;
}

.crossIconCircle {
  position: relative;
  top: 10px;
  right: 30px;
  border-radius: 50%;
  background-color: var(--gray-lite-1);
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: white;
}

.crossIcon {
  margin: 2px;
}

.inputSearchIcon {
  position: relative;
  top: 10px;
  left: 30px;
  z-index: 10;
  width: 20px;
  height: 20px;
}

.columnDropdown {
  width: 284px;
  max-height: 450px;
  overflow-y: auto;
}

.toggleEntry {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  padding: 8px;
}

.actionIcon,
.checkbox {
  margin-right: 6px;
}

.defaultCheckbox {
  margin: 0 10px 0 20px !important;
}

.alignedCheckbox {
  margin: 0 10px 0 3px !important;
}

.newDrilldowListActionIcon {
  margin: 0px;
}

.removeWrapper,
.tagWrapper,
.sentimentWrapper,
.refreshSocialWrapper {
  padding: 0 2px;
}

.bodyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.analyticsIntegrationDropdown {
  width: 250px;
}

.applyRightMargin {
  margin-right: 30px;
}

.listVisor :global(.tk-list-visor-controls) {
  margin: 0;
}

.listVisor .controls {
  margin-top: 0;
  padding: 0;
  height: 100%;
}
.listVisorNewPadding :global(.tk-list-visor-controls) {
  padding-right: 0px;
}

.listVisor :global(.tk-media) > div:empty {
  display: none;
}

.listVisor .sections {
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
}

.fixedRow {
  flex: 1 1;
  justify-content: space-between;
  padding-left: 17px;
  width: 100%;
}

.drilldownActionBarExpandableDropdown {
  display: flex;
  align-items: center;
  height: 40px;
}

.drilldownActionBarContainerClass {
  display: flex;
  align-items: center;
}

.dateRangeWrapper {
  position: relative;
}

.drilldownActionBarDateRangePicker {
  position: absolute;
  top: 32px;
  z-index: 11;
  margin: 5px;
  width: 614px;
}

.drilldownActionBarDateRangePickerNewDrilldown {
  right: 1%;
}

.drillDownActionBarTagDropDownContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  width: 170px;
}

.drillDownActionBarArticleLimiterDropDown {
  display: flex;
  flex-direction: column;
}

.drillDownActionBarArticleLimiterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.drillDownActionBarArticleLimiterMainButton {
  border-radius: 4px;
  padding: 8px;
}

.drillDownActionBarDropDownOptions {
  display: inline-flex;
  line-height: 24px;
  color: var(--gray-90);
  font-family: Proxima Nova, proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.drillDownActionBarArticleLimiterOption:hover {
  background-color: var(--gray-10);
}

@media only screen and (max-width: 1230px) {
  .drilldownActionBarDateRangePickerNewDrilldown.drilldownActionBarDateRangePickerWithSearchInputOpen {
    left: -175px;
  }
}

@media only screen and (min-width: 1230px) {
  .drilldownActionBarDateRangePicker {
    right: 1%;
  }

  .drilldownActionBarDateRangePickerWithSearchInputOpen {
    left: -175px;
  }

  .listVisor :global(.tk-media) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .listVisorWithSearchInputOpen :global(.tk-media) {
    flex-wrap: wrap;
  }

  :global(.searchBuilderModal) .listVisor {
    height: inherit;
  }

  :global(.searchBuilderModal) .listVisor :global(.tk-media) {
    justify-content: inherit;
  }
}

.buttonActionBar:hover {
  background-color: var(--gray-10);
  color: var(--gray-90);
}

.dropdownSentimentButton {
  display: inline-flex;
}
