.linkClick {
  padding: 0;
  text-decoration: none;
  color: var(--light-blue);
  font-weight: 600;
}
  
.linkClick:hover {
  color: var(--light-blue-hover);
}
  