.ReportEditContainer {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  padding-top: 65px;
}

.ReportNotFoundContainer {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  margin: 10% 0;
  padding-top: 65px;
}

.reportTitleInput {
  margin: 0;
  border: none;
  cursor: pointer;
  width: 760px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.reportTitleInput:hover {
  border: 2px solid #3398db;
}

.reportTitleInput:focus {
  border: 2px solid #3398db;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.truncable {
  width: 100%;
}

.campaignList {
  display: inline-flex;
  flex-wrap: wrap;
}
.campaignListItem {
  padding: 0 2px 0 2px;
}

.campaignListItem * {
  padding: 0;
}

.reportsV3Header > div > div > button {
  margin-bottom: 0;
  border: none;
  padding: 1px 2px !important;
  height: 20px;
  color: var(--gray-lite-3);
}

.reportsV3Header > div > div > button svg {
  height: 4px;
}
.reportsV3Header > div > div > button span:first-child {
  display: none;
}
