/* stylelint-disable no-descending-specificity */
.modalWrap .modal :global(.tk-modal__dialog) {
  width: 504px;
}

.header,
.body,
.footer {
  background-color: var(--gray-10);
}

.avatarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  line-height: 1.43;
}

.avatarHeader :global(.tk-label) {
  margin: 0px;
  padding: 0px;
}

.error,
.error:hover,
.error:focus,
.inputError::placeholder {
  color: var(--error);
}
div.error {
  margin-bottom: 15px;
}

.personaNameLabel {
  margin-top: 12px;
  margin-bottom: 4px;
  line-height: 24px;
}

.inputError,
.inputError:hover,
.inputError:focus {
  border-color: var(--error);
}

.personaNameInput {
  transition: border 0.2 ease;
  color: var(--gray-90);
}

.personaNameInput::placeholder {
  color: var(--gray-80);
}

.errorMessage {
  display: block;
  margin-top: 4px;
  line-height: 24px;
}

.loading {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 318px;
}
.loading > div:last-child {
  margin-top: 25px;
  text-align: center;
}
