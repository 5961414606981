.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.bodyContainer{
  display: flex;
  flex-direction: row;
  height: calc(100vh - 180px);
}

.accountManagementSideBarContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid var(--gray-20);
  background-color: var(--white);
  width: 20em;
  height: inherit;
}

.rightContent {
  flex: 1;
  background-color: var(--white);
  padding-right: 10px;
  padding-left: 25px;
  overflow-x: auto;
}
