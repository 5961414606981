@import '../../styles/colors.css';

.row {
  display: grid;
  align-items: center;
  margin: 0 10px;
  border-bottom: 1px solid var(--gray-20);
  padding: 0 20px;
  height: 43px;
}

.row:hover {
  background-color: var(--gray-10);
}

.rowSelected {
  box-shadow: inset 5px 0 0 0 var(--light-teal);
  background-color: var(--gray-10);
}

.svgThreeColumnRow {
  grid-template-columns: 30px 1fr minmax(40px, 85px);
  -ms-grid-columns: 30px 1fr minmax(40px, 85px);
}

.threeColumnRow {
  grid-template-columns: 15px 1fr minmax(40px, 150px);
  -ms-grid-columns: 15px 1fr minmax(40px, 150px);
}

.twoColumnRow {
  grid-template-columns: 1fr minmax(30px, 40px);
  -ms-grid-columns: 1fr minmax(30px, 40px);
}

.twoColumnRowWithTotalMentions {
  grid-template-columns: 1fr minmax(40px, 150px);
  -ms-grid-columns: 1fr minmax(40px, 150px);
}

.twoColumnRowWithPercentage {
  grid-template-columns: 1fr minmax(30px, 100px);
}

.MetricDetailEmpty {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  height: 200px;
}

.label {
  display: flex;
  flex: 1;
  align-items: center;
  height: 44px;
}

.value {
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
  line-height: 44px;
}

.justifyStart {
  justify-content: flex-start;
}

.headerLabel {
  display: flex;
  align-items: center;
  height: 44px;
  line-height: 44px;
}

.gridColumnStartOne {
  grid-column-start: 1;
}

.gridColumnStartTwo {
  grid-column-start: 2;
}

.gridColumnStartThree {
  grid-column-start: 3;
}

.dot {
  display: inline-block;
  margin-right: 10px;
  border: 3px solid transparent;
  border-radius: 50px;
  width: 3px;
  height: 3px;
  line-height: 44px;
}

.barLabelWrapper {
  display: inline-block;
  margin-top: 5px;
  width: 100%;
}

.barLabelWrapperLabelHeader {
  display: flex;
}

.withSubLabel,
.barLabelLabel,
.trendLabelWithEllipsisTextOverflow {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.barLabelLabel {
  padding-right: 10px;
}

.barLabelSubLabel {
  justify-self: flex-end;
  margin-left: auto;
  white-space: nowrap;
}

.trendWrapper {
  display: flex;
  line-height: 44px;
}

.trendLabel {
  flex: 1;
  flex-wrap: wrap;
  height: 44px;
  overflow: hidden;
}

.trendLine {
  display: inline-block;
  justify-content: flex-end;
  padding-right: 12px;
  width: 150px;
}

.drilldownLink {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.lastItemCleanBorderBottom {
  border-bottom: none;
}

.dateCompareContainer {
  display: flex;
  justify-content: space-between;
}

.threeColumnWiderTextRow {
  /* minmax(28px, 38px) means space for numbers with 1,2 and 3 numbers (8%, 10% and 100%) */
  grid-template-columns: 15px 1fr minmax(28px, 38px);
  -ms-grid-columns: 15px 1fr minmax(28px, 38px);
}

.rowIcon {
  align-self: center;
  opacity: 0;
}

.rowIconVisible {
  opacity: 1;
}

.rowIconPadding {
  padding-top: 6px;
  padding-right: 8px;
  padding-left: 10px;
}

.similarWebLogo {
  text-align: left;
  line-height: 1;
}

.similarWebLogoCenter {
  text-align: center;
  line-height: 1;
}
