.SocialProfile {
  box-sizing: border-box;
  margin-top: 0 !important;
  border-top: 1px solid var(--gray-20);
  padding: 16px 0;
}

.primaryMetrics {
  box-sizing: border-box;
  padding: 16px 0;
  width: auto;
}

.secondaryMetrics {
  height: 0;
  overflow: hidden;
}

.secondaryMetrics.expanded {
  height: auto;
}

.secondaryMetric {
  box-sizing: border-box;
  border-top: 1px solid var(--gray-20);
  padding: 16px 20px;
}

.expandableRight {
  min-width: 54px;
}

.socialLink {
  line-height: 1.8rem !important;
}
