.InfluencerActions {
  visibility: visible;
}

.actionIcon {
  --size: 30px;
  margin-right: 15px;
  border: 0;
  border-radius: var(--size) !important;
  background-color: transparent !important;
  width: var(--size) !important;
  height: var(--size) !important;
}

.actionIcon:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.dropdown {
  position: relative;
  top: 2px;
  right: 10px;
}
