.pageContainer {
  min-width: 1080px;
  max-width: 100%;
  /* 60px and 65px are the page header and subheader respectively*/
  height: calc(100vh - 60px - 65px);
  min-height: 600px;
  max-height: 100%;
  overflow: auto;
}
.attributionChartContainer {
  border-bottom: 1px solid var(--gray-20);
  height: 320px;
  min-height: 320px;
}
.chart {
  padding-top: 5px;
  /* 360px legends width */
  width: calc(100% - 360px);
  min-width: 445px;
}
.attributionsContent {
  /* 300px sidebar width */
  width: calc(100% - 300px);
  overflow: hidden;
}
.legends {
  width: 360px;
}
.legendsList {
  display: block;
  margin: 0px;
  margin-top: 14px;
  padding: 0px;
  list-style: none;
}
.legendsItem {
  border-top: 1px solid var(--gray-20);
}
.tableContainer {
  /* 320px is the chart container height*/
  height: calc(100% - 320px);
}
.secondaryNavContent {
  display: flex;
}
.chartHintText {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}
