.propertyCell {
  display: flex;
  padding-bottom: 10px;
}

.propertyLabel {
  width: 50%;
  text-align: right;
  font-weight: bold;
}

.propertyValue {
  margin-left: 4%;
  width: 50%;
}
