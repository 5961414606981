.topContributingContainer {
  height: auto;
  max-height: 522px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.infiniteScrollContainer {
  border-top: 1px solid var(--gray-20);
  padding: 42px 0;
}
