@import '../../styles/colors.css';

.header {
  padding: 25px 0 30px;
  text-align: center;
}

.paper {
  margin: 10px 150px 0 150px;
}

.container {
  padding: 25px;
}
