@import '../../../../../styles/colors.css';

.label {
  letter-spacing: 1px;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: bold;
}

.rate {
  align-content: space-between;
}

.rate:not(:last-child) {
  margin-bottom: 10px;
}

.valueSection {
  letter-spacing: 1px;
  font-size: 14px;
}