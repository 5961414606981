.ResponsiveInfluencerProfile {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
}

.InfluencerProfile {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.actionButtonsContainer {
  display: flex;
  margin-left: auto;
  padding: 8px 0;
}

.viewTitleBarWrapper {
  flex: 0 0 auto;
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  min-height: 0;
}

.tiny .wrapper,
.small .wrapper {
  flex-direction: column;
  flex-shrink: 0;
}

.grid {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

.sidebarWrapper {
  flex: 0 0 auto;
  width: 350px;
  overflow-y: auto;
}

.tiny .sidebarWrapper,
.small .sidebarWrapper {
  width: auto;
}

.contentWrapper {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-width: 0;
  overflow-y: auto;
}

.leftContentClassModifier {
  margin-top: 0.8%;
  width: 308px;
  min-width: 308px;
}

@media (max-width: 768px) {
  .leftContentClassModifier {
    width: 20px;
    min-width: 20px;
  }
}


@media (max-width: 425px) {
  .wrapper {
    margin-top: 50px;
  }

  .leftContentClassModifier {
    width: 100%;
    min-width: 100%;
  }
}

.circulation {
  margin: 1px 16px 7px;
}

.editButton {
  margin-right: 5px;
}

.plusIcon {
  padding-right: 6px;
}

.dropDownWrapper {
  position: relative;
}

.dropdownClassModifier {
  top: -20%;
  width: 100%;
}
