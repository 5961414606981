.callOut {
  grid-column: span 2 / span 2;
  align-items: center;
  justify-items: center;
  margin-bottom: 1rem;
  color: -var(--medium-blue);
}

.infoIcon {
  margin-right: 1.25rem;
  color: var(--color-warning);
}
