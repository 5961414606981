.carousel {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.carouselSlider {
  display: flex;
  flex-wrap: nowrap;
  transform: translateZ(0deg);
}

.carouselSliderStep {
  display: flex;
}
