.advancedTextEditor {
  background: var(--gray-90);
  padding: 10px;
  height: 336px;
  overflow-y: auto;
  resize: none;
  color: white;
  font-size: 16px;
}

.actionBar {
  display: flex;
  flex-direction: row;
  margin: 16px 0 16px 0;
}

.actionBar .preview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.actionBar .preview .suggestion {
  margin-bottom: 8px;
}

.actionBar .actions {
  display: flex;
  flex-direction: column;
}
