.groupsCounter {
  color: #de4543;
}

.groupsCounterTooltip {
  width: 314px;
  text-align: center;
  line-height: 24px;
  color: var(--gray-90);
}
