.container {
  display: flex;
  position: fixed;
  left: 0;

  /* z-index values:
   * - header items: 700 (but modal is positioned under the header)
   * - header sub items: 900 (dropdown after you click a header item)
   * - ViewTitleBar: 800 (can be present on the current view)
   *
   * z-index must be between the ViewTitleBar and the header sub items, thus we
   * use 850.
   */
  z-index: 850;
  background-color: rgba(0, 0, 0, 0.1);
  /* Ensuring the width of the background gray overlay always be larger
   * than width screen when is appearing in the screen. With animation it
   * appear from side to side.
   */
  width: calc(100vw * 2);
  height: 100%;
}

.content {
  display: inherit;
}
