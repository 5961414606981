.pageSection {
  margin-bottom: 32px;
  padding: 16px;
}
.divider {
  display: inline-block;
  width: 5px;
  height: auto;
}

.errorSection {
  padding: 16px;
}

.loaderWrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  z-index: 10;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
