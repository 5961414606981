.EmailVerification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.title {
  color: var(--black);
  font-size: 42px;
  font-weight: 700;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.button {
  width: fit-content;
}

.loader {
  margin: 0 0 0 15px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 677px;
}

.divider {
  margin: 24px 0 24px 0;
  border-top: 1px solid #cccfd2;
  width: 100%;
}
