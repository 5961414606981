.titleBarActionsContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}

.titleBarActionsContainer > * {
  margin-right: 8px;
}

.iconButton {
  display: flex;
  justify-content: center;
}

.actionsDropdown {
  min-width: 150px;
}

.shareIconLabel {
  margin-left: 5px;
}

.shareIconDisabled {
  pointer-events: none;
}
