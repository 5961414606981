.topContributingRow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  padding: 4px 16px;
  height: fit-content;
  min-height: 96px;
  max-height: 107px;
}

.topContributingRow:hover {
  background-color: var(--gray-10);
  cursor: pointer;
}

.hideLastBorderBottom {
  border-bottom: unset;
}

.topContributingNumber {
  min-width: 44px;
}

.topContributingInfo {
  display: flex;
  flex: 1;
}

.topContributingInfoImageContainer {
  margin-right: 8px;
  width: 96px;
  min-width: 96px;
  height: 80px;
}

.topContributingInfoImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topContributingInfoBody {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.topContributingInfoDomain {
  display: flex;
  margin-bottom: 4px;
  line-height: 16px;
  color: var(--battleship-grey);
  font-size: 12px;
}

.avatar {
  margin-right: 8px;
}

.topContributingInfoUrl {
  line-height: 20px;
}

.topContributingInfoUrlText {
  margin-bottom: 4px;
  width: 300px;
  word-break: break-all;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: 600;
}

.topContributingInfoUrlFooter {
  display: flex;
  margin-bottom: 4px;
  line-height: 16px;
  color: var(--battleship-grey);
  font-size: 12px;
  font-weight: normal;
}

.topContributingInfoUrlFooterDot + .topContributingInfoUrlFooterDot:before {
  margin-right: 4px;
  margin-left: 4px;
  content: '\2022';
}

.topContributingsStats {
  flex: 0.3;
}

.topContributingsStatsNumber {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}

.topContributingsStatsText {
  line-height: 16px;
  color: var(--gray-90);
  font-size: 12px;
}

.topContributingsStatsProgressBar :global(.bar-stat) {
  width: 161px;
}

.topContributingsStatsProgressBar :global(.bar-stat__bar) {
  border-radius: 5px;
  background-color: var(--gray-lite-4);
  height: 6px;
}

.topContributingsStatsProgressBar :global(.bar-stat__bar-fill) {
  border-radius: 5px;
  background-color: var(--charting-blue-base);
}

.outletName {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (min-width: 1024px) {
  .topContributingInfoUrlText {
    width: 500px;
  }
  .outletName {
    max-width: 500px;
  }
}

@media only screen and (min-width: 1440px) {
  .topContributingInfoUrlText {
    width: 100%;
  }
  .outletName {
    max-width: 100%;
  }
  .topContributingsStats {
    flex: 0.2;
  }
}

@media only screen and (min-width: 2560px) {
  .topContributingsStats {
    flex: 0.1;
  }
}
