.titleBold {
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
}
.conversionsSubTitle {
  max-width: 285px;
}
.widgetErrorContainerSmall,
.widgetErrorContainerLarge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}
.widgetErrorContainerSmall {
  flex-direction: column;
  text-align: center;
}
.widgetErrorContainerLarge {
  margin: auto;
  text-align: left;
}

.widgetErrorMessageContent {
  width: 285px;
}
