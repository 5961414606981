.titleInput {
  margin: 10px 0 20px;
}

.primarySearch {
  margin: 10px 0;
}

.dateRange {
  margin: 10px 0;
}

.secondarySearches {
  margin: 10px 0 20px 0;
}

.secondarySearchPill {
  margin-right: 10px;
}

.booleanEditor {
  margin-bottom: 40px;
  height: 400px;
}

.simpleSearchButton {
  transition: color 0s;
}

.pane {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: 0 16px 16px;
}

.aceEditor {
  flex-grow: 1;
  color: #fff!important;
}

.booleanFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
