.container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
  width: 100%;
  height: fit-content;
  max-height: 157px;
  font-size: 12px;
}

.container:before {
  box-sizing: border-box;
  width: 12px;
  min-width: 12px;
  content: ' ';
}

.container.selected,
.container:hover {
  background-color: var(--gray-10);
}

.container:hover:not(.selected) {
  cursor: pointer;
}

.container.selected:before {
  box-shadow: inset 5px 0 0 0 var(--light-teal);
}

.titleContainer {
  margin-top: 6px;
  max-width: 178px;
  max-height: 50px;
}

.title {
  line-height: 1.21;
  word-wrap: break-word;
  color: var(--gray-80);
  font-size: 14px;
  font-weight: bold;
  /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  /* Careful, this breaks the word wherever it is without a hyphen */
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  /* Adds a hyphen where the word breaks */
  hyphens: auto;
  overflow-wrap: break-word;
}

.avatar {
  margin-right: 8px;
}

.selected .title {
  color: var(--gray-90);
}

.leftBorder {
  width: 12px;
}

.left {
  padding-top: 8px;
  width: 80px;
  font-weight: bold;
}

.right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 8px;
}

.left,
.right {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1;
}

.index,
.views {
  margin-bottom: 4px;
}

.views {
  font-weight: bold;
}
.image {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  object-fit: cover;
}
.placeholderClassName {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 96px;
  min-height: 96px;
  max-height: 96px;
}

.outletContainer {
  display: flex;
  align-items: center;
}

.outletName {
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  white-space: nowrap;
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 6px;
}

.footerItem {
  max-width: 92px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footerItem + .footerItem:before {
  margin-right: 4px;
  margin-left: 4px;
  content: '•';
}
