.pageSection {
  padding: 16px;
}

.btnImport {
  margin: 0px 10px;
}

.loaderWrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  z-index: 10;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.migrationSuccessContainer {
  display: flex;
  flex-direction: column; 
  align-items: center;
  margin-top: 2rem;
  gap: 0.5rem;
}

.importCustomerContainer {
  margin: auto;
  max-width: 1200px;
}