.InfluencerMessageCenter {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  min-height: 0;
}

.sidebar {
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  transition: 100ms linear margin;
  width: 300px;
}

.collapsed {
  margin-left: -280px;
}

.content {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
