/*
  Set props to inherit because some of the consumers of this component
  apply styles to nested links.
*/
.link.link {
  text-transform: inherit;
  text-decoration: inherit;
  line-height: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}
