.emailModalBody {
  margin-top: '-10px';
  margin-left: '18px';
}
.modalInputLabel {
  color: var(--black);
}

.blockedEmailText {
  color: var(--error) !important;
}

body {
  overflow-y: visible !important;
}
.alreadyVerifiedOrIntegratedEmailText {
  color: var(--color-success);
}
.emailSecurityLink {
  text-decoration: none;
  color: var(--light-blue);
}
