.InfluencerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  text-align: center;
}

.centered {
  align-self: center;
  justify-content: center;
  text-align: center;
}

.contactMethods {
  display: flex;
  justify-content: center;
}

.contactsDivider {
  padding: 0 5px;
}

.avatar {
  margin: 12px 0;
}

.highlight {
  color: var(--gray-90);
}

.circulation {
  white-space: nowrap;
}

.badges {
  padding: 20px 0px 0px 20px;
  width: 40%;
  text-align: start;
}
