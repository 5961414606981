.row {
  grid-template-columns: 400px 2fr 2fr;
  align-items: flex-start;
}

.detailsTable {
  display: grid;
  grid-template-columns: min-content auto;
  width: 100%;
}

.detailsTable > div {
  padding: 3px;
}

.queryWrapper {
  border-radius: 3px;
  background: #272822; /* match mono-kai theme we use */
  padding: 20px;
}

.aceEditor {
  color: #fff !important; /* since aceEditor is so shittily written that it overrides its own themes with default styles */
}
