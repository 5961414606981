.assistantOption {
  margin-left: 10px;
  font-weight: 700;
}

.blockedEmailText {
  color: var(--error) !important;
}

.alreadyVerifiedOrIntegratedEmailText {
  color: var(--color-success);
}

.separator {
  border-top: 1px solid var(--gray-20);
}

.formatSelection {
  margin-top: 20px;
  width: 522px;
}

.callout {
  margin-top: 10px;
  margin-left: 10px;
}

.calloutLink {
  text-decoration: none;
  color: var(--light-blue);
}

.calloutInfoIcon {
  color: #377992;
}

.emailSecurityLink {
  text-decoration: none;
  color: var(--light-blue);
}

.senderNameField {
  display: flex;
}