.wrapper {
  position: absolute;
  top: 100%;
  margin-top: 20px;
}

.dropdown {
  z-index: 1;
  margin-top: -15px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  padding: 5px;
}

.searchSidebarSuggestionsDropdown {
  z-index: 1;
  margin-top: 0px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  padding: 5px;
}

.searchSidebarSuggestionsWrapper {
  top: 0%;
  margin-top: 16%;
}

.limitHeightScrollable {
  max-height: 200px;
  overflow-y: auto;
}

.pillType {
  display: flex;
  align-items: flex-start;
}

.pillAvatar {
  margin-right: 5px;
}

.pillItemName {
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
}

.pillItemCountry {
  line-height: 16px;
  color: var(--gray-80);
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
}

.pillItemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px !important;
}

.pillItem {
  margin: 8px 0;
  border-radius: 100px;
  padding: 9px 16px;
  max-width: 400px;
  line-height: 16px;
}

.pillItem:hover {
  background-color: var(--light-blue) !important;
  color: var(--white) !important;
}

.pillItem:hover .pillItemCountry {
  color: var(--white) !important;
}

.piDropdown {
  position: absolute;
  top: 40px;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  padding: 5px;
}

.openUp {
  position: absolute;
  height: 200px;
  overflow-y: auto;
}

.suggestedMediaPill div[class*='style_Pill'] {
  margin: 5px 0 0 2px;
  border: 1px solid #03fd0f;
  border-radius: 36px;
  box-shadow: none;
  padding: 10px;
  max-width: 340px;
}

.suggestedMediaPill div[class*='style_Pill'] span[class*='style_content'] {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-left: 10px;
}

.suggestedMediaPill div[class*='style_Pill'] span[class*='style_content'] span {
  margin-top: 5px;
  color: var(--gray-60);
  font-size: 12px;
  font-weight: 400;
}

.suggestedMediaPill div[class*='style_Pill'] div[class*='style_actionInline'] {
  margin-inline-start: 5px;
  margin: 0 0 0 5px;
}

.suggestedMediaPill
div[class*='style_Pill']
span[class*='style_content']
span:first-child {
  color: var(--light-blue);
  font-weight: var(--bold);
}

.suggestedMediaPill
div[class*='style_Pill']:hover
span[class*='style_content']
span:first-child,
.suggestedMediaPill
div[class*='style_Pill']:hover
span[class*='style_content']
span:last-child {
  color: var(--white);
}
