@import '../../styles/colors.css';

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0.1% 51%;
  }
  100% {
    background-position: 0% 51%;
  }
}

.SkeletonLoader {
  position: relative;
  overflow: hidden;
}

.loader {
  background: var(--gray-lite-4);
  background: linear-gradient(
    90deg,
    var(--gray-lite-4),
    var(--gray-lite-4) 20%,
    rgba(255, 255, 255, 0.1),
    var(--gray-lite-4) 80%,
    var(--gray-lite-4)
  );
  background-position: 100% 50%;
  background-size: 400% 400%;
  animation: loading 1.5s ease-in-out 1s infinite;
}

.SkeletonPill {
  border-radius: 100px;
  width: 120px;
  height: 32px;
}

.SkeletonAvatarAndText {
  position: relative;
  width: 100%;
  height: 40px;
}

.SkeletonAvatarAndText .circle {
  position: absolute;
  top: 0;
  left: 0;
}

.SkeletonAvatarAndText .title {
  position: absolute;
  top: 0;
  left: 50px;
  height: 10px;
  overflow: hidden;
}

.SkeletonAvatarAndText .maintext {
  position: absolute;
  top: 20px;
  left: 50px;
  border-radius: 5px;
  height: 10px;
}

.SkeletonAvatar {
  margin: 0 auto 15px;
  border-radius: 60px;
  width: 60px;
  height: 60px;
}

.SkeletonAvatarAndName {
  position: relative;
  width: 100%;
  height: 32px;
}

.SkeletonAvatarAndName .circle {
  position: absolute;
  top: 0;
  left: 0;
}

.SkeletonAvatarAndName .name {
  position: absolute;
  top: 50%;
  /* the component sets the `left` property in the style prop */
  margin-top: calc(-15px / 2);
  height: 15px;
  overflow: hidden;
}

.MiniCardArticle {
  margin-bottom: 8px;
}

.MiniCardArticle:last-child {
  margin-bottom: 0;
}

.MiniCardArticle .image {
  margin-right: 8px;
  width: 96px;
  height: 80px;
}

.SkeletonMiniCard {
  position: relative;
  padding: 25px 0;
  width: 100%;
}

.SkeletonBar {
  border-radius: 4px;
  height: 16px;
}

.center {
  margin: 7px auto;
}
