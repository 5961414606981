.InfluencersListSideTray {
  box-sizing: border-box;
  visibility: visible;
  overflow: hidden;
}

.InfluencersListSideTray * {
  box-sizing: border-box;
}

.searchByName {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #FFFFFF;
}

.searchByNameActive {
  display: contents;
  background-color: var(--gray-10);
}

.searchingBody {
  background-color: var(--white);
}

.listDetailHead {
  position: sticky;
  top: 76px;
  z-index: 999;
  background-color: #FFFFFF;
}

.title {
  align-self: center;
  color: var(--gray-90);
  font-size: 18px;
}

.selection.selection {
  flex: 0 0 auto;
}

.footerContent {
  justify-content: right;
}

.footerContent.footerContent {
  align-items: center;
}

.buttons.buttons {
  display: flex;
  flex-flow: row nowrap;
}

.buttons > .button + .button {
  margin-left: 8px;
}

.listTitle {
  align-self: center;
  color: var(--gray-90);
  font-size: 18px;
  font-weight: bold;
}

.listHead.listHead {
  padding: 16px;
}

.buttonClose {
  margin-left: 8px;
}

.buttonBack {
  margin-right: 8px;
}

.body {
  overflow: hidden;
}
