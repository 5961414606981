.SocialSearchDateRange {
  margin: 0 8px;
}

.calendarIcon {
  margin-right: 7px;
}

.calendarDatePicker {
  position: absolute;
  z-index: 10;
  right: 10px;
}
