.listWrapper {
  height: calc(100vh - 215px);
  overflow: auto;
}

.emptyList {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0 280px;
}

.emptyListLabel {
  padding-left: 5px;
}
