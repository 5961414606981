.TagEmptyState {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.EmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: none;
  border: none;
}

.EmptyState > *:first-child {
  margin-top: 120px;
}

.EmptyState .title {
  margin-top: 24px;
}

.EmptyState .message {
  margin-top: 16px;
}

.EmptyState .actionButton {
  margin-top: 24px;
}
