.Influencer {
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid var(--gray-20);
  background-color: #fff;
  padding: 20px;
  font-family: 'Proxima Nova', sans-serif;
}

.Influencer * {
  box-sizing: border-box;
}

.Influencer:hover {
  background-color: #f7f7f7;
}

.Influencer.selected {
  box-shadow: inset 0 0 0 1px #3398db;
  background-color: #fafeff;
}

.Influencer.tiny {
  text-align: center;
}

/* Actions */
.actions {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 15px;
}

/* Checkbox */
.checkbox {
  align-items: flex-start;
}

/* Avatar */
.avatarWrapper {
  display: block;
  align-self: flex-start;
  margin: 0 36px 0 20px;
}

.tiny .avatarWrapper {
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 0 36px;
  padding-bottom: 20px;
}

.outletAvatar {
  border-radius: 100px;
  box-shadow: 0 0 0 2px #fff, 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
}

/* Social */
.socialWrapper {
  height: 2em;
}

.tiny .socialWrapper {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

/* Stats */
.statsWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-around;
  margin: 20px;
}

.tiny .statsWrapper {
  flex: 1 1 auto;
  align-items: center;
  margin: 0;
  max-width: none;
}

.small .statsWrapper {
  width: 30vw;
}

/* Details */
.name {
  display: inline;
  margin: 0 0 0.25em;
  line-height: 1;
  color: var(--gray-90);
}

.text,
.location {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--gray-80);
}

.location {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.tiny .location {
  display: inline-flex;
}

.locationPin {
  margin-right: 5px;
}

.titleAndOutlet {
  margin: 0.25em 0;
  color: var(--gray-90);
}

.locationAndTargetArea {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.locationAndTargetArea p:first-of-type {
  margin-right: 10px;
}

.checkboxAndAvatar {
  flex: 0 0 auto;
}

.info {
  flex-basis: 41%;
  max-width: 41%;
}

.gridRightArea {
  flex-basis: 45%;
  max-width: 45%;
}

.influencerDataArea {
  display: flex;
  flex-flow: row nowrap;
}

.skeletonInfo {
  margin-left: 16px;
}

.skeletonBar {
  margin-bottom: 8px;
}

.badge {
  display: inline-block;
}

.badges {
  display: flex;
  position: absolute;
  top: 14px;
  flex-direction: row;
  margin-left: -0.5%;
}
