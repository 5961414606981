.SocialStreamChart {
  visibility: visible;
}

.title {
  display: block;
  margin: 1em 0;
  margin-left: 20px;
  color: var(--black);
  font-size: 18px;
  font-weight: var(--bold);
}

.activityChart {
  display: grid;
  grid-template-columns: 60% 40%;
}

.activityChartLoading {
  display: flex;
  height: 216px;
}

.metricsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

.donutChartSection {
  height: 216px;
}
