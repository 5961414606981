.sidebarRow {
  display: flex;
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--white);
  padding: 24px 16px;
  /*getting min-height regarding to paddings and overall height of the component*/
  min-height: calc(105px - 24px * 2);
}

.active {
  box-shadow: inset 5px 0 0 0 var(--light-teal);
  background-color: var(--gray-10);
}
@media only screen and (min-width: 1024px) {
  .sidebarRow {
    padding: 24px;
  }
}

.sidebarRow:hover {
  background-color: var(--gray-10);
  cursor: pointer;
}

.sidebarRowAvatar > svg {
  width: 40px;
  height: 40px;
}

.sidebarRowText {
  flex-grow: 1;
  padding-left: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarRowTitle {
  line-height: 1.71;
  color: var(--gray-90);
  font-size: 14px;
}

.active .sidebarRowTitle {
  font-weight: bold;
}

.active:hover {
  cursor: default;
}
