.header {
  background-color: var(--white);
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}

.body {
  background-color: var(--white);
}

.button {
  padding: 0 10px;
}

.title {
  padding: 10px;
}

.buttonWrapper {
  margin: 0 3px;
  text-align: right;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.createListButton {
  display: flex;
  align-items: center;
}

.loader {
  display: inline-block;
  position: absolute;
  bottom: 25px;
  left: 30px;
}

.searchInput {
  border-bottom: 1px var(--gray-20) solid;
  padding: 16px;
}
