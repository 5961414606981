.tooltip {
  --tooltipBorder: var(--white);
  background-color: var(--white);
  padding: 10px;
}

.tooltipArrow {
  --tooltipBackground: var(--white);
  --tooltipBorder: var(--white);
  display: block;
}

.tooltip[data-popper-placement*='right'] .tooltipArrow {
  margin-left: -0.9rem;
}
