.lightningBoltButton {
  position: relative;
  right: 35px;
}

.lightningBoltOptionStyle {
  display: flex;
  top: 136px;
  left: 16px;
  align-items: center;
  margin-left: 16px;
  border-radius: 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 4px 10px 0px 12px !important;
  width: fit-content !important;
  height: 32px !important;
  color: var(--light-blue) !important;
}
