.addAccountForm {
  max-width: var(--form-max-width);
}

.addAccountTitle {
  text-align: center;
}

.buttonHolder {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}

.adminBox_tkLoader_position {
  position: absolute;
  top: 35%;
  left: 30%;
}
