.Topics {
  position: relative;
}

.chosen {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}

.pillWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -21px;
  border: 1px solid var(--gray-40);
}

.suggestionsWrapper {
  position: absolute;
}

.suggestionsWrapper > div {
  position: relative;
  top: -20px;
}

.pills {
  padding: 7px 0 7px 7px;
}

.pills [class*='style_checked'] div:first-of-type {
  margin: 0 2px;
}

.pills [class*='style_content'] {
  line-height: 8px;
  font-size: 12px;
}

.pills svg {
  width: 17px;
  height: 17px;
}

.searchIcon {
  position: relative;
  top: 30px;
  margin-left: 20px;
  width: 20px;
  height: 20px;
}

.searchInput {
  text-indent: 30px;
}

.clearInputCrossIcon {
  position: absolute;
  right: 2px;
  margin-top: 3px;
}
