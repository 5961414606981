.headerSection {
  display: flex;
  justify-content: space-between;
}

.exportButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  padding: 16px;
  height: 80%;
}

.pageContainer {
  margin: auto;
  max-width: 1200px;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.toggleLabel {
  display: inline-block;
  margin-top: auto;
  margin-right: 1rem;
  margin-bottom: auto;
}

.toggleButton {
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
}

.expansionPanel {
  border: 1px solid #bfbfbf6b;
  padding: 0;
}

.accordion {
  margin: 10px 0;
}

.accordionHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 10px;
}

.dateStyle {
  white-space: nowrap;
}

.accordionExpandedHeader {
  padding-right: 16px;
}

.horizontalDivider {
  align-self: stretch;
  border-left: 1px solid #c3c3c3;
  width: 1px;
}

.arrowIcon {
  width: 18px;
}

.accordionBody {
  display: flex;
  flex-direction: column;
  margin: 0 25px;
  border-top: 1px solid #e0e0e059;
  padding: 16px 0;
}

.date {
  font-weight: 600;
}

.migrationPhasesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 20px;
}

.iconTooltip {
  height: 24px;
}

.inProgressColor {
  color: var(--blue);
}

.errorColor {
  color: var(--color-danger);
}

.successColor {
  color: green;
}

.accountContainer {
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
}

.accountsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 15px;
}

.migrationStatusContainer {
  border-top: 1px solid #e0e0e059;
  padding-top: 10px;
}

.accountsArrowBig {
  align-self: center;
  padding: 0 30px;
}

.migrationItem {
  text-transform: uppercase;
}

.migrationModalBody{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.migrationModalFooter{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loaderPosition {
  position: absolute;
  top: 40%;
  left: 50%;
}

.pageSection {
  padding: 16px;
}

.btnImport {
  margin:16px;
}
