.mainContainer {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 40px;
}

.description {
  cursor: pointer;
  padding: 16px 15px;
  text-transform: uppercase;
}

.createWidget {
  display: flex;
  flex-direction: row;
  align-self:flex-end;
  margin-bottom: 20px;
}

