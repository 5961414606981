.InfluencerActivities {
  padding: 15px 15px 15px 30px;
}

/* Add parent class for specificity, since this style is applied to an imported component. */
.InfluencerActivities .paper {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  padding: 0;
}
