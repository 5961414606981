.searchInput {
  flex-grow: 1;
  outline: none;
  border: 0 none;
  background-color: inherit;
}

.searchIcon {
  display: flex;
  margin-right: 5px;
}

.scroller {
  margin: 0 -20px -20px;
  max-height: 250px;
  overflow-y: auto;
}

.scroller > div {
  padding: 15px 20px 0;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  padding-right: 5px;
  padding-left: 5px;
}

.separator {
  margin: 12px -20px 0 !important;
  border: solid var(--gray-lite-4);
  border-width: 1px 0 0;
}

.description {
  display: flex;
  flex-grow: 1;
  padding-bottom: -10px;
}

.description.withSearch {
  margin-top: 20px;
}

.filterModalErrorStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 30px;
}

.filterModalErrorStateBaseStyle {
  line-height: 24px;
  font-family: $base-font-family;
  font-style: normal;
}

.filterModalErrorStateTitle {
  margin-bottom: 0;
  width: 169px;
  height: 24px;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}

.filterModalErrorStateText {
  margin-top: 0;
  color: var( --gray-80);
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.filterModalLoader {
  margin-top: 115px;
  margin-bottom: 110px;
}

.filterModalRetryNowButton {
  line-height: 24px;
  color: var(--light-blue);
  font-size: 14px;
}
