.SavedIndicator {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.text {
  /* Top margin is for minor vertical alignment tweak against icons */
  margin-top: 0.16667em;
  padding-left: 5px;
  line-height: 20px;
  font-size: 12px;
}
