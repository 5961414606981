.VideoEntry {
  box-sizing: border-box;
  border-top: 1px solid var(--gray-20);
  padding: 20px 0;
}

.videoThumbnail {
  display: block;
  margin-right: 30px;
  background-size: contain;
  width: 120px;
  height: 90px;
}

.videoStat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
}
