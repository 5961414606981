@import '../../../../../styles/colors.css';

.AccountManagementToggle {
  margin-bottom: 10px;
  padding: 4px 3px;
}

.AccountManagementToggle:hover {
  background-color: var(--gray-lite-5);
}

.label {
  align-content: center;
}
