.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.bodyContainer {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 125px);
}

.newsletterSideBarContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid var(--gray-20);
  background-color: var(--white);
  min-width: 20em;
  height: inherit;
}

.rightContent {
  flex: 1;
  background-color: var(--white);
  overflow-x: auto;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-top: 150px;
}

.emptyState .textContent {
  display: flex;
  flex-direction: column;
}

.emptyState .title {
  color: var(--gray-90);
  font-size: 24px;
  font-weight: bold;
}

.emptyState .message {
  margin-top: 16px;
  color: var(--gray-90);
  font-size: 16px;
}

.unsubscribedButton {
  margin-right: 8px;
}

.actions {
  display: flex;
  flex-direction: row;
}
