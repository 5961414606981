.gridContainer {
  display: flex;
  flex: 2 1 auto;
  flex-flow: column nowrap;
  width: calc(100vw - var(--analytics-list-sidebar));
  min-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.smallGridContainer {
  width: 100%;
}

.gridTable {
  display: block;
}

.category {
  display: flex;
}

.analyticRowLink {
  text-decoration: none;
  color: inherit;
}

.analyticRowLink:hover {
  cursor: pointer;
}

.analyticIcon {
  display: flex;
}

.iconAlignTop {
  margin-bottom: auto;
}

.actionButtons {
  display: flex;
  flex-direction: row;
}

.kebabMenu {
  display: block;
}

.starGoldActionButton {
  color: var(--gold);
}

.starActionButton:hover,
.starGoldActionButton:hover {
  color: var(--gold);
}

.checkedStar {
  margin-right: 1px;
  margin-left: 2px;
}

.checkbox {
  display: flex;
  padding-left: 10px;
}

.checkboxLargeDown {
  margin-bottom: auto;
}

.gridRowEllipsis {
  visibility: hidden;
}

.analyticRow:hover .gridRowEllipsis {
  visibility: visible;
}

.sharedIcon {
  margin-left: -25px;
  padding-right: 10px;
}

.truncable {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multipleDescriptionColumn {
  display: flex;
  flex-direction: column;
}

.multipleDescriptionColumn span {
  margin-bottom: 5px;
  overflow: inherit;
  text-overflow: inherit;
  white-space: inherit;
}

.titleText {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.titleName {
  font-weight: var(--bold);
}

.analyticRow:first-of-type > div {
  margin-top: 40px;
}

.shortButton {
  padding-top: 0;
  padding-bottom: 0;
}

.searchesDeleted {
  line-height: 16px;
  color: #de4543;
  font-family: var(--primary-font);
  font-weight: 700;
}

.searchesDeletedDropdown {
  min-width: 75px;
  text-align: left;
}

.searchesItem {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.searchTitleAndIcon {
  margin-right: 10px;
}
