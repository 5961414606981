.segmentContainer {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--gray-20);
  width: 300px;
  min-width: 300px;
  height: 100%;
  overflow: auto;
}
.metricsTitleContainer {
  border-top: 1px solid var(--white);
}
.conversionsTitleContainer,
.metricsTitleContainer,
.segmentTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--gray-10);
  padding-right: 25px;
  padding-left: 16px;
  height: 32px;
  text-transform: uppercase;
}
.allAudienceContainer {
  display: flex;
  align-items: center;
  box-shadow: inset 5px 0px 0px var(--light-teal);
  background-color: var(--gray-10);
  padding-right: 25px;
  padding-left: 20px;
  height: 100px;
}
.allAudienceIcon {
  margin-right: 15px;
  width: 48px;
  height: 48px;
}
.allAudienceTitle {
  margin-bottom: 4px;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: bold;
}
.allAudienceMetrics > div {
  margin: 0;
}
.toggleIcon {
  transition: transform 300ms var(--linear);
  cursor: pointer;
}
.topLinesInfoText {
  padding: 16px;
  line-height: 16px;
  color: var(--gray-80);
  font-size: 12px;
}
.topMetricContainer {
  margin: 0;
  padding: 0 16px;
  list-style: none;
}
.topMetricRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.metricName {
  line-height: 24px;
  color: var(--gray-90);
}
.wordText {
  margin: 0 2px;
}
.conversionMetricsContainer {
  margin: 0;
  padding: 0 16px;
  list-style: none;
}
.conversionRow span {
  display: block;
}
.conversionRow span + span {
  margin-top: 8px;
}
.conversionRateText {
  line-height: 16px;
  color: var(--gray-80);
  font-size: 12px;
}
