.container {
  box-sizing: border-box;
  display: inherit;
  padding: 16px 16px 16px 32px;
  width: 100%;
}

.noVerticalSpace {
  padding-top: 0px;
  padding-bottom: 0px;
}
