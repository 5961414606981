.reportHeaderContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--white);
  width: 100%;
  min-height: 57px;
}

.reportHeaderLeftMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.reportHeaderRightMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}

.reportHeaderRightMenu > * {
  margin: 0 5px;
}

.actionsMenuButton {
  margin-right: 10px;
  width: auto;
}

.actionsMenuButton button {
  padding: 0 7px;
}

.addItemToSlide {
  display: flex;
  justify-content: center;
  width: 62px;
  height: 40px;
}

.tooltip {
  color: var(--gray-80);
}