.content {
  background: white;
  padding: 10px;
}

.tabGroupWrapper {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: auto;
  max-height: 100%;
}

.tabGroupMenu {
  flex: 0 0 auto;
  border-bottom: 1px solid var(--gray-20);
}

.tabGroupContent {
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
}

.loadingBar {
  margin-bottom: 8px;
}

.social {
  margin-bottom: 16px;
}

.inAppProductMarketingArticlesText {
  padding: 16px 0;
  color: var(--gray-90);
}

.viewAllLinkDisabled {
  pointer-events: none;
}