.selectionListEntry {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border: 0;
  background-color: transparent;
  padding: 10px 16px;
  width: 100%;
  text-align: left;
}

.selectionListEntry:hover,
.selectionListEntry:focus,
.selectionListEntry:active {
  outline: 0 none transparent;
  background-color: var(--gray-10);
  cursor: pointer;
}

.selectionListEntryDisabled {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  opacity: 0.3;
  border: 0;
  background-color: transparent;
  cursor: default;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.selectionListEntryUnavailable {
  opacity: 0.5;
}

.selectionListEntrySingle {
  margin-right: 12px;
}

.selectionListEntryIcon {
  margin-top: 2px;
  margin-right: 8px;
}

.selectionListEntryInfoIcon {
  margin: 0 10px 0 10px;
}

.selectionListEntryData {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  min-width: 0;
  color: var(--gray-90);
}

.selectionListEntryTitle {
  max-width: 216px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  pointer-events: none;
}
