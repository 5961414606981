.accordion {
  margin: 5px -10px -10px -10px;
}

.advancedFiltersCountContainer {
  display: flex;
}

.advancedFiltersCount {
  width: 20px;
  font-weight: 700;
}
