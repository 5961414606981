.insideLink {
  display: flex;
  width: 100%;
}

.iconStart {
  flex: 0 0 auto;
  padding: 2%;
}

.linkText {
  flex: 1 1 auto;
  padding: 2%;
  min-width: 0;
}

.sideBarButton {
  border: 0;
  border-left: 5px solid transparent;
  background-color: white;
  cursor: pointer;
  padding: 20px 24px 20px 19px;
  width: 100%;
  text-align: left;
}

.selectedMenuOption,
.selectedMenuOption:hover {
  border-left: 5px solid var(--teal-lite-2);
  background-color: var(--gray-20);
}
