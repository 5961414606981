.ReportStatusBar {
  border-bottom: 1px solid #ebeced;
  background: var(--white);
  padding: 8px 16px 3px;
}

.toggleWrapper {
  margin-right: 8px;
}

.linkWrapper {
  margin-left: 16px;
}

.stateWrapper {
  display: inline-flex;
  align-items: center;
}
