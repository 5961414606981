.contentSection {
  padding-bottom: 20px;
}

.fieldText {
  margin: 6px 0;
}

.widgetContent {
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  max-height: 240px;
  overflow-y: auto;
}

.widgetSection {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 32px;

}
.widgetSection:hover {
  background-color: var(--gray-20);
}

.widgetTitle {
  margin-left: 8px;
}

.widgetAddTextWidgetToSlidesControl {
  flex: 1;
}
