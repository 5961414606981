@import '../../../../../styles/colors.css';

.Contacts {
  margin-top: 0;
  margin-bottom: 0.5em;
  word-wrap: break-word;
}

.centered {
  justify-content: center;
  text-align: center;
}

.contactMethods {
  display: inline-flex;
}

.emailWithClipboard {
  padding-left: 3px;
}

.phoneLink,
.twitterLink {
  text-decoration: none;
  color: var(--color-link);
}

.twitterLinkDisabled {
  display: inline-block;
  color: var(--gray-40);
  pointer-events: none;
}

.twitterLinkDisabled::first-letter {
  text-transform: uppercase;
}
