.modalContent {
  max-width: 600px;
  user-select: none;
}

.modalExportSubTitle {
  color: var(--black);
}

.modalExportInfoText {
  line-height: 24px;
  color: var(--gray-80);
}

.checkboxRow {
  color: var(--gray-90);
}

.radioButtonContainer .radioButtonLabel,
.checkBoxLabel {
  line-height: 24px;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: normal;
}
