.actionsContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  min-height: 56px;
}

.actionsListWrapper {
  align-items: center;
}

.mediaItem {
  margin-right: 8px;
}

.dropdownButton {
  padding-left: 0px;
}

.searchChartDefaultColor {
  margin-right: 4px;
  width: 24px;
  height: 24px;
}
