@import '../../../../../styles/variables.module.css';
@import '../../../../../styles/sizes.css';

.container {
  box-sizing: border-box;
  display: block;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  min-height: 100px;
  color: var(--gray-90);
}

.selectedContainer {
  border: solid 1px var(--brandColor);
  box-shadow: none;
  background-color: var(--base-selected);
}

.cardHeader {
  display: flex;
  cursor: pointer;
  padding: var(--impact-audience-card-padding);
  height: auto;
  min-height: calc(100px - calc(var(--impact-audience-card-padding) * 2));
}

.selectedContainer .cardHeader {
  padding: calc(
    var(--impact-audience-card-padding) - 1px
  ); /* 1px is the borders when is selected */
}

.container:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.radioButtonContainer {
  margin-left: 2px;
}

.avatarContainer {
  margin-right: 16px;
}

.descriptionContainer {
  flex: 1.6;
}

.audienceName {
  margin: 0px;
  line-height: 36px;
  font-size: 18px;
}

.audiencePercentage {
  margin-top: 8px;
}

.uniqueViewsContainer {
  flex: 1;
  padding: 14px 0px;
}

.uniqueViewsTitle {
  display: block;
  line-height: 16px;
  font-size: 12px;
}

.uniqueViewsValue {
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
}

.avatar {
  width: 68px;
  height: 68px;
}

.cardContent {
  transition: max-height 0.1s ease-in-out;
  opacity: 0;
  cursor: pointer;
  padding: 0 var(--impact-audience-card-padding);
  max-height: 0;
  overflow: hidden;
}

.cardContentExpanded {
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
  opacity: 1;
  border-top: 1px solid var(--gray-20);
  padding: var(--impact-audience-card-padding);
  height: auto;
  max-height: 100%;
  overflow: visible;
}

@media only screen and (min-width: 1024px) {
  .avatarContainer {
    margin-right: 32px;
  }

  .uniqueViewsContainer {
    flex: 0.3;
  }

  .descriptionContainer {
    flex: 1;
  }
}
@media only screen and (min-width: 1440px) {
  .uniqueViewsContainer {
    flex: 0.5;
  }
}

@media only screen and (min-width: 1920px) {
  .uniqueViewsContainer {
    flex: 0.3;
  }
}
