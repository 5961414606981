.tagsListContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.tagListSearchBar {
  flex-shrink: 0;
  margin: 16px 16px;
  border: 1px var(--gray-40) solid;
  border-bottom: 1px var(--gray-20) solid;
  border-radius: 2px;
  padding: 8px;
}

.tagListDivider {
  display: block;
  clear: both;
  border: 0;
  background-color: var(--gray-20);
  width: 96%;
  height: 1px;
}

.tagsList {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
}

.tagListEntries {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
}

.tagListTitle {
  margin: 15px 0 0 12px;
  color: var(--gray-80);
  font-size: 12px;
}

.tagEntryRadioButton {
  margin-right: 12px;
}

.tagEntryIcon {
  margin: 0 10px 0 10px;
}

.tagEntryData {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  min-width: 0;
}

.tagEntryTitle {
  cursor: default;
  max-width: 216px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.tagEntry {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border: 0;
  background-color: transparent;
  padding: 10px 16px;
  width: 100%;
  text-align: left;
}

.tagEntry:hover,
.tagEntry:focus,
.tagEntry:active {
  outline: 0 none transparent;
  background-color: var(--gray-10);
  cursor: pointer;
}

.tagEntryDisabled {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  opacity: 0.3;
  border: 0;
  background-color: transparent;
  cursor: default;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.tagCustomListTitle {
  padding: 20px 0px 0px 16px;
  font-size: 12px;
}
