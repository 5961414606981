.TweetsFeedRoutes {
  max-height: 75vh;
}

.TweetsFeed .tweetsFeedWrapper {
  border-top: 1px solid var(--gray-20);
}

.overflowXHidden {
  overflow-x: hidden;
}

.overflowYScroll {
  overflow-y: scroll;
}

.maxHeight550 {
  max-height: 550px;
}

.noScrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.noScrollbar::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.previewTitle {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  padding-bottom: 16px;
}

.actionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.previewActionBarMargin {
  margin: 16px 0 16px 0;
}

.emptyPreviewStateWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.skeletonWrapper {
  margin: 20px 0 20px 0;
}

.skeletonHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.skeletonHeaderAvatarAndInfo {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.skeletonHeaderAvatar {
  margin: 0 8px 0 0;
}

.skeletonHeaderInfo {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: space-around;
  height: 38px;
}

.skeletonBody {
  margin-bottom: 15px;
}

.skeletonFooter {
  display: flex;
  justify-content: space-between;
}

.skeletonBar {
  margin-bottom: 8px;
}

.tabStyle {
  border-bottom: 1px solid var(--gray-20);
}

.profileSearchTitle {
  padding: 15px 0;
}

.profileSearchTitleText {
  border-bottom: 2px solid var(--light-blue);
  padding: 15px 0;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: 800;
}
