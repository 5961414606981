@import '../../../../../styles/colors.css';

.ContactInformation {
  display: flex;
  line-height: 1.8;
}

.ContactInformation > div:first-of-type {
  width: 100%;
}

.ContactInformation:not(:last-child) {
  margin-bottom: 16px;
}

.icon {
  margin-right: 10px;
}

.label {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--gray-90);
  font-size: 12px;
  font-weight: bold;
}

.description {
  color: var(--gray-80);
  font-size: 14px;
}

.link {
  text-decoration: none;
  color: var(--color-link);
}
