.formWrapper {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 10px;
  background-color: var(--white);
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeButton {
  padding: 0 10px;
}

.outletTopicsInputDiv {
  margin-top: -25px;
}

.additionalInfoFormWrapper {
  padding-top: 20px;
}

.flexArea {
  display: flex;
  flex-direction: column;
}

.flexAddressArea {
  display: flex;
  flex-direction: column;
}

.flexInput {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.informationHeader {
  padding-top: 0;
  padding-bottom: 0;
  color: var(--gray-80);
}

.informationHeader:hover {
  color: var(--gray-80);
}

.information {
  padding: 10px;
}

.outletInformation {
  margin-top: 15px;
  padding: 10px;
}

.callout.callout {
  margin-bottom: 10px;
  padding: 15px;
  font-weight: bold;
}

.callout:last-of-type {
  margin-bottom: 0;
}

.matches {
  position: relative;
  align-items: center;
  cursor: pointer;
}

.matchesPrompt {
  display: inline-block;
  max-width: 90%;
}

.matchesArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  padding: 3px 0 0;
  text-align: right;
}

.footer {
  text-align: right;
}

.footerButton {
  margin-left: 8px;
}

.mediaType {
  border: none !important;
}

.frequency {
  flex-basis: 30%;
}

.frequencies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.errorColor {
  color: var(--error);
}
