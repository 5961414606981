.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 24px 16px;
}

.svg {
  height: 200px;
}

.body {
  text-align: center;
}
