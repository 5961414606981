.modalBody {
  display: flex;
  height: 700px;
  clip-path: inset(0);
}

.loaderContainer {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}

.searchBuilderModalDepthWrapper {
  z-index: 1500;
}

.searchBuilderModalDepthWrapper + :global(.tk-modal-depth-wrapper) {
  z-index: 1800;
}
