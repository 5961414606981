.connectionGroupTitle {
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  
}

.connectionGroup{
  display: flex;
  flex-direction: column;
  margin-top: -8px;
  margin-bottom: -8px;
}

.connectionGroup button {
  width: 100%;
}

.connectionGroup p {
  width: auto;
  max-width: calc(100% - 30px);
}

.connectionGroup p, .connectionGroup svg {
  color: var(--light-blue);
}

.modalWrap .modal :global(.tk-modal__header) {
  padding: 11.1px;
}

.modalWrap .modal :global(.tk-modal__dialog) {
  width: 580px;
}
