.MetricsSideTray {
  visibility: visible;
}

.titleWrapper {
  align-items: center;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
}

.metricsText {
  display: flex;
  align-items: center;
}

.tweetsDisplay {
  padding: 10px;
}

.positiveSentimentColor {
  color: var(--charting-green-base);
}

.negativeSentimentColor {
  color: var(--charting-red-base);
}

.neutralSentimentColor {
  color: var(--gray-40);
}

.undeterminedSentimentColor {
  color: var(--gray-60);
}

.skeletonLoader {
  padding: 15px;
}
