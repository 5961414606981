@import '../../../../../styles/colors.css';

.alsoFollows {
  border-top: 1px solid var(--gray-20);
  padding: 16px 0;
  color: var(--gray);
  font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
}

.alsoFollows .socialLink {
  text-decoration: none;
  color: var(--color-link);
}

.alsoFollows .followsInvisibleLink {
  text-decoration: none;
  color: inherit;
}

.alsoFollows .followsInfo {
  padding: 10px 0;
}

.alsoFollows .followsIcon {
  cursor: pointer;
  padding-left: 20px;
}

.topItemName {
  @apply t-w-full t-font-bold t-text-base;
  color: var(--gray);
  font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
}

.topItemPercent {
  @apply t-font-bold t-text-lg t-pl-6;
  color: var(--gray);
  font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
}

.topItemPercentBarFill {
  background: #63bf52 linear-gradient(90deg, #63bf52, #0092c2);
}
