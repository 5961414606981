.draggableHeader {
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background-color: var(--gray-20);
  width: 100%;
  height: 30px;
}

.draggableHeader:hover {
  visibility: visible;
  cursor: pointer;
}

.editWidgetContainer:hover {
  cursor: pointer;
}

.editWidgetContainer:hover ~ .draggableHeader {
  visibility: visible;
}
