.dropdownTitle {
  display: flex;
  position: relative;
  justify-content: space-between;
  transition: all .1s ease-in-out;
  border: 1px solid var(--gray-40);
  border-radius: 2px;
  background-color: white;
  padding: 8px;
  width: 100%;
  line-height: 20px;
  color: var(--gray-80); 
  font-size: 14px;
} 

.dropdownTitle:hover {
  border-color: var(--gray-80);
}

.modalBody {
  margin-bottom: 10px
}

.dropdown {
  position: absolute;
  top: 70%;
  border-radius: 2px;
  background: var(--white);
  width: 93.8%; 
}

.dropdownMenu {
  border: 1px solid var(--gray-40);
}

.chevronDown {
  height: 20px;
}

.permissionOption {
  padding: 5px;
}
