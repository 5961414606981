.header {
  display: flex;
  user-select: none;
  cursor: pointer;
}

.headerContent {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 150ms cubic-bezier(0.25, 0.25, 0.75, 0.75);
  margin-right: 16px;
}

.expandedArrow {
  transform: rotate(-180deg);
}
