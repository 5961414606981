.rangeFilter {
  padding: 20px 15px 30px 15px;
}

.selectGroup {
  display: flex;
  padding-top: 5px;
}

.select {
  width: 100%;
}

.select:nth-child(2) {
  margin-left: 10px;
}

.button {
  border-radius: 2px;
  padding-right: 15px;
  padding-left: 10px;
}
