.titleBar {
  display: flex;
}

.titleBar .view-title-bar .view-title-bar-right-content {
  width: 30%;
}

.dropdownMenu {
  z-index: 20;
}

.sidebar {
  flex-direction: column;
}

@media only screen and (min-width: 600px) {
  .kebabButton {
    display: none;
  }

  .normalButton {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .normalButton {
    display: none;
  }
}

.listSurround {
  display: flex;
}

.bodyArea {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.sideBar {
  display: grid;
  flex-basis: 20%;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

.listArea {
  height: 100%;
}

.savedListTable {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  width: 100%;
}

.gridArea {
  flex-basis: 80%;
}

.centerLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100%;
}
