.accountListContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.accountList {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
}

.accountListEntries {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
}

.accountListEntriesTitle {
  padding: 16px 16px 8px;
  color: var(--gray-80);
  font-size: 14px;
}

.accountNoEntries {
  margin: 40px 16px 0;
  text-align: center;
}
