@import '../../../styles/colors.css';
@import '../../../styles/variables.module.css';

.divider {
  border-bottom: var(--divider-border);
}

.articleDivider {
  margin-top: 16px;
}

.listDivider {
  margin-top: 24px;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container {
  width: 1174px;
  min-height: 700px;
}

.defaultInput {
  padding: 0px 8px;
  width: 311px;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
}

.marginButton {
  margin-left: 16px;
}

.pseudonymContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
}
.influencerContainer {
  display: flex;
  flex-direction: row;
}

.head.head {
  margin-bottom: 24px;
}

.title {
  display: flex;
  align-items: center;
}

.actions.actions {
  display: block;
}

.actions > * {
  margin-right: 9px;
}

.marginActions {
  margin-left: 16.5px;
}

.actions > .inlineLoader {
  display: inline-block;
  margin-bottom: 0;
  margin-left: 20px;
  vertical-align: middle;
}

.actions > :last-child {
  margin-right: 0;
}

.blockLoader {
  margin-bottom: 16px;
  width: 311px;
}

.article {
  margin-bottom: 26px;
}

.articleTitle {
  margin-bottom: 4px;
  font-weight: bold;
}

.articleLink {
  text-decoration: none;
  color: var(--color-link);
}

.articleLink:hover,
.articleLink:focus {
  color: var(--color-link-active);
}

.pseudonymsSection {
  margin-bottom: 24px;
}

.pseudonymTitle {
  line-height: 24px;
  color: var(--gray-90);
  font-weight: bold;
}

.groupMargin {
  margin-left: 5px;
}

.groupListMargin {
  margin: 0 8px;
}

.groupTitle {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.pseudonymsHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: var(--gray-80);
  font-size: 12px;
  font-weight: bold;
}

.pseudonym {
  margin-bottom: 8px;
}

.pseudonymLabel {
  margin-left: 7px;
  font-size: 14px;
}

.influencer {
  margin-bottom: 24px;
}

.influencerName {
  margin-bottom: 8px;
  font-weight: bold;
}

.influencerContacts {
  margin-bottom: 8px;
}

.callout.callout {
  margin-bottom: 24px;
}
