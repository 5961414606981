.container {
  display: flex;
}

.iconContainer {
  flex-grow: 1;
  margin-right: 10px;
}

.textContainer {
  flex-grow: 5;
  text-align: left;
}

.textContainer > div {
  padding-bottom: 20px;
  line-height: 24px;
  font-size: 15px;
}

.linkButtonContent {
  display: flex;
  align-items: center;
}

.linkButtonContent > svg[class*='arrowRight2'] {
  margin-right: 10px;
}

.linkButtonContent > svg[class*='plus'] {
  margin-right: 5px;
}
