.container {
  margin-bottom: 16px;
}

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  box-shadow: 0 1px 0 0 var(--gray-lite-4);
  background-color: white;
  padding: 24px 16px 16px 32px;
}

.header.headerLong {
  padding-bottom: 56px;
}

.articleDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--gray-80);
  font-size: 14px;
}

.headerActionButton {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--gray-lite-3);
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: var(--gray-80);
}

.headerActionButton:hover {
  border: solid 1px var(--gray-60);
  background-color: var(--gray-20);
  color: var(--gray-90);
}

.articleContent {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.title {
  display: block;
  margin-top: 32px;
  max-width: 665px;
  line-height: 1.25;
  color: var(--light-blue);
  font-size: 32px;
  font-weight: bold;
}

.title:hover {
  color: var(--light-blue-hover);
}

.link {
  text-decoration: none;
}

.detailBreadcrumb {
  display: flex;
  align-items: center;
  color: var(--gray-80);
}

.detailBreadcrumb + .detailBreadcrumb:before {
  margin-right: 4px;
  margin-left: 4px;
  cursor: auto;
  color: var(--gray-80);
  content: '•';
}

.outletName {
  color: var(--gray-90);
}

.textImageContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.image {
  margin-left: 32px;
  width: auto;
  max-width: 350px;
  height: 150px;
}

.text {
  max-width: 395px;
  line-height: 1.5;
  color: var(--gray-90);
  font-size: 16px;
}

.text.textLong {
  max-width: 550px;
}

.outletAuthorInfo {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;
}

.avatar {
  margin-right: 8px;
}

.outletContainer {
  display: flex;
  align-items: center;
}

.availableInfluencer {
  color: var(--light-blue);
}

.availableInfluencer:hover {
  cursor: pointer;
}

.downloadIconButton {
  margin-right: 16px;
  padding: 0;
}

.downloadIcon {
  fill: var(--gray-80);
}

.removeUrlLabel {
  margin-left: 15px;
}

@media screen and (min-width: 1440px) {
  .image {
    height: 190px;
  }

  .title {
    max-width: 1050px;
  }

  .text,
  .text.textLong {
    max-width: 620px;
  }
}

@media screen and (min-width: 1920px) {
  .text,
  .text.textLong {
    max-width: 880px;
  }

  .title {
    max-width: 1230px;
  }
}
