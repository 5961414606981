.containerPhrase {
  display: grid;
  grid-template-columns: 300px auto; /** METADATA | LANGUAGE GROPS */
  column-gap: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 #dddfdf;
  background-color: #fff;
  padding: 5px 25px; /** see comment for .containerPhrase about the low top/bottom value */
}
.containerPhrase > * {
  /**
   * need this so the border used as a separator sticks up
   * closer to the edge of the .containerPhrase
   */
  padding-top: 15px;
}

.containerLanguage {
  display: grid;
  grid-template-columns: 100px auto; /** LANGUAGE | REGIONS */
  column-gap: 8px;
  margin-bottom: 28px;
}
.containerLanguage:last-child {
  margin-bottom: 0;
}

.containerMetaData {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.labelDescription {
  padding-top: 10px;
  white-space: normal; /** counteract the ellipsis logic needed on the column as a whole for KEY */
}

.listTranslations {
  margin: 0;
  padding-left: 0;
}
