.template {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  cursor: pointer;
  padding: 10px;
}

.template:hover {
  background-color: var(--gray-20);
}

.templateText {
  display: flex;
  flex-direction: column;
}

.imagePlaceholder {
  margin-right: 30px;
  border: 1px solid var(--gray-lite-3);
  background-color: var(--white);
  width: 160px;
  height: 120px;
}

.templateTitle {
  margin: 15px 0;
}
