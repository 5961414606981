.wizardContainer {
  display: grid;
  grid-template-columns: 5fr minmax(300px, 0.8fr);
  grid-auto-columns: min-content;
  height: 100%;
}

.stepDescription {
  margin-bottom: 30px;
}

.audienceWizardStepBody {
  flex-grow: 1;
  padding: 50px 24px 24px;
  color: var(--gray-90);
}

.audienceWizardStepBody h1 {
  margin: 0px;
  margin-bottom: 16px;
  line-height: 36px;
  letter-spacing: normal;
  color: var(--gray-90);
  font-size: 24px;
  font-stretch: normal;
  font-weight: bold;
  font-style: normal;
}

.audienceWizardStepBody h2 {
  margin: 0px;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--gray-90);
  font-size: 18px;
  font-stretch: normal;
  font-weight: bold;
  font-style: normal;
}

.audienceWizardStepBody h3 {
  margin: 0px;
  line-height: 24px;
  font-size: 14px;
}

.audienceWizardStepBody .audienceWizardStepHeader {
  margin-bottom: 24px;
}

.audienceWizardStepDescription {
  width: 420px;
}

.audienceWizardStepBody
.audienceWizardStepHeader
.audienceWizardStepDescription {
  line-height: 1.71;
  letter-spacing: normal;
  font-size: 14px;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
}

.audienceWizardStepBody .audienceWizardStepContent {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  padding: 16px;
  width: auto;
}

.audienceWizardStepBody
.audienceWizardStepContent
.audienceWizardStepContentHeader {
  border-bottom: 1px solid var(--gray-20);
  width: auto;
}

@media only screen and (min-width: 1024px) {
  .audienceWizardStepBody {
    padding: 50px;
  }

  .audienceWizardStepDescription {
    width: 624px;
  }
}

@media only screen and (min-width: 1440px) {
  .audienceWizardStepBody {
    padding: 78px;
  }

  .audienceWizardStepBody .audienceWizardStepContent {
    padding: 32px;
  }

  .audienceWizardStepDescription {
    width: 984px;
  }
}

@media only screen and (min-width: 1920px) {
  .audienceWizardStepBody {
    padding: 72px;
  }
}
