.formContainer {
  margin: 5px 15px;
}

.media {
  display: flex;
  align-items: center;
}

.mediaItem {
  margin-right: 8px;
}

.pubStatusHidden {
  visibility: hidden;
}
