.wrapper {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

.title {
  margin-bottom: 15px;
}

.seeMore {
  margin-top: 10px;
}

.pillType {
  display: flex;
  align-items: flex-start;
  color: var(--gray-80);
  font-weight: normal;
}

.pillChild {
  display: flex;
  flex-direction: column;
}

.suggestedPill {
  margin: 5px;
}

.suggestedPill:hover .pillType {
  color: var(--white);
}

.suggestionName {
  font-weight: var(--bold);
}
