@import '../../../styles/variables.module.css';

.Highlighter {
  background-color: var(--highlighter-bg-color);
  padding: 3px 0;
  color: inherit;
}

.miss {
  padding: 3px 0 3px;
}

.miss:empty {
  background-color: var(--highlighter-bg-color);
}
