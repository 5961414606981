@import '../../../../styles/colors.css';
.filtersContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  padding: 0 16px;
  min-height: 48px;
}

.containerWrapper {
  margin: 8px 0;
}

.mediaItem {
  margin-right: 8px;
}
.toggle {
  margin-right: 15px;
}

.pill span {
  font-weight: bold !important;
}

.qualityInfoTooltip {
  width: 440px;
}

.qualityTooltipArrow {
  --tooltipBackground: var(--gray-90) !important;
  display: block;
}

.qualityInfoTooltip[data-popper-placement*='right'] :global(.tooltip-arrow) {
  margin-left: -1.1rem !important;
}

.qualityInfoTooltip[data-popper-placement*='left'] :global(.tooltip-arrow) {
  margin-right: -1.1rem !important;
}

@media only screen and (max-width: 800px) {
  .qualityInfoTooltip {
    width: 300px;
  }
}
