.tilesContainer {
  padding-top: 20px;
}

.title {
  margin-left: 10px;
  height: 20px;
  font-size: 14px;
}

.analyticsRecentlyViewedTile {
  display: inline-block;
  flex: 0;
  margin: 0 10px 15px;
}

.mediumUpContainer {
  /* with sidebar */
  width: calc(100vw - var(--analytics-list-sidebar));
}

.smallContainer {
  /* no sidebar */
  width: calc(100vw);
}

.tiles {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.body {
  display: flex;
  flex-direction: column;
}

.fluidGrid {
  margin-top: 20px;
  padding-top: 20px;
}

.tileIcon {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: -10px;
  left: 20px;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 8px;
  width: 40px;
  height: 40px;
  color: #677078;
}

.footer {
  margin-top: auto;
}

.sticky {
  position: fixed;
  top: 125px;
  z-index: 800;
}

.noAnalytics {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  width: 100%;
}
