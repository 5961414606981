.container {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border: 1px solid var(--gray-20);
  border-top: unset;
  border-bottom: unset;
  background-color: white;
  width: 100%;
  min-width: var(--impact-single-url-sidebar-width);
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-20);
  /* 16px is top(8px) and bottom(8px) paddings */
  padding: 8px 21px 8px 16px;
  height: calc(56px - 16px);
  min-height: calc(56px - 16px);
}

.headerTitle {
  line-height: 1.5;
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}

.headerSubTitle {
  line-height: 1.33;
  color: var(--battleship-grey);
  font-size: 12px;
}

.loader {
  margin-right: auto;
  margin-left: auto;
  padding-top: 140px;
}

.list {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.list,
.row {
  margin: 0;
  padding: 0;
  width: 100%;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.list li {
  border-bottom: 1px solid var(--gray-20);
  height: fit-content;
  max-height: 157px;
}

.dateFilter {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--gray-lite-6);
  padding: 8px 16px;
  height: 50px;
  min-height: 50px;
}

.datePicker {
  right: 0;
  left: 10px;
  z-index: 750;
  max-width: 631px;
}

.infiniteScrollLoading {
  margin-top: 10px;
  margin-bottom: 10px;
}
