.titlePosition {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.articleSearchContainer {
  margin-top: 20px;
}

.resultGrid {
  background-color: var(--gray-10);
  padding: 15px;
  width: 49rem;
  height: 35rem;
  overflow-y: auto;
}

.itemAligment {
  flex-basis: 282px;
  margin-right: 15px;
}

.resultSpacing {
  margin-right: 1rem;
}

.buttonAlign {
  display: flex;
  align-items: flex-end !important;
}

.searchButton {
  justify-content: center;
  margin: auto 0 0;
  padding-right: 35px;
  padding-left: 35px;
  height: 70%;
}

.socialRefreshButton {
  justify-content: center;
  margin: auto 0px 0 45px;
  padding-right: 35px;
  padding-left: 35px;
  width: 14rem;
  height: 70%;
}
