.header {
  background-color: var(--white);
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body {
  width: 100%;
}

.button {
  padding: 0 10px;
}
