.drilldownSidebar {
  border-right: 1px solid var(--gray-20);
}

.filterContent {
  padding: 20px;
}

.filterHeader {
  height: 40px;
  background-color: var(--gray-10);
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: flex-start;
}

.filterClear {
  margin-left: auto;
}

.categoryWarning {
  font-size: 12px;
}

.toggleFilter {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.filterPill {
  margin-bottom: 15px;
}

.pillCross {
  height: 25px;
  width: 25px;
  color: var(--white);
  background-color: var(--light-teal-hover);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.toggleText {
  display: flex;
  flex-direction: column;
}

.toggle {
  margin-left: auto;
  margin-top: 10px;
}
