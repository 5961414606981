.impactReportBar {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  border-radius: 6px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.status {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status > * {
  margin-right: 15px;
}
