.Layout :hover {
  z-index: 10;
}

.Layout :global(.react-resizable-handle) {
  z-index: 11;
}

.Layout :global(.react-grid-item.react-grid-placeholder) {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 1;
  border: 2px dashed #50a7e0;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.05);
}
