.Article {
  border-bottom: 1px solid var(--gray-20);
  padding: 10px 0;
}

.Article:last-of-type {
  border-bottom: 0;
}

.Article .heading {
  line-height: calc(20 / 14);
}

.duplicateEntries {
  margin-top: 4px;
  border-bottom: 1px solid var(--gray-20);
}

.duplicateEntry {
  border-top: 1px solid var(--gray-20);
  padding: 8px 0;
}

.showDupes {
  margin-bottom: 4px;
}

.showDupesIcon.showDupesIcon {
  color: var(--gray);
}

.viewArticlesLink {
  font-size: 12px;
}

.Article a:link {
  text-decoration: none;
}

.ArticleImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 96px;
  height: 80px;
}
