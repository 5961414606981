.textContainer {
  width: 670px;
}

.title {
  color: var(--gray-90);
  font-size: 32px;
  font-weight: 700;
}

.simpleText {
  display: flex;
  margin: 1em 0;
  line-height: 24px;
  color: var(--gray-80);
  font-size: 14px;
  font-weight: normal;
}

.customText {
  display: flex;
  margin: 1em 0 32px 0 !important;
  line-height: 24px;
  color: var(--gray-80);
  font-size: 14px;
  font-weight: normal;
}

.firstText {
  margin: 1em 0;
}

.divider {
  left: 16px;
  border: 0px;
  background: var(--gray-20);
  width: 100%;
  height: 1px;
}

.buttonContainer {
  display: flex;
}

.importButton {
  margin-top: 20px;
}
