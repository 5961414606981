:root {
  --circle-radius: 16px;
  --upper-line-height: 20px;
  --lines-width: 4px;
  --wizard-progress-padding: 20px;
  --wizard-progress-side-padding: calc(var(--wizard-progress-padding) * 2);
}

.audienceWizardProgressTitle {
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}

.audienceWizardProgress {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: var(--white);
  padding: var(--wizard-progress-padding);
  width: 300px;
  min-width: 300px;
}

.audienceWizardProgressStepData {
  margin-left: 10px;
  color: var(--gray-80);
  font-weight: normal;
}
.audienceWizardProgressStepValue {
  margin-bottom: 10px;
}

.audienceWizardProgressStepValue > span {
  display: inline-block;
  max-width: 215px;
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row {
  display: flex;
  width: 100%;
  height: auto;
}

.column {
  overflow: hidden;
}

.left {
  /* IE 10-11 ignore calc() functions used in flex shorthand declarations. */
  flex-basis: calc(var(--circle-radius) * 4);
}

.right {
  flex: 100%;
  border-bottom: 1px solid #dddddd;
  padding-bottom: var(--circle-radius);
}

.circle ,.circleError{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--circle-radius);
  background-color: var(--gray-40);
  width: calc(var(--circle-radius) * 2);
  height: calc(var(--circle-radius) * 2);
  text-align: center;
  line-height: calc(var(--circle-radius) * 2);
  color: var(--white);
  font-size: 14px;
  font-weight: bold;
}

.circleError{
  border: 0.6px solid darkred;
  background-color: #fbdddd ;
}

.line {
  margin-left: calc(var(--circle-radius) - calc(var(--lines-width) / 2));
  background-color: var(--gray-40);
  width: var(--lines-width);
}

.upperLine {
  height: var(--upper-line-height);
}

.active .circle,
.active .upperLine {
  background-color: var(--light-teal) !important;
}

.lowerLine {
  height: 100%;
}

.lowerErrorLine{
  background-color: rgb(176, 6, 6) !important;
  height: 100%;
}

.upperErrorLine{
  background-color: rgb(176, 6, 6) !important;
  height: var(--upper-line-height);
}


.completed .circle,
.completed .upperLine,
.completed .lowerLine {
  background-color: var(--light-teal) !important;
}

.hidden {
  opacity: 0;
}

.stepTitle {
  margin-top: var(--upper-line-height);
  line-height: calc(var(--circle-radius) * 2);
}

.stepTitle.activeStep {
  color: var(--gray-90);
  font-weight: bold;
}

.noBorder {
  border-bottom: none;
}

.bottomBorder {
  border-bottom: 1px solid #dddddd;
}

.audienceWizardProgressNote {
  margin-top: auto;
  margin-bottom: -10px;
  border-top: 1px solid #dddddd;
  padding-top: 5px;
}