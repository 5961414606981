.showPropertiesPage {
  box-sizing: border-box;
  max-width: var(--form-max-width);
}

.headerPage {
  padding: 20px 0 25px;
  text-align: center;
}

.linkClick {
  padding: 0;
  font-weight: bold;
}

.linkClick:hover {
  color: var(--light-blue-hover);
}

.propertyCell {
  display: flex;
  padding-bottom: 15px;
}

.propertyListCell {
  display: flex;
  padding-bottom: 5px;
}

.propertyLabel {
  width: 50%;
  text-align: right;
  font-weight: bold;
}

.propertyList {
  margin-top: 5px;
  padding-left: 15px;
}

.propertyValue {
  margin-left: 4%;
  width: 50%;
}

.buttonHolder {
  display: flex;
  padding-top: 10px;
}

.userUrl {
  text-decoration: none;
  color: var(--blue-contrast);
}

.userUrl:hover {
  color: var(--light-blue-hover);
}

.linkClickImpersonate {
  position: absolute;
  margin-left: -80px;
  padding: 0;
  text-decoration: none;
  color: var(--light-blue);
  font-weight: 600
}

.linkClickImpersonateMaxCharReached{
  left: 80%  
}

.linkClickImpersonateMaxCharNotReached{
  left: 70%
}


.linkClickImpersonate:hover {
  color: var(--light-blue-hover);
}

.accountUsers {
  width: 600px;
}