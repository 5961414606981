.newMentionColumnContainer {
  display: flex;
  flex-direction: row;
}

.newMentionColumnContainer :hover {
  cursor: pointer;
}

/* checkbox and arrow for dupes */
.newMentionColumnFirstGroup {
  display: flex;
  flex-direction: column;
}

.newMentionColumnCheckbox {
  padding-top: 19px;
  padding-left: 13px;
}

.newMentionColumnDupeArrow {
  margin-right: -4px;
  text-align: right;
}

.newDrillDownDupeArticle {
  margin-left: 50px;
}

.newDrillDownDupeArticle .newDrillDownMentionCell {
  margin-left: 125px;
}

/* date and image */
.newMentionColumnSecondGroup {
  padding-top: 5px;
  padding-left: 19px;
}

.newMentionCellImage img {
  padding-top: 11px;
  width: 100px;
  height: 80px;
  object-fit: cover;
}

.newMentionCellThirdGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 45px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.newMentionCellPublisherAndAuthor {
  display: flex;
  flex-direction: row;
  line-height: 16px;
  color: var(--gray-80);
  font-family: Proxima Nova, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
}

.newMentionCellPublisher {
  padding-right: 4px;
}

.newMentionCellAuthor {
  padding-left: 4px;
}

.newMentionCellMainContent {
  display: flex;
  flex-direction: column;
}

.newMentionCellTitle {
  display: -webkit-box;
  max-width: 500px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 22px;
  color: var(--gray-80);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
}

.sourceLogo {
  max-height: 24px;
}

.newMentionCellTagsList {
  margin: -5px;
  padding: 5px;
  max-height: 110px;
  overflow: hidden;
}

.newMentionColumnDivider {
  display: flex;
  margin-top: 5px;
  border-radius: 50%;
  background-color: var(--gray-80);
  width: 5px;
  height: 5px;
}

.influencerClickable {
  cursor: pointer;
  color: var(--light-blue);
}

.customArticleImage {
  padding-top: 11px;
  width: 96px;
}
