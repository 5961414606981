.articlesRowItem {
  flex: 0 0 18.3%;
  overflow: auto;
}
.articlesLargeRowItem {
  flex: 0 0 45%;
}
.emptyStateIcon {
  min-width: 96px;
  min-height: 96px;
}
.articlesList {
  /* 32px and 40px table header ans sub header */
  height: calc(100% - 32px - 40px);
  overflow-y: scroll;
}
.wordText {
  margin: 0 2px;
}
.withPublishDateLarge {
  flex: 0 0 35%;
}
.withPublishDate {
  flex: 0 0 16%;
}
.tooltipInfoIcon {
  width: 20px;
}
