.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  white-space: nowrap;
}

.toolTip {
  white-space: nowrap;
}
