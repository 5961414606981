:root {
  --circle-radius: 16px;
}

.stepper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.step {
  padding: 5px;
}

.stepHeader {
  display: flex;
  justify-content: flex-start;
  line-height: 24px;
  color: var(--gray-80);
  font-weight: 400;
}

.stepHeaderActive {
  color: var(--gray-90);
  font-weight: 700;
}

.stepBody {
  margin-left: 14px;
  padding: 16px 0px 16px 14px;
}

.stepNumber,
.clickableStepNumber {
  margin-right: 8px;
  border-color: var(--gray-40);
  background-color: var(--gray-40);
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
}

.clickableStepNumber:hover,
.clickableStepNumber:focus,
.stepActive {
  border-color: var(--medium-blue);
  background-color: var(--medium-blue);
}

.stepNumber:hover,
.stepNumber:focus {
  border-color: var(--gray-40);
  background-color: var(--gray-40);
}

.stepLine {
  border-left: 4px solid var(--gray-40);
  padding-left: 25px;
}

.stepLineActive {
  border-left: 4px solid var(--medium-blue);
  padding-left: 25px;
}
