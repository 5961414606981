@import '../../../../styles/colors.css';

.active {
  color: var(--gray-90);
  font-size: 16px;
  font-weight: bold;
}

.inactive {
  color: var(--gray-80);
  font-size: 16px;
}

.disabled {
  color: var(--gray-40);
}
