.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
}

.header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  padding: 8px 16px;
  height: 56px;
}

.headerIcon {
  padding-right: 0;
  padding-left: 0;
  fill: var(--gray-80);
}

.headerIcon:hover {
  fill: var(--gray-90);
  background-color: var(--gray-20);
}

.headerActions {
  display: flex;
}

.headerIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: transparent;
  width: 40px;
  height: 40px;
}

.title {
  flex: 1 1 auto;
  flex-direction: column;
  justify-items: center;
  margin-right: 5px;
  overflow: hidden;
}

.titleText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--gray-90);
  font-size: 14px;
  font-weight: bold;
}

.urlCount {
  line-height: 12px;
  color: var(--gray-80);
  font-size: 12px;
}

.content {
  padding: 20px 50px;
}

.stats {
  margin: 0 0 28px;
}

.noTile,
.noTile:hover {
  box-shadow: none;
}

.small {
  padding-top: 0px;
}

.smallStats {
  margin: 0 0 2px;
}