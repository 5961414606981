.searchListContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.searchListSearchBar {
  flex-shrink: 0;
  margin: 0px 16px;
  border: 1px var(--gray-40) solid;
  border-bottom: 1px var(--gray-20) solid;
  border-radius: 2px;
  padding: 8px;
}

.searchListSearchBarPadding {
  padding-top: 10px;
}

.searchList {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
}

.searchListTitle {
  margin: 15px 0 0 12px;
  color: var(--gray-80);
  font-size: 12px;
}

.searchListDivider {
  display: block;
  clear: both;
  border: 0;
  background-color: var(--gray-20);
  width: 96%;
  height: 1px;
}

.socialSearchesDivider {
  margin-top: 20px;
}

.searchListEntries {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
}

.searchListMultiIcon {
  margin-right: 8px;
}

.searchCustomListTitle {
  padding: 20px 0px 0px 16px;
  font-size: 12px;
}
