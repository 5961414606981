@import '../../../../styles/colors.css';

.title {
  margin-bottom: 1px;
  height: 24px;
  line-height: 24px;
  color: var(--white);
  font-size: 14px;
  font-weight: var(--bold);
}

.searchTitle {
  margin-bottom: 15px;
  line-height: 18px;
  color: var(--white);
  font-size: 12px;
  font-weight: var(--bold);
}
.editorWrapper {
  min-width: 260px;
  max-width: 800px;
  max-height: 500px;
}

.advancedTextEditor {
  background-color: #34404b;
  max-width: 800px;
  color: #fff !important;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
    monospace;
}

.containerTooLongText {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}

.textTooLongText {
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 4px 0px;
  background: #677078;
  padding: 0 4px 0 4px;
  line-height: 24px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}
