.Head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  height: 25px;
}

.title {
  display: flex;
  align-items: center;
}

.aggregations {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-top: 20px;
}

.aggregation {
  flex: 0 0 80px;
  margin-right: 20px;
}

.badge {
  margin-left: 12px;
  background-color: var(--charting-purple-dark);
  text-transform: uppercase;
  color: var(--white);
}
