.chartContainer {
  margin: 0;
  padding-right: 0.5%;
  padding-left: 0.5%;
  /* rechart responsive container fix for proper resizing */
  width: 99%;
  min-width: 310px;
  height: 100%;
}

.legends {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 1%;
  /* padding for legends alignment with tweaked rechart responsive container */
  padding-left: 1%;
  height: 100%;
}

.legend {
  text-align: left;
  line-height: 21px;
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  margin: 5px 10px;
  width: 290px;
}

.tooltipDateRow {
  margin: 5px 10px;
  font-weight: normal;
}

@media only screen and (min-width: 1440px) {
  .legends {
    justify-content: center;
  }
  .legend {
    margin-left: 50px;
  }
}
