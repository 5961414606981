.grid :global(.tk-grid-table__row--header) {
  background-color: var(--gray-10);
}

.grid :global(.tk-grid-table__row > div:first-child) {
  padding-left: 16px;
}

.truncable {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dashboardIcon {
  display: flex;
}

.titleText {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--bold);
}

.loader {
  margin: 20px 0;
}

.warningHighlight {
  margin-right: 4px;
  font-weight: bold;
}

.statusBar {
  display: flex;
  align-items: center;
}
