.articleTable {
  border: 1px solid var(--gray-20);
  background-color: white;
}

.status {
  margin-left: auto;
}

.statusLabel {
  margin: 0;
}

.statusIcon {
  position: relative;
  top: 2px;
  margin-right: 12px;
}

.loaderContainer {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.body {
  padding: 10px 5px;
  height: 230px;
  overflow-y: scroll;
}

.header {
  padding: 0;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 10px solid transparent;
  width: 100%;
}

.url {
  width: 417px;
}

.urlText {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--gray-90);
}

.tooltipContentIcon {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.tooltipContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.blueText {
  cursor: pointer;
  color: var(--light-blue);
}