.OutletInfluencerList {
  display: flex;
  flex-flow: column nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-flow: row nowrap;
  flex-wrap: nowrap;
  min-height: 0;
}

.tiny .wrapper,
.small .wrapper {
  display: block;
}

.grid {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

.sidebarWrapper {
  flex: 0 0 auto;
  border-right: 1px solid var(--gray-20);
  background-color: var(--white);
  width: 350px;
}

.tiny .sidebarWrapper,
.small .sidebarWrapper {
  width: auto;
}

.contentWrapper {
  flex: 1 1 auto;
  min-width: 0;
}
