.baseColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagTableRowTitleColumn {
  padding-left: 20px;
  max-width: 317px;
}

.tagTableRowNumberOfArticlesColumn {
  max-width: 50px;
}

.tagTableRowLastModifiedColumn {
  max-width: 160px;
}

.tagTableRowOwnerColumn {
  display: flex;
  flex-direction: row;
  max-width: 400px;
  margin-left: -30px;
  align-items: center;
}

.tagTableRowActionsColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-right: 16px;
}

.kebabDropdown {
  min-width: 170px;
  text-align: start;
}
