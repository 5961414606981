.SidebarInfo {
  color: var(--gray-90);
}

.label {
  text-transform: uppercase;
  font-family: Proxima Nova, sans-serif;
  font-size: 12px;
  font-weight: normal;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--gray-10);
  padding-right: 16px;
  padding-left: 16px;
}

.content {
  padding: 16px;
}
