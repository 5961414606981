.mainContainer {
  position: relative;
}

.menuContainer {
  background-color: var(--white);
  min-width: 220px;
}

.columnsPickerContainer {
  position: absolute;
  right: 0;
  z-index: 10;
}

.columnDropdown {
  width: 284px;
  max-height: 450px;
  overflow-y: auto;
}
