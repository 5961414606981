.tableContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
  overflow: hidden;
}

.tableWrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

.table {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
