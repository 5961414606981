@import '../../styles/variables.module.css';

.gridContainer {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 279px auto;
  height: calc(100% - 158px);
}

.gridContainerItem {
  width: 100%;
  height: 100%;
  text-align: center;
}

.gridSlideContainer {
  display: grid;
  grid-template-rows: auto 97px;
}

.gridNavContainer {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 33% 33% 33%;
  margin-bottom: 50px;
  width: 100%;
}

.reportSectionsScrolling {
  height: calc(
    100vh -
    var(--header-bar-height) -
    var(--view-title-bar-height) -
    var(--view-report-status-switch-bar-height)
  );
  overflow-y: auto;
}

.reportV3SlidesHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 5% 5% 0 0;
  background-color: var(--gray-lite-5);
  padding: 0 15px;
  font-size: initial;
  font-weight: bold;
}

.reportV3SlidesHeader button {
  border: none;
  background-color: transparent;
}

.reportV3SlidesHeader button:hover {
  border: none;
  background-color: transparent;
}
