.wizardContentSizeProgress {
  display: flex;
  align-items: center;
  margin-right: 85px;
}

.icon {
  margin-top: 4px;
  margin-right: 8px;
  color: var(--salmon);
}
