.modal {
  width: 578px !important;
}
.textArea {
  border: 1px solid var(--gray-20);
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  resize: none;
}

.articlePreviewTitle {
  margin-bottom: 10px;
}

.link {
  cursor: pointer;
  color: var(--light-blue);
}