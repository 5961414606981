.mainViewport,
.queryBuilder {
  display: flex;
  flex-direction: column;
}

.mainViewport {
  flex-shrink: 0;
  padding-top: 5px;
}

.childWrapper {
  padding: 5px 15px 15px 15px;
}

.buttonBox {
  border-radius: 0 0 6px 6px;
  padding: 0 !important;
}

.titleBar {
  display: flex;
  align-items: center;
}

.earnedSearchRightContent {
  display: flex;
  justify-content: flex-end;
}

.earnedSearchRightContent > * {
  margin-right: 20px;
}

.checkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkContainer :first-child {
  margin-right: 5px;
}

.searchEditorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.drilldownContainer {
  position: relative;
  background-color: var(--gray-10);
  padding-top: 0;
}

.filtersWarningBar { 
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-danger);
  background-color: var(--error-light);
  padding-left: 15px;
  height: 50px;
}

.filtersWarningIcon {
  margin-right: 10px;
  width:30px;
  height:30px;
  color: var(--color-danger);
}

.filtersWarningCloseIcon {
  position: absolute;
  right: 30px;
}
