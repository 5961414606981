.pillsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid var(--gray-20);
  padding: 16px 16px 23px 16px;
  min-height: 72px;
  color: var(--gray-80);
}

.addPillButtonWrapper {
  display: none;
}

.pillsContainerHover:hover {
  background-color: var(--gray-10);
}

.pills {
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px;
  padding-top: 6px;
}

.pills > * {
  margin-top: 8px;
  margin-left: 8px;
}

.pillInputWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
}

.pillsContainerHover:hover .addPillButtonWrapper {
  display: block;
  position: absolute;
  top: 8px;
  right: 16px;
}

.pillCancelButton {
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.containerWithError {
  border: 1px solid var(--color-danger);
  background-color: var(--bgColor-danger);
}

.containerWithError:hover {
  background-color: var(--bgColor-danger);
}

.errorMessage {
  padding-top: 16px;
  color: var(--color-danger);
}

.titleAndActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
