.paper {
  flex-flow: column nowrap;
  height: 100%;
}

.content {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 16px 0;
  height: calc(100% - 106px);
  overflow-y: auto;
}
