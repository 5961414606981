.container {
  background-color: #424b55;
  color: var(--white);
}

.actionButtonContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.actionButton {
  margin-left: 15px;
}
