.modalBody {
  display: grid;
  grid-template-rows: 40px 28px auto;
}

.followerCounterRangeSelectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}
