.articlesRowItem,
.articlesLargeRowItem {
  box-sizing: border-box;
  align-items: center;
  padding-right: 20px;
}
.articlesRowItem {
  flex: 0 0 16%;
  overflow: auto;
}
.articlesLargeRowItem {
  flex: 0 0 35%;
  padding-left: 20px;
}
.emptyStateIcon {
  min-width: 96px;
  min-height: 96px;
}
.topContributingInfoImage {
  width: 96px;
  height: 80px;
  object-fit: cover;
}
.avatar {
  min-width: 16px;
  min-height: 16px;
  overflow: hidden;
  line-height: 10;
}
.footerDot + .footerDot:before {
  margin-right: 4px;
  margin-left: 4px;
  content: '\2022';
}
