.Article {
  border-bottom: 1px solid var(--gray-20);
  padding: 10px 0;
  break-after: auto;
}

.ArticleLarge {
  border-right: 1px solid var(--gray-20);
  padding: 0 15px;
}

.Article .contentLarge {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.ArticleWrapper {
  position: relative;
  height: stretch;
  overflow-y: auto;
}

.ArticleLargeWrapper {
  display: flex;
}

.ArticleLargeWrapperRedesign {
  position: relative;
  columns: 300px 2;
  column-rule: solid 1px var(--gray-20);
  padding: 10px 0px 10px 0px;
  height: stretch;
  overflow-y: auto;
}

.Article:last-of-type,
.ArticleLarge:last-of-type {
  border-bottom: 0;
}

.Article .heading,
.ArticleLarge .heading {
  line-height: calc(20 / 14);
}

.Article .body,
.ArticleLarge .body {
  align-content: center;
}

.Article .contentSmall,
.Article .contentMedium,
.Article .contentTall,
.Article .contentLarge,
.ArticleLarge .contentSmall,
.ArticleLarge .contentMedium,
.ArticleLarge .contentTall,
.ArticleLarge .contentLarge {
  flex-flow: row nowrap;
  cursor: pointer;
}

.ArticleLargeWrapper .Article .contentTall,
.ArticleLargeWrapper .Article .contentLarge,
.ArticleLargeWrapper .ArticleLarge .contentLarge {
  flex-flow: column nowrap;
  cursor: pointer;
}

.Article .source,
.ArticleLarge .source {
  display: flex;
  flex-flow: row wrap;
}

.Article .item,
.ArticleLarge .item {
  padding: 0px 10px 0px 14px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0em;
  font-family: Proxima Nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
}

.Article .number,
.ArticleLarge .number {
  display: inline-block;
  padding: 0 0 0 0;
  min-width: 2em;
}

.sourceIcon {
  padding-right: 8px;
  padding-left: 2px;
}

.Article a:link {
  text-decoration: none;
}

.image {
  object-fit: cover;
  padding-right: 12px;
}

.noData {
  min-height: 300px;
}
