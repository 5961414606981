.ReportLoadingContainer {
  width: 100%;
  height: 100%
}

.reportLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 235px);
}