.SearchActionsBar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-20);
  background-color: var(--white);
  height: 50px;
}

.selectTotalInfluencersBar {
  display: flex;
  padding-left: 18px;
  width: 100%;
  height: 40px;
}

.pagination {
  display: -webkit-box;
  align-items: center;
  margin-right: 15em;
}

.barRight {
  display: flex;
  margin-left: auto;
}

.arrow {
  padding-right: 10px;
  padding-left: 10px;
}

.selectableArrow {
  cursor: pointer;
}

.sorting {
  margin-right: 30px;
  margin-left: 20px;
}

.sortByLabel,
.selectedItemsWrapper {
  display: flex;
}

.searchInput { 
  margin-right: 30px; 
}

.influencerSearchBar {
  margin: 5px 10px 0px 0px;
}

.searchButton {
  padding: 8px 15px;
}

.selectedItemsWrapper {
  flex: 50%;
  padding: 0 20px;
}

.checkbox {
  margin-right: 6px;
}

@media (max-width: 425px) {
  .searchButton {
    display: none;
  }

  .influencerSearchBar {
    display: none;
  }
}