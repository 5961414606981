.drilldownChartContainer {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 350px;
}

.chartContent {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  padding: 0 16px 0;
  height: 250px;
}

.widgetTitle {
  margin: 20px 0 0 30px;
  color: var(--black);
  font-size: 30px;
  font-weight: bold;
}

.areaChart {
  flex: 2;
}
.metrics {
  flex: 1;
}
