.baseAction {
  display: inline-block;
  padding-left: 6px;
}

.container {
  display: flex;
}

.tooltip{
  color: var(--gray-80);
}

.openInNewTab {
  display: flex;
  color: inherit !important;
}
