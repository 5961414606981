@import '../../../../../styles/colors.css';

.StoryHubActionBar {
  padding: 8px 16px;
  background: var(--white);
}

.backArrowWrapper {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.backArrow.backArrow {
  padding: 0;
  border: none;
}

.backArrow.backArrow:hover {
  background: inherit;
}

.formWrapper {
  display: inline-block;
  width: auto;
}

.actionButtonsWrapper.actionButtonsWrapper {
  display: flex;
}

.actionButton {
  margin-left: 8px;
}
