.loaderPosition {
  position: absolute;
  top: 30%;
  left: 30%;
}

.adminButtonHolder {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.buttonMargin {
  margin-right: 10px;
}

.adminFormPage {
  box-sizing: border-box;
  max-width: var(--form-max-width);
}

.inputStyle {
  width: 100%;
}
