@import '../../styles/colors.css';

.row {
  display: grid;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  height: 43px;
}

.svgThreeColumnRow {
  grid-template-columns: 30px 1fr minmax(40px, 85px);
}

.threeColumnRow {
  grid-template-columns: 15px 1fr minmax(40px, 100px);
}

.twoColumnRow {
  grid-template-columns: 1fr minmax(30px, 40px);
}

.MetricDetailEmpty {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  height: 200px;
}

.label {
  display: flex;
  flex: 1;
  align-items: center;
  height: 44px;
}

.value {
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
  line-height: 44px;
}

.justifyStart {
  justify-content: flex-start;
}

.headerLabel {
  flex: 1;
  height: 44px;
  line-height: 44px;
}

.gridColumnStartOne {
  grid-column-start: 1;
}

.gridColumnStartTwo {
  grid-column-start: 2;
}

.gridColumnStartThree {
  grid-column-start: 3;
}

.dot {
  display: inline-block;
  margin-right: 10px;
  border: 3px solid transparent;
  border-radius: 50px;
  width: 3px;
  height: 3px;
  line-height: 44px;
}

.barLabelWrapper {
  display: inline-block;
  margin-top: 5px;
}

.trendWrapper {
  display: flex;
  line-height: 44px;
}

.trendLabel {
  flex: 1;
  flex-wrap: wrap;
  height: 44px;
  overflow: hidden;
}

.trendLine {
  display: inline-block;
  justify-content: flex-end;
  width: 150px;
}

.drilldownLink {
  text-decoration: none;
  color: inherit;
}

.lastItemCleanBorderBottom {
  border-bottom: none;
}

.blockContainer {
  display: flex;
}

.sentimentLabel {
  text-transform: capitalize;
}

.sentimentIcon {
  padding-top: 5px;
}
